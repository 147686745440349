import React, { FC, ReactElement } from "react";
import styled from "styled-components";

const Rank: FC<{ rank: string; teamColor: string }> = ({ rank, teamColor }): ReactElement => {
  return (
    <Wrapper teamColor={teamColor}>
      <div className="rank">
        <div className="inner">{rank}</div>
      </div>
      <div className="rank">
        <div className="outer">{rank}</div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ teamColor: string }>`
  position: relative;
  .rank {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .outer {
    font-family: "RobotoWebFont";
    font-style: italic;
    font-weight: 900;
    font-size: 470px;
    line-height: 293px;
    text-align: center;
    letter-spacing: -82px;
    text-align: right;

    -webkit-text-stroke: 8px ${({ teamColor }) => teamColor};
    color: transparent;

    &::before {
      -webkit-text-stroke: 0;
      color: transparent;
      content: attr(data-text);
      position: absolute;
      white-space: pre;
    }
  }
  .inner {
    font-family: "RobotoWebFont";
    font-style: italic;
    font-weight: 900;
    font-size: 470px;
    line-height: 293px;
    text-align: center;
    letter-spacing: -82px;
    text-align: right;
    mix-blend-mode: overlay;
  }
`;

export default Rank;
