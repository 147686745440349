import useReactRouter from "use-react-router";
import qs from "qs";

export const useParameter = () => {
  const { location } = useReactRouter();
  const url = decodeURIComponent(location.search);
  const {
    sportsId,
    leagueId,
    gameId,
    locale = "ja",
    isShownFooter = "true",
    isShownTime = "true",
    // https://team-spolive.slack.com/archives/C01NDDA9FT9/p1683512335696209?thread_ts=1683505627.846539&cid=C01NDDA9FT9
    hidden
  } = qs.parse(url.replace("?", "")) as {
    [key: string]: string;
    locale: "ja" | "en";
  };

  return {
    sportsId,
    leagueId,
    gameId,
    locale,
    isShownFooter,
    isShownTime,
    hidden
  };
};
