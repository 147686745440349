import React, { FC, ReactElement } from "react";
import styled from "styled-components";
import spoliveIcon from "assets/images/change_player_card/spolive_icon.svg";

const LogoLayer: FC = (): ReactElement => {
  return (
    <Wrapper>
      <img className="spoliveLogo" src={spoliveIcon} alt="SpoLiveLogo" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  .spoliveLogo {
    width: 186px;
    height: 68px;
    position: absolute;
    left: 0px;
    top: 4px;
    padding: 10px;
    box-sizing: border-box;
  }
`;

export default LogoLayer;
