const colorTheme = {
  color: {
    primary: "#3BC3A3",
    text: "#ffffff",
    darkText: "rgba(0,0,0,0.87)",
    btnText: "#6F6F6F",
    placeholderText: "#97999B",
    oddColor: "#333333",
    evenColor: "rgba(59,195,163,0.3)",
    tournamentBorder: "#999999",
    bg: "#121212",
    danger: "#D63528",
    white: "#fff",
    content: "#333333",
    darkContent: "rgba(0, 0, 0, 0.54)",
    lightText: "rgba(255,255,255,0.54)",
    input: "rgba(255,255,255,0.26)",
    contentBorder: "rgba(255,255,255,0.12)",
    contentLightBorder: "rgba(255,255,255,0.06)"
  }
};

export default colorTheme;
