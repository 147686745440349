import { SportsDic } from "models/sportsDic";

export const handball: SportsDic = {
  playerHasUniformNumber: true,
  hasUniformNumber: true,
  hasPosition: true,
  isFixedPlayerPositionNum: false,
  teamImgIsCircle: false,
  startingMemberNum: 20,
  reserveMemberNum: 0,
  isPeriodFinish: true,
  isDisplaySeconds: true,
  isHiddenReserveMember: true,
  isCountDownTime: false,
  positionList: {
    ja: [
      {
        id: "GK",
        name: "GK"
      },
      {
        id: "LW",
        name: "LW"
      },
      {
        id: "LB",
        name: "LB"
      },
      {
        id: "CB",
        name: "CB"
      },
      {
        id: "RB",
        name: "RB"
      },
      {
        id: "RW",
        name: "RW"
      },
      {
        id: "PV",
        name: "PV"
      },
      {
        id: "GM",
        name: "GM"
      },
      {
        id: "H",
        name: "監督"
      },
      {
        id: "C",
        name: "コーチ"
      },
      {
        id: "S",
        name: "スタッフ"
      }
    ],
    en: [
      {
        id: "GK",
        name: "GK"
      },
      {
        id: "LW",
        name: "LW"
      },
      {
        id: "LB",
        name: "LB"
      },
      {
        id: "CB",
        name: "CB"
      },
      {
        id: "RB",
        name: "RB"
      },
      {
        id: "RW",
        name: "RW"
      },
      {
        id: "PV",
        name: "PV"
      },
      {
        id: "GM",
        name: "GM"
      },
      {
        id: "H",
        name: "Head Coach"
      },
      {
        id: "C",
        name: "Coach"
      },
      {
        id: "S",
        name: "Support Staff"
      }
    ]
  },
  infringementNames: [
    {
      key: "penaltyShootout",
      name: "SO",
      name_en: "SO",
      is_show_gamecontent: true
    }
  ],
  statTypes: [
    {
      key: "Goal",
      name: "ゴール",
      name_en: "Goal",
      name_short: "ゴール",
      name_short_en: "Goal",
      point: 1,
      gamePoint: 1,
      iconText: "G",
      isAssist: true
    },
    {
      key: "assist",
      name: "アシスト",
      name_en: "Assist",
      iconText: "A"
    },
    {
      key: "shotMiss",
      name: "シュート失敗",
      name_en: "Shot miss",
      name_short: "シュート失敗",
      name_short_en: "Shot miss",
      color: "gray",
      iconText: "SM"
    },
    {
      key: "7mGoal",
      name: "7mゴール",
      name_en: "7m goal",
      name_short: "7mゴール",
      name_short_en: "7m goal",
      point: 1,
      gamePoint: 1,
      iconText: "7m"
    },
    {
      key: "7mMiss",
      name: "7m失敗",
      name_en: "7m miss",
      name_short: "7m失敗",
      name_short_en: "7m miss",
      color: "gray",
      iconText: "7m-"
    },
    {
      key: "Foul",
      name: "ファウル",
      name_en: "Foul",
      name_short: "ファウル",
      name_short_en: "Foul",
      iconText: "F",
      color: "gray"
    },
    {
      key: "Turnover",
      name: "ターンオーバー",
      name_en: "Turnover",
      name_short: "ターンオーバー",
      name_short_en: "Turnover",
      iconText: "TO"
    },
    {
      key: "Save",
      name: "セーブ",
      name_en: "Save",
      name_short: "セーブ",
      name_short_en: "Save",
      iconText: "SV"
    },

    {
      key: "yellowcard",
      name: "警告",
      name_en: "Yellow card",
      name_short: "警告",
      name_short_en: "Yellow card",
      iconText: "Yellow card"
    },
    {
      key: "Suspension",
      name: "退場",
      name_en: "Suspension",
      name_short: "退場",
      name_short_en: "Suspension",
      iconText: "2M"
    },
    {
      key: "redcard",
      name: "失格",
      name_en: "Red card",
      name_short: "失格",
      name_short_en: "Red card",
      iconText: "red card"
    },
    {
      key: "bluecard",
      name: "失格報告書",
      name_en: "Blue card",
      name_short: "失格報告書",
      name_short_en: "Blue card",
      iconText: "Blue card"
    },
    {
      key: "timeout",
      name: "タイムアウト",
      name_en: "Timeout",
      iconText: "T",
      color: "gray",
      isNoPlayer: true,
      isUseEventTimer: true,
      isHiddenStat: true
    }
  ],
  initialPeriod: [
    {
      id: "firstQuarter",
      name: "前半",
      name_en: "1st",
      nameScore: "前半",
      nameScore_en: "1st",
      order: 1
    },
    {
      id: "halfTime",
      name: "ハーフタイム",
      name_en: "Half-time",
      isBreak: true,
      order: 2
    },
    {
      id: "secondQuarter",
      name: "後半",
      name_en: "2st",
      nameScore: "後半",
      nameScore_en: "2st",
      order: 3
    },
    {
      id: "halfTime2",
      name: "休憩",
      name_en: "Break",
      order: 4,
      isExtra: true,
      isBreak: true
    },
    {
      id: "ET1",
      name: "延長前半",
      name_en: "ET 1st",
      nameScore: "延長前半",
      nameScore_en: "ET 1st",
      order: 5,
      isExtra: true
    },
    {
      id: "halfTime3",
      name: "延長ハーフタイム",
      name_en: "ET Half-time",
      order: 6,
      isExtra: true,
      isBreak: true
    },
    {
      id: "ET2",
      name: "延長後半",
      name_en: "ET 2st",
      nameScore: "延長後半",
      nameScore_en: "ET 2st",
      order: 7,
      isExtra: true
    }
  ],
  periodNames: {
    firstQuarter: {
      id: "firstQuarter",
      name: "前半",
      name_en: "1st",
      nameScore: "前半",
      nameScore_en: "1st",
      order: 1,
      timeMin: 30,
      isBreak: false
    },
    halfTime: {
      id: "halfTime",
      name: "ハーフタイム",
      name_en: "Half-time",
      timeMin: 15,
      isBreak: true,
      order: 2
    },
    secondQuarter: {
      id: "secondQuarter",
      name: "後半",
      name_en: "2st",
      nameScore: "後半",
      nameScore_en: "2st",
      order: 3,
      timeMin: 30,
      isBreak: false,
      isLastPeriod: true
    },
    halfTime2: {
      id: "halfTime2",
      name: "休憩",
      name_en: "Break",
      order: 4,
      isExtra: true,
      isBreak: true,
      timeMin: 5
    },
    ET1: {
      id: "ET1",
      name: "延長前半",
      name_en: "ET 1st",
      nameScore: "延長前半",
      nameScore_en: "ET 1st",
      order: 5,
      timeMin: 5,
      isBreak: false,
      isExtra: true
    },
    halfTime3: {
      id: "halfTime3",
      name: "延長ハーフタイム",
      name_en: "ET Half-time",
      order: 6,
      isExtra: true,
      isBreak: true
    },
    ET2: {
      id: "ET2",
      name: "延長後半",
      name_en: "ET 2st",
      nameScore: "延長後半",
      nameScore_en: "ET 2st",
      order: 7,
      timeMin: 5,
      isBreak: false,
      isExtra: true
    }
  }
};
