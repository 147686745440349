import { SportsDic } from "models/sportsDic";

export const rugby: SportsDic = {
  playerHasUniformNumber: false,
  isFixedPlayerPositionNum: true,
  teamImgIsCircle: false,
  startingMemberNum: 15,
  reserveMemberNum: 24, //通常は8人 練習試合では24人登録できるように
  statTypes: [
    {
      key: "try",
      name: "トライ",
      name_en: "Try",
      point: 5,
      iconText: "T"
    },
    {
      key: "conversiongoal",
      name: "コンバージョンゴール",
      name_en: "Conversion Goal",
      name_short: "ゴール",
      name_short_en: "Goal",
      point: 2,
      iconText: "G"
    },
    {
      key: "penaltygoal",
      name: "ペナルティゴール",
      name_en: "Penalty Goal",
      name_short: "ゴール",
      name_short_en: "Goal",
      point: 3,
      iconText: "PG"
    },
    {
      key: "penaltytry",
      name: "ペナルティトライ",
      name_en: "Penalty Try",
      point: 7,
      iconText: "PT",
      isNoPlayer: true
    },
    {
      key: "dropgoal",
      name: "ドロップゴール",
      name_en: "Drop Goal",
      name_short: "ゴール",
      name_short_en: "Goal",
      point: 3,
      iconText: "DG"
    },
    {
      key: "yellowcard",
      name: "イエローカード",
      name_en: "Yellow Card"
    },
    {
      key: "redcard",
      name: "レッドカード",
      name_en: "Red Card"
    },
    {
      key: "goalFail",
      name: "ゴール失敗",
      name_en: "Goal Fail",
      iconText: "G失敗",
      color: "gray"
    },
    {
      key: "penaltygoalfail",
      name: "ペナルティゴール失敗",
      name_en: "Penalty Goal Fail",
      iconText: "PGF",
      color: "gray"
    },
    {
      key: "change",
      name: "交代",
      name_en: "Change",
      isHiddenStat: true
    }
  ],
  infringementNames: [
    {
      key: "knockon",
      name: "ノックオン",
      name_en: "Knock-on"
    },
    {
      key: "throwforward",
      name: "スローフォワード",
      name_en: "Throw Forward"
    },
    {
      key: "offside",
      name: "オフサイド",
      name_en: "Offside"
    },
    {
      key: "notrollaway",
      name: "ノットロールアウェイ",
      name_en: "Not Roll Away"
    },
    {
      key: "overthetop",
      name: "オーバーザトップ",
      name_en: "Over The Top"
    },
    {
      key: "notreleasetheball",
      name: "ノットリリースザボール",
      name_en: "Not Release The Ball"
    },
    {
      key: "knotstraight",
      name: "ノットストレート",
      name_en: "Not Straight"
    },
    {
      key: "collapsing",
      name: "コラプシング",
      name_en: "Collapsing"
    },
    {
      key: "hightackle",
      name: "ハイタックル",
      name_en: "High Tackle"
    }
  ],
  positionNames: [
    {
      num: 1,
      name: "FW"
    },
    {
      num: 9,
      name: "HB"
    },
    {
      num: 11,
      name: "TB"
    },
    {
      num: 15,
      name: "FB"
    }
  ],
  positionDictionary: {
    1: { position: "PR" },
    2: { position: "HO" },
    3: { position: "PR" },
    4: { position: "LO", position_en: "SR" },
    5: { position: "LO", position_en: "SR" },
    6: { position: "FL" },
    7: { position: "FL" },
    8: { position: "NO8" },
    9: { position: "SH" },
    10: { position: "SO", position_en: "FH" },
    11: { position: "WTB" },
    14: { position: "WTB" },
    12: { position: "CTB" },
    13: { position: "CTB" },
    15: { position: "FB" }
  },
  periodNames: {
    firstHalf: {
      id: "firstHalf",
      name: "前半",
      name_en: "1H",
      nameScore: "1st",
      nameScore_en: "1st",
      displayMaxTimeMin: 50,
      order: 1
    },
    halfTime: {
      id: "halfTime",
      name: "ハーフタイム",
      name_en: "half time",
      isBreak: true,
      order: 2
    },
    secondHalf: {
      id: "secondHalf",
      name: "後半",
      name_en: "2H",
      nameScore: "2nd",
      nameScore_en: "2nd",
      displayMaxTimeMin: 50,
      order: 3
    }
  },
  defaultPosition: [],
  addCheerIcons: ["🏉"]
};
