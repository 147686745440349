import React, { FC, ReactElement } from "react";
import BgLayer from "../BgLayer";
import { CardCanvas, Layer } from "../LayerSystem";
import { CardData } from "../types";
import LogoLayer from "./LogoLayer";
import PlayerPhotoLayer from "./PlayerPhotoLayer";
import ScoreEventTextLayer from "./ScoreEventTextLayer";
import TextLayer from "./TextLayer";

const SCORE_CARD_HEIGHT = 1000;
const SCORE_CARD_WIDTH = 1000;

type Props = {
  data: CardData;
};

const ScoreCardForRedHurricanes: FC<Props> = ({ data }): ReactElement => {
  return (
    <CardCanvas width={SCORE_CARD_WIDTH} height={SCORE_CARD_HEIGHT}>
      <Layer width={SCORE_CARD_WIDTH} height={SCORE_CARD_HEIGHT} layerProperty={0}>
        <BgLayer src={data.bgImg} />
      </Layer>
      <Layer width={SCORE_CARD_WIDTH} height={SCORE_CARD_HEIGHT} layerProperty={1}>
        <ScoreEventTextLayer event={data.eventType} />
      </Layer>
      <Layer width={SCORE_CARD_WIDTH} height={SCORE_CARD_HEIGHT} layerProperty={1}>
        <LogoLayer />
      </Layer>
      <Layer width={SCORE_CARD_WIDTH} height={SCORE_CARD_HEIGHT} layerProperty={2}>
        <PlayerPhotoLayer playerPhoto={data.playerPhoto} />
      </Layer>
      <Layer width={SCORE_CARD_WIDTH} height={SCORE_CARD_HEIGHT} layerProperty={2}>
        <TextLayer
          playerName={data.playerName}
          playerNameEn={data.playerNameEn}
          gameName={data.gameName}
          gameStatus={data.gameStatus}
          homeScore={data.homeScore}
          awayScore={data.awayScore}
          homeTeamIcon={data.homeTeamIcon}
          awayTeamIcon={data.awayTeamIcon}
          teamPosition={data.teamPosition}
        />
      </Layer>
    </CardCanvas>
  );
};

export default ScoreCardForRedHurricanes;
