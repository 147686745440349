import { Player } from "models/player";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import { useLocation, Link } from "react-router-dom";
import backButton from "assets/images/backButton.png";
import instagramWhiteIcon from "assets/images/sns/instagramWhiteIcon.png";
import twitterWhiteIcon from "assets/images/sns/twitterWhiteIcon.png";
import webWhiteIcon from "assets/images/sns/webWhiteIcon.png";
import firebase from "firebase";
import { Header } from "./internal/Header";
import { Footer } from "./internal/Footer";
import { FaUserAlt } from "react-icons/fa";

export const PlayerPage = () => {
  const location = useLocation();
  const res = location.pathname.split("/");
  const sportsId = res[3];
  const teamMasterId = res[4];
  const playerId = res[5];
  const [player, setPlayer] = useState<Player>();

  useEffect(() => {
    (async () => {
      firebase
        .firestore()
        .collection(`sportsData/${sportsId}/teamMaster/${teamMasterId}/players`)
        .doc(playerId)
        .get()
        .then(doc => setPlayer(doc.data() as Player));
    })();
  });

  const snsLinkProps = (url?: string) => {
    if (url) {
      return {
        href: url,
        target: "_blank",
        rel: "noopener"
      };
    } else {
      return {
        tabindex: "-1"
      };
    }
  };

  return (
    <Wrapper>
      <Header />
      <div className="subHeader_area">
        <Link
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            textDecoration: "none"
          }}
          to={{
            pathname: `/sports/team/${sportsId}/${teamMasterId}`
          }}
        >
          <div className="backButton_wrapper">
            <img src={backButton} alt="backButton" className="backButton" />
          </div>
          <div className="subHeader_mainText">チームトップに戻る</div>
        </Link>
      </div>
      <div className="main_content_wrapper">
        {player?.photoUrl ? (
          <img className="player_photo" src={player.photoUrl} alt="PlayerPhoto" />
        ) : (
          <FaUserAlt className="no_user_photo_icon" color="gray" />
        )}
        <div className="player_name_area">
          <p className="player_name">{player?.name}</p>
          <p>{player?.name_en}</p>
        </div>
        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {player?.height && <div className="gray_16_text">身長：{player?.height}cm</div>}
            <div style={{ width: 20 }} />
            {player?.weight && <div className="gray_16_text">体重：{player?.weight}kg</div>}
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {player?.birthday && <div className="gray_16_text">{`${player?.birthday}生まれ`}</div>}
            <div style={{ width: 20 }} />
            {player?.birthPlace && <div className="gray_16_text">出身：{player?.birthPlace}</div>}
          </div>
          {player?.description && (
            <div className="player_description_area">
              <div style={{ width: "80%" }}>{player?.description}</div>
            </div>
          )}
          <div className="sns_icon_area">
            {player?.twitterUrl && (
              <a {...snsLinkProps(player?.twitterUrl)}>
                <img
                  src={twitterWhiteIcon}
                  alt="twitterIcon"
                  className="sns_icon"
                  style={{ width: 27 }}
                />
              </a>
            )}
            {player?.instagramUrl && (
              <a {...snsLinkProps(player?.instagramUrl)}>
                <img src={instagramWhiteIcon} alt="instagramIcon" className="sns_icon" />
              </a>
            )}
            {player?.infoUrl && (
              <a {...snsLinkProps(player?.infoUrl)}>
                <img src={webWhiteIcon} alt="webIcon" className="sns_icon" />
              </a>
            )}
          </div>
        </div>
      </div>
      <div className="footer_wrapper">
        <Footer />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-height: 100vh;
  position: relative;
  padding-bottom: 64px;
  .header_area {
    background-color: #000000;
    height: 64px;
    display: flex;
    align-items: center;
    padding-left: 18px;
  }
  .spolive_logo {
    width: 113px;
    height: 32px;
  }
  .subHeader_area {
    background-color: #222222;
    height: 40px;
    display: flex;
    align-items: center;
  }
  .backButton_wrapper {
    margin-left: 21px;
    margin-right: 15px;
  }
  .backButton {
    width: 6px;
    height: 10px;
  }
  .subHeader_mainText {
    font-size: 14px;
    color: white;
  }
  .main_content_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 40px;
  }
  .player_photo {
    width: 220px;
    height: 240px;
  }
  .no_user_photo_icon {
    width: 200px;
    height: 240px;
    ${media.lessThan("medium")`
    width: 160px;
    height: 194px;
  `}
  }
  .dummy_playerPhoto {
    background-color: white;
    width: 220px;
    height: 240px;
  }
  .player_name_area {
    margin-top: 32px;
    margin-bottom: 32px;
    text-align: center;
  }
  .player_name {
    font-size: 28px;
    font-weight: 700;
  }
  .gray_16_text {
    font-size: 16px;
    color: white;
    opacity: 0.8;
  }
  .player_description_area {
    margin-top: 50px;
    margin-bottom: 28px;
    display: flex;
    justify-content: center;
  }
  .sns_icon_area {
    display: flex;
    justify-content: center;
    margin-top: 28px;
  }
  .sns_icon {
    width: 24px;
    height: 24px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .footer_wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    ${media.greaterThan("medium")`
    display: none
  `}
  }
`;
