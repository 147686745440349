import { SportsDic } from "models/sportsDic";

export const hockey: SportsDic = {
  playerHasUniformNumber: true,
  hasUniformNumber: true,
  hasPosition: true,
  isFixedPlayerPositionNum: false,
  teamImgIsCircle: false,
  startingMemberNum: 11,
  reserveMemberNum: 11,
  isHockey: true,
  //  isCountDownTime: false, //カウントダウンに変更する時これを入れる
  isPeriodFinish: true,
  isPullDownOrder: true,
  isDisplaySeconds: true,
  statTypes: [
    {
      key: "Goal",
      name: "ゴール",
      name_en: "Goal",
      name_short: "G",
      name_short_en: "G",
      pullDownOrder: 1,
      point: 1,
      iconText: "G",
      isHiddenStat: true
    },
    {
      key: "Shoot",
      name: "シュート",
      name_en: "Shoot",
      name_short: "S",
      name_short_en: "S",
      pullDownOrder: 2,
      iconText: "S"
    },
    {
      key: "PenaltyStroke",
      name: "ペナルティストローク",
      name_en: "Penalty Stroke",
      name_short: "PS",
      name_short_en: "PS",
      pullDownOrder: 3,
      color: "gray",
      iconText: "PS",
      isHiddenStat: true
    },
    {
      key: "PenaltyCorner",
      name: "ペナルティコーナー",
      name_en: "Penalty Corner",
      name_short: "PC",
      name_short_en: "PC",
      pullDownOrder: 4,
      color: "gray",
      iconText: "PC"
    },
    {
      key: "greencard",
      name: "グリーンカード",
      name_en: "Green Card",
      name_short: "Green Card",
      name_short_en: "Green Card",
      pullDownOrder: 5,
      iconText: "GreenCard"
    },
    {
      key: "yellowcard",
      name: "イエローカード",
      name_en: "Yellow Card",
      name_short: "Yellow Card",
      name_short_en: "Yellow Card",
      pullDownOrder: 6,
      iconText: "yellowcard"
    },
    {
      key: "redcard",
      name: "レッドカード",
      name_en: "Red Card",
      name_short: "Red Card",
      name_short_en: "Red Card",
      pullDownOrder: 7,
      iconText: "redcard"
    },
    {
      key: "change",
      name: "交代",
      name_en: "change",
      name_short: "change",
      name_short_en: "change",
      pullDownOrder: 8,
      iconText: "change",
      isHiddenStat: true
    }
  ],
  infringementNames: [
    {
      key: "penaltyShootout",
      name: "SO",
      name_en: "penalty shootout",
      is_show_gamecontent: true
    }
  ],
  positionNames: [
    { name: "FW" },
    { name: "MF" },
    { name: "DF" },
    { name: "GK" },
    { name: "FB" }
    // 下記、FW、MF、DFのさらに細かい分類。必要になったら追加
    //    { name: "RW" },
    //    { name: "CF" },
    //    { name: "LW" },
    //    { name: "RI" },
    //    { name: "CH" },
    //    { name: "LI" },
    //    { name: "RH" },
    //    { name: "ST" },
    //    { name: "LH" },
    //    { name: "SW" },
    //    { name: "RB" },
    //    { name: "LB" },
  ],
  initialPeriod: [
    {
      id: "firstQuarter",
      name: "1Q",
      name_en: "1Q",
      nameScore: "1Q",
      nameScore_en: "1Q",
      order: 1
    },
    {
      id: "secondQuarter",
      name: "2Q",
      name_en: "2Q",
      nameScore: "2Q",
      nameScore_en: "2Q",
      order: 2
    },
    {
      id: "halfTime",
      name: "HT",
      name_en: "half time",
      isBreak: true,
      order: 3
    },
    {
      id: "thirdQuarter",
      name: "3Q",
      name_en: "3Q",
      nameScore: "3Q",
      nameScore_en: "3Q",
      order: 4
    },
    {
      id: "fourthQuarter",
      name: "4Q",
      name_en: "4Q",
      nameScore: "4Q",
      nameScore_en: "4Q",
      order: 5
    },
    {
      id: "SO",
      name: "SO",
      name_en: "SO",
      order: 6,
      isExtra: true,
      isBreak: false
    }
  ],
  periodNames: {
    firstQuarter: {
      id: "firstQuarter",
      name: "1Q",
      name_en: "1Q",
      nameScore: "1Q",
      nameScore_en: "1Q",
      order: 1,
      isBreak: false
    },
    secondQuarter: {
      id: "secondQuarter",
      name: "2Q",
      name_en: "2Q",
      nameScore: "2Q",
      nameScore_en: "2Q",
      order: 2,
      isBreak: false
    },
    halfTime: {
      id: "halfTime",
      name: "HT",
      name_en: "half time",
      isBreak: true,
      order: 3
    },
    thirdQuarter: {
      id: "thirdQuarter",
      name: "3Q",
      name_en: "3Q",
      nameScore: "3Q",
      nameScore_en: "3Q",
      order: 4,
      isBreak: false
    },
    fourthQuarter: {
      id: "fourthQuarter",
      name: "4Q",
      name_en: "4Q",
      nameScore: "4Q",
      nameScore_en: "4Q",
      order: 5,
      isBreak: false,
      isLastPeriod: true
    },
    SO: {
      id: "SO",
      name: "SO",
      name_en: "SO",
      order: 6,
      isExtra: true,
      isBreak: false
    }
  },
  downCountDictionary: {
    1: { name: "1st", isFieldPositionEnable: true },
    2: { name: "2nd" },
    3: { name: "3rd" },
    4: { name: "4th" }
  },
  defaultPosition: [],
  addCheerIcons: ["🏑"]
};
