import { Game } from "models/game";
import { Team } from "models/team";

export const toHalfWidth = (text: string) =>
  text
    ? text.replace(/[Ａ-Ｚａ-ｚ０-９！-～]/g, s => String.fromCharCode(s.charCodeAt(0) - 0xfee0))
    : "";
export const toFullWidth = (text: string) =>
  text ? text.replace(/[A-Za-z0-9!-~]/g, s => String.fromCharCode(s.charCodeAt(0) + 0xfee0)) : text;

const getHomeTeamNameEmptyTeamName = (isJa: boolean, game: Game) => {
  let teamName = "";
  if (isJa && game?.team_homeName_ifEmptyTeamId) {
    teamName = game.team_homeName_ifEmptyTeamId;
  } else if (game?.team_homeName_ifEmptyTeamId_en) {
    teamName = game.team_homeName_ifEmptyTeamId_en;
  } else if (game?.team_homeName_ifEmptyTeamId) {
    teamName = game.team_homeName_ifEmptyTeamId;
  }
  return teamName;
};

const getAwayTeamNameEmptyTeamName = (isJa: boolean, game: Game) => {
  let teamName = "";
  if (isJa && game?.team_awayName_ifEmptyTeamId) {
    teamName = game.team_awayName_ifEmptyTeamId;
  } else if (game?.team_awayName_ifEmptyTeamId_en) {
    teamName = game.team_awayName_ifEmptyTeamId_en;
  } else if (game?.team_awayName_ifEmptyTeamId) {
    teamName = game.team_awayName_ifEmptyTeamId;
  }
  return teamName;
};

export const getTeamNameShortFromTeam = (
  isJa: boolean,
  teamData: Team | undefined | null
): string => {
  if (!teamData) {
    return "";
  }
  const { name, companyName, name_en, companyName_en } = teamData;
  if (isJa || !(companyName_en || name_en)) {
    return companyName || name;
  }
  return companyName_en || name_en || companyName || name;
};

const getHomeTeamNameShort = (isJa: boolean, game: Game) => {
  const teamName = getTeamNameShortFromTeam(isJa, game?.homeTeam);
  if (teamName) return teamName;
  if (game.team_homeName_ifEmptyTeamId || game.team_homeName_ifEmptyTeamId_en) {
    return getHomeTeamNameEmptyTeamName(isJa, game);
  }
  return "";
};

const getAwayTeamNameShort = (isJa: boolean, game: Game) => {
  const teamName = getTeamNameShortFromTeam(isJa, game?.awayTeam);
  if (teamName) return teamName;
  if (game.team_awayName_ifEmptyTeamId || game.team_awayName_ifEmptyTeamId_en) {
    return getAwayTeamNameEmptyTeamName(isJa, game);
  }
  return "";
};

export const getTeamNameShort = (
  isJa: boolean,
  isHome: boolean,
  game: Game | undefined
): string => {
  if (!game) {
    return "";
  }
  return isHome ? getHomeTeamNameShort(isJa, game) : getAwayTeamNameShort(isJa, game);
};

export const getTeamNameFull = (isJa: boolean, teamData: Team) => {
  const { name, companyName, name_en, companyName_en } = teamData;
  let padding = "";
  if (isJa || !(companyName_en || name_en)) {
    if (companyName && name) {
      padding = " ";
    }
    return [companyName, name].join(padding);
  }
  if (companyName_en && name_en) {
    padding = " ";
  }
  return [companyName_en, name_en].join(padding);
};
