import { textPrimary, textSecondary } from "assets/js/color";
import { SportsIconBlackBorder } from "assets/js/sportsImages";
import { useGameContext } from "libs/gameContext";
import React, { useMemo } from "react";
import styled from "styled-components";

type Props = {
  diameter: number;
  sportsId: string;
  name: string;
  name_en?: string;
  logoUrl?: string;
  color?: string;
  isFit?: boolean;
};

export const TeamImage: React.FC<Props> = ({
  diameter,
  sportsId,
  logoUrl,
  name,
  name_en,
  color,
  isFit = true
}) => {
  const { locale } = useGameContext();
  if (logoUrl) {
    return (
      <Wrapper className="team-image" style={{ width: diameter, height: diameter }}>
        <img
          src={logoUrl}
          alt=""
          className={"team-image__img" + (isFit ? " team-image__img--fit" : "")}
        />
      </Wrapper>
    );
  }

  return (
    <NoLogo
      diameter={diameter}
      name={name}
      name_en={name_en}
      color={color}
      locale={locale}
      sportsId={sportsId}
    />
  );
};

export const NoLogo = ({
  diameter,
  sportsId,
  name,
  name_en,
  color,
  locale
}: {
  diameter: number;
  sportsId?: string;
  name: string;
  name_en?: string;
  color?: string;
  locale?: string;
}) => {
  const isJa = locale === "ja";
  const sportsIcon = useMemo(() => {
    if (!sportsId) return;
    return SportsIconBlackBorder[sportsId];
  }, [sportsId]);

  const textColor = useMemo(() => {
    if (!color) return textSecondary;
    const mach = color.match(/^#([0-9a-fA-F]{6}|[0-9a-fA-F]{3})$/);
    if (!mach) {
      return textSecondary;
    }
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);
    const luminance = (0.2126 * r) / 255 + (0.7152 * g) / 255 + (0.0722 * b) / 255;
    return luminance > 0.5 ? textSecondary : textPrimary;
  }, [color]);

  return (
    <svg
      width={diameter}
      height={diameter}
      viewBox="0 0 212 212"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_20037_56687)">
        <rect width="212" height="212" rx="106" fill="white" />
        <g filter="url(#filter0_d_20037_56687)">
          <path
            d="M106.001 19.1416C97.7098 19.1416 64.6483 24.8959 36.0229 40.8799C32.2006 47.324 36.6428 154.916 41.1152 161.08C46.2039 167.473 95.8227 200.72 106.001 201.999C116.179 200.72 165.797 167.473 170.886 161.08C175.358 154.916 179.801 47.324 175.978 40.8799C147.353 24.8959 114.291 19.1416 106.001 19.1416Z"
            fill={color || "#FFFFFF"}
          />
        </g>
        <image href={sportsIcon} width="65" height="65" x={106 - 30} y={4} />
        <text
          x="50%"
          y="65%"
          font-family="Verdana"
          font-size="64"
          fill={textColor}
          stroke-width="1"
          textAnchor="middle"
          fontWeight={700}
        >
          {((isJa ? name : name_en) || "").slice(0, 2)}
        </text>
      </g>
      <defs>
        <filter
          id="filter0_d_20037_56687"
          x="23.1434"
          y="13.4273"
          width="165.714"
          height="205.714"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5.71429" />
          <feGaussianBlur stdDeviation="5.71429" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_20037_56687" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_20037_56687"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_20037_56687">
          <rect width="212" height="212" rx="106" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const Wrapper = styled.div`
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .team-image {
    &__img {
      width: 80%;
      height: 80%;
      object-fit: contain;
      &--fit {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
`;
