const paths = {
  home: "/",
  share: "/share",
  shareCollection: "/share/collection/:id",
  cheerBattleShare: "/cheerBattleShare",
  gamePreview: "/preview",
  gameSplash: "/_splash/game",
  playSplash: "/_splash/play",
  eventSplash: "/_splash/event",
  cheerBattle: "/cheerBattle",
  previewScoreCard: "/generate-card/score/preview",
  previewChangePlayerCard: "/generate-card/change-player/preview",
  genScoreCard: "/generate-card/score",
  genChangePlayerCard: "/generate-card/change-player",
  leaguePage: "/sports/tournaments",
  teamPage: "/sports/team",
  playerPage: "/sports/player",
  albumSplash: "/_splash/album"
};

export default paths;
