import { useState, useEffect, useCallback } from "react";
import { Points } from "models/game";
import { Timeline as TimelineType } from "models/timeline";
import { isNumber } from "lodash";

// appのuseGamePointsをコピー
export const useGamePoints = (
  timelineData: TimelineType[] | undefined
): {
  firstHalf: Points;
  lastHalf: Points;
} => {
  const [firstHalf, setFirstHalfPoints] = useState<Points>({
    home: "-",
    away: "-"
  });
  const [lastHalf, setLastHalfPoints] = useState<Points>({
    home: "-",
    away: "-"
  });

  const _setGamePoints = useCallback(() => {
    const firstHalf: Points = { home: "-", away: "-" };
    const lastHalf: Points = { home: "-", away: "-" };
    if (timelineData && timelineData.length) {
      firstHalf.home = 0;
      firstHalf.away = 0;

      timelineData.forEach(timelinePlay => {
        const homePoint = timelinePlay?.points ? timelinePlay.points.home : 0;
        const awayPoint = timelinePlay?.points ? timelinePlay.points.away : 0;

        if (timelinePlay.isFirstHalf) {
          // timelinePlay.pointsは登録されたときの得点が入ってるから、前半後半で最大値を取る
          if (homePoint && isNumber(firstHalf.home) && firstHalf.home < homePoint) {
            firstHalf.home = homePoint;
          }
          if (awayPoint && isNumber(firstHalf.away) && firstHalf.away < awayPoint) {
            firstHalf.away = awayPoint;
          }
        } else {
          if (homePoint && (lastHalf.home === "-" || lastHalf.home < homePoint)) {
            lastHalf.home = homePoint;
          }
          if (awayPoint && (lastHalf.away === "-" || lastHalf.away < awayPoint)) {
            lastHalf.away = awayPoint;
          }
        }
      });

      if (lastHalf.home !== "-") {
        lastHalf.home = lastHalf.home - firstHalf.home;
      }
      if (lastHalf.away !== "-") {
        lastHalf.away = lastHalf.away - firstHalf.away;
      }
    }
    setFirstHalfPoints(firstHalf);
    setLastHalfPoints(lastHalf);
  }, [timelineData]);

  useEffect(() => {
    if (!timelineData) {
      return;
    }
    _setGamePoints();
  }, [_setGamePoints, timelineData]);

  return {
    firstHalf,
    lastHalf
  };
};
