import React, { FC, ReactElement } from "react";
import styled from "styled-components";
import defaultPlayer from "assets/images/change_player_card/default_player_in.png";

type Props = {
  playerName: string;
  playerNameEn: string;
  uniformNumber: string;
  playerPhoto?: string;
};

const InPlayerLayer: FC<Props> = ({
  playerName,
  playerNameEn,
  uniformNumber,
  playerPhoto
}): ReactElement => {
  return (
    <Wrapper>
      <img
        src={playerPhoto ?? defaultPlayer}
        className="playerPhoto"
        onError={e => {
          e.currentTarget.src = defaultPlayer;
        }}
        alt={playerName}
      />
      <div className="playerInfoWrapper">
        <div className="playerInfo">
          <div className="upperSection">
            <span className="uniformNumber">{uniformNumber}</span>
            <span className="playerName">{playerName}</span>
          </div>
          <span className="playerNameEn">{playerNameEn}</span>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  .playerPhoto {
    position: absolute;
    width: 600px;
    height: 600px;
    right: -116px;
    top: 60px;
    z-index: 1;
  }
  .playerInfoWrapper {
    position: absolute;
    right: -40px;
    bottom: 20px;
    background-color: black;
    transform: skew(-30deg);
    z-index: 2;
  }
  .playerInfo {
    display: flex;
    height: 136px;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 10px 80px 10px 50px;
    transform: skew(30deg);
    text-align: right;
  }
  .upperSection {
    display: flex;
    justify-content: right;
    .uniformNumber {
      margin: 0px 20px 0px 5px;
      font-family: Oswald;
      font-style: normal;
      font-weight: bold;
      font-size: 46px;
      line-height: 64px;
      color: #000000;
      -webkit-text-stroke: 3px #ffffff;
      letter-spacing: -3px;
    }
    .playerName {
      font-family: "M + 1c";
      font-style: normal;
      font-weight: 900;
      font-size: 46px;
      line-height: 64px;
      color: #ffffff;
      text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
    }
  }
  .playerNameEn {
    font-family: Oswald;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 44px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #ffffff;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
  }
`;

export default InPlayerLayer;
