import React, { useEffect, useMemo } from "react";
import useReactRouter from "use-react-router";
import qs from "qs";
import styled from "styled-components";
import img_logo from "assets/images/logo.png";
import img_logoIcon from "assets/images/icon.png";
import img_bgFrame from "assets/images/bg_frame.png";

import { teamImgIsCircle } from "assets/sportsData";

import { splashBg } from "assets/js/sportsImages";
import { getTeamNameShort } from "assets/js/textConvert";
import setHtmlMeta from "assets/js/setHtmlMeta";
import placeIcon from "assets/images/game_card/multi_team_sports_card/place.svg";
import { TeamImage } from "components/atoms/TeamImage";
import { GameContextProvider, useGameContext } from "libs/gameContext";
const viewportSize = 1200;

const EventSplash: React.FC = () => {
  const {
    sportsId,
    gameData,
    leagueData,
    infoDateDay,
    infoDateDayOfWeek,
    infoDateTime,
    placeImageUrl,
    loading,
    error,
    locale
  } = useGameContext();
  const isJa = locale === "ja";
  const leagueText = (!isJa && gameData?.info_leagueText_en) || gameData?.info_leagueText;
  const placeName = (!isJa && gameData?.info_placeName_en) || gameData?.info_placeName;

  useEffect(() => {
    setHtmlMeta({ viewport: "width=" + viewportSize });
  }, []);

  const homeTeamNameJa = useMemo(
    () =>
      gameData?.homeTeam?.companyName ||
      gameData?.homeTeam?.name ||
      gameData?.team_homeName_ifEmptyTeamId ||
      "",
    [gameData]
  );

  const homeTeamNameEn = useMemo(
    () =>
      gameData?.homeTeam?.companyName_en ||
      gameData?.homeTeam?.name_en ||
      gameData?.team_homeName_ifEmptyTeamId_en ||
      homeTeamNameJa ||
      "",
    [
      gameData?.homeTeam?.companyName_en,
      gameData?.homeTeam?.name_en,
      gameData?.team_homeName_ifEmptyTeamId_en,
      homeTeamNameJa
    ]
  );

  if (loading) {
    // preview読み込み中に期待外の画面を表示させない
    return null;
  }
  if (!sportsId || !splashBg[sportsId] || error) {
    return <Wrapper bgimg={"/ogimage.png"}></Wrapper>;
  }
  return (
    <Wrapper bgimg={placeImageUrl || splashBg[sportsId]} className="p-eventSplash">
      <div className="p-eventSplash_bgFrame" />
      <div className="p-eventSplash_inner">
        <div className="p-eventSplash_inner_leagueText">{leagueText}</div>
        {placeName && (
          <p className="p-eventSplash_inner_place">
            <img className="placeIcon" src={placeIcon} alt="placeIcon" />
            {gameData && <span>{placeName}</span>}
          </p>
        )}

        {infoDateTime && (
          <p className="p-eventSplash_inner_date">
            <span className="p-eventSplash_inner_date_Day">{infoDateDay}</span>
            <span className="p-eventSplash_inner_date_DayOfWeek">{infoDateDayOfWeek}</span>
            <span className="p-eventSplash_inner_date_Time">{infoDateTime}</span>
            {/* https://team-spolive.slack.com/archives/CKKM0D7C1/p1684387163305929?thread_ts=1684382195.357929&cid=CKKM0D7C1 */}
            {/* <span className="p-eventSplash_inner_date_Utc">{infoDateUtc}</span> */}
          </p>
        )}
      </div>
      <div className="p-eventSplash_teamArea">
        <div className="p-eventSplash_teamImgWrapper" data-iscircle={teamImgIsCircle(sportsId)}>
          <TeamImage
            diameter={80}
            sportsId={sportsId}
            name={homeTeamNameJa}
            name_en={homeTeamNameEn}
            logoUrl={gameData?.homeTeam?.logoUrl}
            isFit={Boolean(leagueData?.isTeamImageFit)}
          />
        </div>
        <span className="p-eventSplash_teamName">{getTeamNameShort(isJa, true, gameData)}</span>
      </div>

      <img className="p-eventSplash_logoIcon" alt="spolive" src={img_logoIcon} />
      <img className="p-eventSplash_logo" alt="spolive" src={img_logo} />
    </Wrapper>
  );
};

const EventSplashWrap = () => {
  const { location } = useReactRouter();
  const url = decodeURIComponent(location.search);
  const {
    sportsId,
    leagueId,
    gameId,
    locale = "ja",
    homePoint,
    awayPoint
  } = qs.parse(url.replace("?", "")) as {
    sportsId: string;
    leagueId: string;
    gameId: string;
    locale: string;
    homePoint: string | null;
    awayPoint: string | null;
  };
  return (
    <GameContextProvider
      sportsId={sportsId}
      leagueId={leagueId}
      gameId={gameId}
      locale={locale}
      homePoint={homePoint ? parseInt(homePoint, 10) : undefined}
      awayPoint={awayPoint ? parseInt(awayPoint, 10) : undefined}
    >
      <EventSplash />
    </GameContextProvider>
  );
};
export default EventSplashWrap;

const Wrapper = styled.div<{ bgimg: string }>`
  position: relative;
  width: ${viewportSize}px;
  height: ${viewportSize * 0.525}px;
  margin: 0 auto;
  padding: 52px 14px;
  box-sizing: border-box;
  background-image: url(${({ bgimg }) => bgimg});
  background-size: cover;

  .p-eventSplash {
    &_labelArea {
      position: absolute;
      top: 60px;
    }

    &_bgFrame {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url(${img_bgFrame});
      background-color: rgba(0, 0, 0, 0.5);
    }
    &_inner {
      position: relative;
      height: calc(100% - 80px - 80px - 8px);
      margin-top: calc(86px - 8px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 56px;

      &_leagueText {
        font-size: 76px;
        font-weight: 700;
        text-align: center;
        line-height: 91px;

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      &_place {
        font-size: 40px;
        font-weight: 700;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-top: 32px;
      }
      &_date {
        text-align: center;
        padding: 0;
        vertical-align: baseline;
        &_Day {
          font-family: "Oswald";
          font-style: normal;
          font-weight: 500;
          font-size: 56px;
          line-height: 83px;
        }
        &_DayOfWeek {
          font-family: "Noto Sans";
          font-style: normal;
          font-weight: 900;
          font-size: 41px;
          line-height: 56px;

          margin: 0px 8px 0px 8px;
        }
        &_Time {
          font-family: "Oswald";
          font-style: normal;
          font-weight: 500;
          font-size: 56px;
          line-height: 83px;
        }
        &_Utc {
          font-family: "Oswald";
          font-style: normal;
          font-weight: 500;
          font-size: 41px;
          line-height: 61px;
          margin-left: 8px;
        }
      }
    }

    &_teamArea {
      position: absolute;
      left: 70px;
      bottom: 48px;
      width: calc(100% - 140px);
      justify-content: flex-start;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &_teamImgWrapper {
      display: block;
      background-color: #fff;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      margin-right: 16px;
    }
    &_teamImg {
      width: auto;
      height: 80%;
      object-fit: contain;
      &[data-isimagefit="true"] {
        width: auto;
        height: 100%;
      }
      &--blank {
        width: auto;
        height: 70%;
      }
    }
    &_teamName {
      min-width: 400px;
      max-width: calc(100% - 150px);
      font-size: 48px;
      font-weight: 700;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &_logo {
      position: absolute;
      width: 220px;
      height: 56px;
      top: 57px;
      left: 132px;
    }
    &_logoIcon {
      position: absolute;
      width: 67px;
      height: 67px;
      top: 50px;
      left: 50px;
    }
  }
  .placeIcon {
    margin-right: 20px;
  }
`;
