import React, { FC, ReactElement } from "react";
import styled from "styled-components";

type Props = {
  gameName: string;
};

const GameNameLayer: FC<Props> = ({ gameName }): ReactElement => {
  return (
    <Wrapper>
      <div className="gameNameWrapper">
        <div className="gameName">{gameName}</div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  .gameNameWrapper {
    position: absolute;
    left: 0px;
    top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 54px;
    font-family: Noto Sans JP;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 100%;
    /* identical to box height, or 14px */
    text-align: center;
    color: #ffffff;
  }
`;

export default GameNameLayer;
