import React, { FC, ReactElement } from "react";
import useReactRouter from "use-react-router";
import styled from "styled-components";
import qs from "qs";
import CheerBattleResultCard from "components/organisms/CheerBattleResultCard";
import { GameContextProvider } from "libs/gameContext";

type QueryParam = {
  sportsId: string;
  leagueId: string;
  gameId: string;
  teamId?: string;
  uid?: string;
  locale?: "ja" | "en";
};

const GenCheerBattleResultCard: FC = (): ReactElement => {
  const { location } = useReactRouter();
  const url = decodeURIComponent(location.search);
  const queryParam = qs.parse(url.replace("?", "")) as QueryParam;

  return (
    <Wrapper>
      <CheerBattleResultCard
        sportsId={queryParam.sportsId}
        leagueId={queryParam.leagueId}
        gameId={queryParam.gameId}
        teamId={queryParam.teamId}
        uid={queryParam.uid}
        locale={queryParam.locale ?? "ja"}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 1200px;
  height: 630px;
`;

const GenCheerBattleResultCardWrapper = () => {
  const { location } = useReactRouter();
  const url = decodeURIComponent(location.search);
  const queryParam = qs.parse(url.replace("?", "")) as QueryParam;
  const { sportsId, leagueId, gameId, locale } = queryParam;

  return (
    <GameContextProvider
      sportsId={sportsId}
      leagueId={leagueId}
      gameId={gameId}
      locale={locale ?? "ja"}
    >
      <GenCheerBattleResultCard />
    </GameContextProvider>
  );
};

export default GenCheerBattleResultCardWrapper;
