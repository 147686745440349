import React, { useCallback, useEffect, useRef, useState } from "react";
import anime from "animejs";
import { CheerComment } from "models/cheerComment";
import styled from "styled-components";
import { usePrevious } from "hooks/use-previous";
import useStateRef from "react-usestateref";

type Props = {
  comments: CheerComment[];
  isHome?: boolean;
};

const MAX_DISPLAY_COMMENT_NUM = 5;

export const CheerCommentItem: React.FC<Props> = ({ comments, isHome = true }) => {
  const elRef = useRef<HTMLDivElement>(null);

  const measuringRef = useRef<HTMLDivElement>(null);

  const animate = useCallback(async () => {
    const marginTop = Number(
      measuringRef.current
        ? getComputedStyle(measuringRef.current).getPropertyValue("margin-top").replace("px", "")
        : 0
    );
    const animeResult = anime({
      targets: elRef.current,
      translateY: measuringRef.current ? measuringRef.current?.offsetHeight + marginTop : 0,
      duration: 200,
      easing: "linear"
    });
    await animeResult.finished;
  }, []);
  const resetAnimate = useCallback(() => {
    anime({
      targets: elRef.current,
      translateY: 0,
      duration: 0
    });
  }, []);

  const [displayComments, setDisplayComments] = useState<CheerComment[]>([]);
  const [currentCommentIndex, setCurrentCommentIndex, currentCommentIndexRef] = useStateRef(0);

  const prevComments = usePrevious(comments);

  useEffect(() => {
    if (prevComments && prevComments?.length > comments.length) {
      const diff = prevComments.length - comments.length;
      setCurrentCommentIndex(state => state - diff);
      const startIndex = comments.length - MAX_DISPLAY_COMMENT_NUM;
      setDisplayComments(
        comments.slice(startIndex > 0 ? startIndex : 0, comments.length).reverse()
      );
    }
  }, [comments, comments.length, prevComments, setCurrentCommentIndex]);

  useEffect(() => {
    if (!currentCommentIndex && comments) {
      const startIndex = comments.length - MAX_DISPLAY_COMMENT_NUM;
      setDisplayComments(
        comments.slice(startIndex > 0 ? startIndex : 0, comments.length).reverse()
      );
      setCurrentCommentIndex(comments.length);
    }
  }, [comments, currentCommentIndex, setCurrentCommentIndex]);

  const pushComment = useCallback(async () => {
    await animate();
    setDisplayComments(state => {
      if (!comments[currentCommentIndexRef.current]) return state;

      return [comments[currentCommentIndexRef.current], ...state].slice(0, MAX_DISPLAY_COMMENT_NUM);
    });
    setCurrentCommentIndex(i => (i += 1));
    resetAnimate();
  }, [animate, comments, resetAnimate, currentCommentIndexRef, setCurrentCommentIndex]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (comments.length > currentCommentIndex) {
        pushComment();
      }
    }, 1500);
    return () => clearInterval(interval);
  }, [comments.length, currentCommentIndex, pushComment]);

  return (
    <Wrapper className="cheer-comment-item">
      <div ref={elRef} className="cheer-comment-item_comments-home-anime">
        <div className="cheer-comment-item_comment cheer-comment-item_measure" ref={measuringRef}>
          {comments[currentCommentIndex] && comments[currentCommentIndex].comment}
        </div>
        {displayComments.map((comment, i) => (
          <div
            key={comment.id}
            className={
              "cheer-comment-item_comment" + (isHome ? "" : " cheer-comment-item_comment--away")
            }
          >
            {comment.comment}
          </div>
        ))}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-top: 10px;
  width: 793px;
  width: 40%;
  bottom: 0px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .cheer-comment-item {
    opacity: 0;
    position: absolute;
    &_comment {
      font-size: 36px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      line-height: 1.25;
      width: 100%;
      margin-top: 20px;
      word-break: break-all;
      font-family: NotoSansJPWebFont;
      span {
        word-break: break-all;
      }
      &--away {
      }
    }
    &_measure {
      position: absolute;
      opacity: 0;
    }
  }
`;
