import React from "react";
import styled from "styled-components";
import appStoreBtn from "assets/images/appStoreBtn.png";
import playStoreBtn from "assets/images/playStoreBtn.png";

export const Footer = () => {
  return (
    <Wrapper>
      <div className="footer_area">
        <div style={{ display: "flex" }}>
          <a href={"https://apps.apple.com/jp/app/id1465570606"} target="_blank" rel="noreferrer">
            <img className="appstore_logo" src={appStoreBtn} alt="appStoreLogo" />
          </a>
          <div style={{ marginRight: 24, marginLeft: 11 }}>
            <a
              href="https://play.google.com/store/apps/details?id=com.ntt.spolive"
              target="_blank"
              rel="noreferrer"
            >
              <img className="playstore_logo" src={playStoreBtn} alt="playStoreLogo" />
            </a>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .footer_area {
    background-color: #000000;
    height: 64px;
    display: flex;
    align-items: center;
    padding-left: 18px;
    justify-content: center;
  }
  .appstore_logo {
    width: 113px;
    height: 32px;
  }
  .playstore_logo {
    width: 113px;
    height: 32px;
  }
`;
