import moment from "moment";
import firebase from "firebase/app";

import { getTeamNameShort } from "assets/js/textConvert";
import { getStatData } from "assets/sportsData";

import { Sports } from "models/sports";
import { Game } from "models/game";
import { League } from "models/league";

const displayWithLimit = (str: string, limitLength: number) => {
  if (str.length > limitLength) {
    return str.slice(0, limitLength) + "...";
  }
  return str;
};

export const getServiceConcept = (isJa: boolean, gameData: Game, isShort: boolean) => {
  const gameStatus = gameData.time_isGameEnd
    ? "finished"
    : !gameData.time_firstHalfStartTime
    ? "before"
    : "live";
  if (!isJa) {
    if (gameData.hasCommentary || !gameData.isOnlyResult) {
      return "| Providing live sports commentary in real-time";
    }
    return "| Enjoy cheering together! SpoLive provide live sports commentary in real-time";
  }

  switch (gameStatus) {
    //試合前
    case "before":
      if (gameData.hasCommentary || !gameData.isOnlyResult) {
        return isShort ? "" : "のリアルタイム速報・スタッツ・ルール解説を無料でお届けします！";
      } else {
        return isShort
          ? "を一緒に盛り上げよう！"
          : "を一緒に盛り上げよう！SpoLive(スポライブ)は試合の速報・スタッツ・ルール解説を無料でお届けします！";
      }
    //試合中
    case "live":
      if (gameData.hasCommentary || !gameData.isOnlyResult) {
        return isShort
          ? "をただいま速報中！"
          : "のリアルタイム速報・スタッツ・ルール解説を無料でお届けします！";
      } else {
        return isShort
          ? "を一緒に盛り上げよう！"
          : "を一緒に盛り上げよう！SpoLive(スポライブ)は試合の速報・スタッツ・ルール解説を無料でお届けします！";
      }
  }
  //試合終了後
  return isShort
    ? "の試合結果を確認しよう！"
    : "のリアルタイム速報・スタッツ・ルール解説を無料でお届けします！";
};

export const getGameStatusLabel = (isJa: boolean, gameData: Game) => {
  const gameStatus = gameData.time_isGameEnd
    ? "finished"
    : !gameData.time_firstHalfStartTime
    ? "before"
    : "live";
  if (!isJa) {
    if (!gameData.hasCommentary && gameData.isOnlyResult) {
      return gameStatus === "finished" ? "[RESULT]" : "";
    }
    return gameStatus === "finished" ? "[RESULT]" : "[LIVE]";
  }

  switch (gameStatus) {
    case "before":
      if (gameData.hasCommentary) {
        return "【実況/速報予定】";
      } else if (!gameData.isOnlyResult) {
        return "【速報予定】";
      } else {
        return "";
      }
    case "live":
      if (gameData.hasCommentary) {
        return "【実況/速報】";
      } else if (!gameData.isOnlyResult) {
        return "【速報】";
      } else {
        return "";
      }
    case "finished":
      return "【結果】";
  }
};

export const getTitleDescription = async (
  isJa: boolean,
  gameData: Game,
  sportsId: string,
  firestore: firebase.firestore.Firestore,
  leagueData: League,
  specifiedPlay: any
) => {
  const sportsData = (await firestore
    .collection(`sportsData`)
    .doc(sportsId)
    .get()
    .then(doc => doc.data())) as Sports;
  const homeTeamName = getTeamNameShort(isJa, true, gameData);
  const awayTeamName = getTeamNameShort(isJa, false, gameData);
  const gameTimeString = isJa
    ? moment.unix(gameData.info_datetime?.seconds || 0).format("YYYY年M月D日")
    : moment.unix(gameData.info_datetime?.seconds || 0).format("ll"); // Jun 9 2014
  const gameTimeStringShort = isJa
    ? moment.unix(gameData.info_datetime?.seconds || 0).format("M月D日")
    : moment.unix(gameData.info_datetime?.seconds || 0).format("MMM Do"); // Jun 9th

  const gameCard = homeTeamName && awayTeamName ? `${homeTeamName} vs ${awayTeamName}` : "";
  const leagueText = `${
    !isJa && gameData.info_commentaryText_en
      ? displayWithLimit(gameData.info_commentaryText_en || "", 50)
      : displayWithLimit(gameData.info_commentaryText || "", 50)
  }`;
  const leagueTextShort = `${(!isJa && gameData.info_leagueText_en) || gameData.info_leagueText}`;

  const spoliveName = isJa ? "SpoLive(スポライブ)" : "SpoLive";

  let title = `${getGameStatusLabel(isJa, gameData)} ${gameCard} ${leagueTextShort} ${
    isJa ? sportsData.name : sportsData.name_en
  } ${gameTimeStringShort} ${getServiceConcept(isJa, gameData, true)} | ${spoliveName}`;

  if (specifiedPlay?.player) {
    try {
      const isHomePlay = specifiedPlay?.player?.hasOwnProperty("home");
      const stat = getStatData(specifiedPlay.type, sportsId);
      const { isFirstHalf } = specifiedPlay;
      const playerId = specifiedPlay?.player?.[isHomePlay ? "home" : "away"];
      const playTeamData = gameData[isHomePlay ? "homeTeam" : "awayTeam"];
      const playerData = playTeamData?.players?.[playerId];
      if (!playerData) {
        throw Error("no player");
      }
      const playTeamName = isHomePlay ? homeTeamName : awayTeamName;
      const time = Number(specifiedPlay.time.split(".")[0]);
      if (isJa) {
        title = `【速報】 ${isFirstHalf ? "前半" : "後半"}${time}分 ${playTeamName} ${
          playerData.name
        }選手の${stat?.name}！！ - ${gameCard} ${leagueData.name} ${sportsData.name}`;
      } else {
        title = `[LIVE] ${isFirstHalf ? "1st" : "2nd"} ${time}' ${playTeamName} ${
          stat?.name_en
        } by ${playerData.name_en || playerData.name}！！ - ${gameCard} ${
          leagueData.name_en || leagueData.name
        } ${sportsData.name_en}`;
      }
    } catch (err) {}
  }

  return {
    title,
    description: `${gameTimeString} ${leagueText} ${gameCard} ${getServiceConcept(
      isJa,
      gameData,
      false
    )} | ${spoliveName}`
  };
};
