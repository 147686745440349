import React, { FC, ReactElement } from "react";
import styled from "styled-components";
import { TeamPosition } from "../types";

type Props = {
  gameName: string;
  gameStatus: string;
  homeTeamIcon: string;
  awayTeamIcon: string;
  homeScore: string;
  awayScore: string;
  teamPosition: TeamPosition;
};

const Game: FC<Props> = ({
  gameName,
  gameStatus,
  homeScore,
  homeTeamIcon,
  awayScore,
  awayTeamIcon,
  teamPosition
}): ReactElement => {
  return (
    <Wrapper teamPosition={teamPosition}>
      <div className="gameStatusContainer">
        <img className="homeTeamIcon" src={homeTeamIcon} alt="homeTeamIcon" />
        <div className="middlePartContainer">
          <div className="score">
            <span className="homeScore">{homeScore}</span>
            <span className="scorePipe">-</span>
            <span className="awayScore">{awayScore}</span>
          </div>
          <span className="gameStatus">{gameStatus}</span>
        </div>
        <img className="awayTeamIcon" src={awayTeamIcon} alt="awayTeamIcon" />
      </div>
      <div className="gameName">{gameName}</div>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ teamPosition: TeamPosition }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .homeTeamIcon {
    width: 120px;
    height: 120px;
    box-sizing: border-box;
    padding: 16px;
  }
  .awayTeamIcon {
    width: 120px;
    height: 120px;
    box-sizing: border-box;
    padding: 16px;
  }
  .gameStatusContainer {
    display: flex;
  }
  .middlePartContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px 3px 0px 3px;
    .score {
      display: flex;
      align-items: center;
      .homeScore {
        font-family: Oswald;
        font-style: normal;
        font-weight: bold;
        font-size: 92px;
        line-height: 117px;
        /* identical to box height */
        text-align: center;
        text-transform: uppercase;

        color: ${props => (props.teamPosition === "home" ? "#ffef64" : "#ffffff")};

        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
      }
      .scorePipe {
        font-family: Oswald;
        font-style: normal;
        font-weight: bold;
        font-size: 67.5px;
        line-height: 86px;
        /* identical to box height */
        text-align: center;
        text-transform: uppercase;

        color: #ffffff;
        margin: 0px 3px 0px 3px;
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
      }
      .awayScore {
        font-family: Oswald;
        font-style: normal;
        font-weight: bold;
        font-size: 92px;
        line-height: 117px;
        /* identical to box height */
        text-align: center;
        text-transform: uppercase;

        color: ${props => (props.teamPosition === "away" ? "#ffef64" : "#ffffff")};

        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
      }
    }
    .gameStatus {
      font-family: Noto Sans JP;
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 30px;
      /* identical to box height, or 100% */
      text-align: center;
      color: #ffffff;
    }
  }
  .gameName {
    margin: 25px 0px 25px 0px;
    font-family: Noto Sans JP;
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 100%;
    /* identical to box height, or 14px */

    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;
  }
`;

export default Game;
