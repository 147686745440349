import React, { useEffect, useState, useCallback, useRef, useContext, useMemo } from "react";
import {
  Game,
  isBreakPeriod,
  transformContractToPeriodSetModel,
  transformContractToPeriodTimeStampModel
} from "models/game";
import moment from "moment";
import styled, { css } from "styled-components";
import { getIsCountDownTime, periodNames } from "assets/sportsData";
import anime from "animejs";
import { Typography } from "components/atoms/Typography";
import { GameDataContext } from "./hooks/gameData";
import {
  getCurrentScoreName,
  getPeriodTime,
  isStartFirstHalfSecondHalfGame,
  zeroPadding
} from "libs/gameTimeTextGenerator";
import { getTeamName } from "libs/scoreboard";
import { DateTime } from "./componets/DateTime";
import { TimeScore } from "./componets/TimeScore";
import { NoLogo } from "components/atoms/TeamImage";
import { useAsyncMemo } from "@spolive-team/hooks";
import { fetchTeamMaster } from "assets/js/firebase";

type Props = {
  gameData: Game | undefined;
  latestPoints: {
    home: number;
    away: number;
  };
  sportsId: string;
  scale: number;
  isShownTime: boolean;
  teamFoul: { away: number; home: number } | undefined;
};

export const Score: React.FC<Props> = ({
  gameData,
  latestPoints,
  sportsId,
  scale,
  teamFoul,
  isShownTime
}) => {
  const awayScoreEl = useRef<HTMLSpanElement>(null);
  const homeScoreEl = useRef<HTMLSpanElement>(null);

  const { isJa } = useContext(GameDataContext);

  const animatePoint = useCallback((scoreEl: React.RefObject<HTMLSpanElement>) => {
    anime({
      targets: scoreEl.current,
      scale: 1.2,
      duration: 250,
      easing: "linear",
      complete: () => {
        anime({
          targets: scoreEl.current,
          scale: 1.0,
          duration: 250,
          easing: "linear"
        });
      }
    });
  }, []);

  useEffect(() => {
    animatePoint(homeScoreEl);
  }, [animatePoint, latestPoints.home]);

  useEffect(() => {
    animatePoint(awayScoreEl);
  }, [animatePoint, latestPoints.away]);

  const hasPeriodList = useMemo(() => {
    return !!gameData?.periodList && gameData?.periodList.length > 0;
  }, [gameData?.periodList]);

  const hasPeriodModel = useMemo(() => {
    return !!gameData?.periods && gameData?.periods.periods.length > 0;
  }, [gameData?.periods]);

  const hasStartedPeriodTimeStamp = useMemo(() => {
    return (
      !!gameData?.periodTimeStamps &&
      gameData.periodTimeStamps.length > 0 &&
      gameData.periodTimeStamps.filter(p => p.startedAt).length > 0
    );
  }, [gameData?.periodTimeStamps]);

  const [time, setTime] = useState<string | null>("00:00");

  const isLiveGame = useMemo(
    () =>
      gameData &&
      (getPeriodTime(sportsId, gameData, true) ||
        isStartFirstHalfSecondHalfGame(gameData) ||
        hasStartedPeriodTimeStamp),
    [gameData, sportsId, hasStartedPeriodTimeStamp]
  );

  const isGameStart = useMemo(() => {
    //ピリオド形式かつカウントアップの試合で試合時間があり、終了されていない場合は開始状態
    if (
      !!gameData &&
      hasPeriodList &&
      (!!getPeriodTime(sportsId, gameData, getIsCountDownTime(sportsId)) ||
        !!gameData?.time_isGameEnd)
    ) {
      return true;
    }
    // ピリオド共通化形式かつ試合時間があり、終了されていない場合は開始状態
    if (!!gameData && hasPeriodModel && hasStartedPeriodTimeStamp && !gameData?.time_isGameEnd) {
      return true;
    }
    //前半後半の試合で開始されていて、終了されていない場合は開始状態
    if (gameData && (isStartFirstHalfSecondHalfGame(gameData) || !!gameData?.time_isGameEnd)) {
      return true;
    }
    if (gameData?.period_currentStatusId && gameData?.period_currentStatusId !== "") {
      return true;
    }

    return false;
  }, [gameData, hasPeriodList, hasPeriodModel, sportsId, hasStartedPeriodTimeStamp]);
  const isGameEnd = useMemo(() => !!gameData && !!gameData?.time_isGameEnd, [gameData]);

  const timeScoreName = useMemo(() => {
    if (isGameEnd) return "Final";
    return gameData ? getCurrentScoreName(sportsId, gameData, isJa) : null;
  }, [gameData, sportsId, isGameEnd, isJa]);

  useEffect(() => {
    const _setNowGameTime = () => {
      if (!gameData) {
        return;
      }
      const isCountDownTime = getIsCountDownTime(sportsId);
      if (isCountDownTime) {
        if (gameData?.stopGameTime && gameData.stopGameTime !== "") {
          setTime(gameData.stopGameTime);
          return;
        }
        const time = getPeriodTime(sportsId, gameData, true);
        if (!time) return;
        setTime(zeroPadding(time.min, 2) + ":" + zeroPadding(time.sec, 2));
        return;
      }
      const {
        time_isFirstHalfEnd,
        time_firstHalfStartTime,
        time_secondHalfStartTime,
        time_firstHalfDiffTimeSec = 0,
        time_secondHalfDiffTimeSec = 0
      } = gameData;

      // ゲーム終了または休憩ピリオドなら時間はnull
      if (isGameEnd) {
        setTime(null);
      }

      if ((!time_isFirstHalfEnd && !time_secondHalfStartTime) || time_firstHalfStartTime) {
        setTime("00:00");
      }

      const dbTime = moment().diff(
        moment.unix(
          time_secondHalfStartTime && !(sportsId === "flyingdisc")
            ? time_secondHalfStartTime.seconds
            : time_firstHalfStartTime?.seconds || 0
        )
      );

      const diffTimeSec =
        (!time_secondHalfStartTime || sportsId === "flyingdisc"
          ? time_firstHalfDiffTimeSec
          : time_secondHalfDiffTimeSec) || 0;

      let nowGameTimeMinute = String(
        Math.floor(
          moment
            .duration(dbTime)
            .add(-1 * diffTimeSec, "seconds")
            .asMinutes()
        )
      );
      nowGameTimeMinute = ("00" + nowGameTimeMinute).slice(-2);
      const nowGameTimeSecond = moment(dbTime)
        .add(-1 * diffTimeSec, "seconds")
        .format("ss");
      const nowGameTime = `${nowGameTimeMinute}:${nowGameTimeSecond}`;
      setTime(nowGameTime);
    };

    const _setNowGameTimeFromPeriodTimeStamp = () => {
      if (!gameData) return;
      const AdditionalLimitSeconds = 60 * 60; // durationSecondsを超えてカウントを動かすための追加時間（「durationSeconds + この時間」を超えたらカウントを止める）

      const { period_currentStatusId, time_pauseStartTime, time_isGameEnd, periods } = gameData;
      const periodSets = transformContractToPeriodSetModel(periods ? [periods] : [], "Prohibited");
      const periodModels = periodSets[0]?.periods;
      const periodTimeStampModel = transformContractToPeriodTimeStampModel(
        gameData?.periodTimeStamps ? gameData.periodTimeStamps : []
      );
      const currentPeriod = periodModels?.find(p => p.id === period_currentStatusId);

      if (
        !period_currentStatusId ||
        !currentPeriod ||
        !periodTimeStampModel ||
        time_isGameEnd ||
        isBreakPeriod(currentPeriod) ||
        currentPeriod?.timerType === "None"
      ) {
        // 試合終了 or 休憩中 or 時間非表示なら時間をnullにする
        setTime(null);
        return;
      }
      // 時間制限なしの場合は∞表示
      if (currentPeriod.durationSeconds === null) {
        setTime("∞");
        return;
      }
      if (currentPeriod && periodTimeStampModel[period_currentStatusId]?.finishedAt) {
        setTime("00:00");
        return;
      }

      // 現在までの経過時間を計測
      const diffTimeSec = periodTimeStampModel[period_currentStatusId]?.diffTimeSec || 0;
      const startedAt = periodTimeStampModel[period_currentStatusId]?.startedAt?.toDate();

      const calculateNowDiffSeconds = (isPaused: boolean) => {
        const baseTime = isPaused ? moment(time_pauseStartTime?.toDate()) : moment();
        return Math.max(0, baseTime.add(-1 * diffTimeSec, "seconds").diff(startedAt, "seconds"));
      };

      const formatTime = (seconds: number) => {
        const minutes = Math.max(Math.floor(seconds / 60), 0);
        const formattedMinutes = String(minutes).padStart(2, "0");
        const displaySec = String(seconds % 60).padStart(2, "0");
        return `${formattedMinutes}:${displaySec}`;
      };

      if (currentPeriod.timerType !== "CountDown") {
        // カウントアップの場合は、開始時刻(or pause時刻)から現在時刻までの経過時間を表示
        const nowDiffSeconds = calculateNowDiffSeconds(!!time_pauseStartTime);
        const maxDisplayTime =
          currentPeriod.durationSeconds && currentPeriod.durationSeconds + AdditionalLimitSeconds;
        const effectiveTime =
          maxDisplayTime && nowDiffSeconds > maxDisplayTime ? maxDisplayTime : nowDiffSeconds;
        const displayTime = formatTime(effectiveTime);
        setTime(displayTime);
      } else {
        // カウントダウンの場合は、durationSecondsから、開始時刻(or pause時刻)から現在時刻までの経過時間を引く
        const periodDurationTime = currentPeriod.durationSeconds || 0;
        const nowDiffSeconds = periodDurationTime - calculateNowDiffSeconds(!!time_pauseStartTime);
        if (nowDiffSeconds < 1) {
          setTime("00:00");
        } else {
          const displayTime = formatTime(nowDiffSeconds);
          setTime(displayTime);
        }
      }
      return;
    };

    const _setNowGameTimeForPeriod = () => {
      if (!gameData) {
        return;
      }
      const { periodList, period_currentStatusId, time_pauseStartTime, stopGameTime } = gameData;
      const isCountDownTime = getIsCountDownTime(sportsId);

      const currentPeriod =
        !period_currentStatusId || !periodList?.filter(p => p.startedAt)?.length
          ? null
          : periodList?.filter(p => p.id === period_currentStatusId)[0];
      if (isGameEnd || !currentPeriod || periodNames(sportsId)[currentPeriod.id]?.isBreak) {
        setTime(null);
        return;
      }
      if (!period_currentStatusId || !currentPeriod) {
        setTime("00:00");
        return;
      }

      //時間が止められている時（stopGameTime）は止められた時間を設定する。
      if (stopGameTime && stopGameTime !== "") {
        setTime(stopGameTime);
        return;
      }
      const dbTime = moment().diff(moment.unix(currentPeriod?.startedAt?.seconds!));
      const diffTimeSec = currentPeriod.diffTimeSec || 0;
      if (!isCountDownTime) {
        const nowGameTime = moment(dbTime)
          .add(-1 * diffTimeSec, "seconds")
          .format("mm:ss");
        setTime(nowGameTime);
      } else {
        const periodDic = periodNames(sportsId)[currentPeriod.id];
        let maxTimeMin: undefined | number;
        if (periodDic?.isExtra) {
          maxTimeMin = gameData?.periodOT ?? periodDic?.displayMaxTimeMin;
        } else {
          maxTimeMin = gameData?.periodTime ?? periodDic?.displayMaxTimeMin;
        }
        const nowDiffSeconds =
          (maxTimeMin || 10) * 60 -
          (time_pauseStartTime
            ? moment(time_pauseStartTime?.toDate())
                .add(-1 * diffTimeSec, "seconds")
                .diff(currentPeriod?.startedAt?.toDate(), "seconds")
            : moment()
                .add(-1 * diffTimeSec, "seconds")
                .diff(currentPeriod?.startedAt?.toDate(), "seconds"));
        if (nowDiffSeconds < 1) {
          setTime("00:00");
        } else {
          let displayMin = String(Math.max(Math.floor(nowDiffSeconds / 60), 0));
          displayMin = (" " + displayMin).slice(-2);
          const displaySec = ("00" + (nowDiffSeconds % 60)).slice(-2);
          setTime(`${displayMin}:${displaySec}`);
        }
      }
    };

    const interval = setInterval(() => {
      hasPeriodList
        ? _setNowGameTimeForPeriod()
        : hasPeriodModel
        ? _setNowGameTimeFromPeriodTimeStamp()
        : _setNowGameTime();
    }, 1000);
    return () => clearInterval(interval);
  }, [gameData, hasPeriodList, hasPeriodModel, sportsId, isGameEnd]);

  const hasFouls = useMemo(() => sportsId === "basketball3x3", [sportsId]);
  const getBonus = (isHome: boolean) => {
    const foul = isHome ? teamFoul?.home : teamFoul?.away;
    if (!foul) return;
    if (foul <= 6) {
      return;
    } else if (foul <= 9) {
      return <div />;
    } else {
      return (
        <>
          <div />
          <div />
        </>
      );
    }
  };

  const homeTeamMaster = useAsyncMemo(async () => {
    const teamId = gameData?.homeTeam?.masterTeamId;
    if (!teamId) return;
    try {
      return await fetchTeamMaster(sportsId, teamId);
    } catch (error) {
      console.error("Failed to fetch home team master data:", error);
      return null;
    }
  }, [gameData?.homeTeam]);

  const awayTeamMaster = useAsyncMemo(async () => {
    const teamId = gameData?.awayTeam?.masterTeamId;
    if (!teamId) return;
    try {
      return await fetchTeamMaster(sportsId, teamId);
    } catch (error) {
      console.error("Failed to fetch away team master data:", error);
      return null;
    }
  }, [gameData?.awayTeam]);

  const homeTeamNameJa = useMemo(() => {
    return (
      gameData?.homeTeam?.companyName ||
      gameData?.homeTeam?.name ||
      gameData?.team_homeName_ifEmptyTeamId ||
      ""
    );
  }, [
    gameData?.homeTeam?.companyName,
    gameData?.homeTeam?.name,
    gameData?.team_homeName_ifEmptyTeamId
  ]);
  const homeTeamNameEn = useMemo(() => {
    return gameData?.homeTeam?.companyName_en || gameData?.homeTeam?.name_en || homeTeamNameJa;
  }, [gameData?.homeTeam, homeTeamNameJa]);
  const awayTeamNameJa = useMemo(() => {
    return (
      gameData?.awayTeam?.companyName ||
      gameData?.awayTeam?.name ||
      gameData?.team_awayName_ifEmptyTeamId ||
      ""
    );
  }, [
    gameData?.awayTeam?.companyName,
    gameData?.awayTeam?.name,
    gameData?.team_awayName_ifEmptyTeamId
  ]);
  const awayTeamNameEn = useMemo(() => {
    return gameData?.awayTeam?.companyName_en || gameData?.awayTeam?.name_en || awayTeamNameJa;
  }, [gameData?.awayTeam, awayTeamNameJa]);

  const pkScores = useMemo(() => {
    // infringementDataからkeyがpenaltyShootoutのもので、valueにhomeとawayのkey-valueがある場合、homeとawayの値を取り出す
    const { home = 0, away = 0 } = gameData?.infringements?.penaltyShootout || {};
    if (home === 0 && away === 0) return null;
    return { home, away };
  }, [gameData?.infringements]);

  return (
    <Wrapper className="p-score" scale={1} isShownTime={isShownTime} isLiveGame={isGameStart}>
      {isGameStart ? <TimeScore time={time} period={timeScoreName} /> : <DateTime />}
      {(isLiveGame || isGameEnd) && (
        <div className="p-score-point">
          <div className="p-score-point_wrapper">
            <span className="p-score-point_point" ref={homeScoreEl}>
              {latestPoints.home}
              {pkScores && <span style={{ fontSize: "80px" }}>({pkScores.home})</span>}
            </span>
            <span className="p-score-point_point" ref={awayScoreEl}>
              {latestPoints.away}
              {pkScores && <span style={{ fontSize: "80px" }}>({pkScores.away})</span>}
            </span>
          </div>
        </div>
      )}
      {hasFouls && (isLiveGame || isGameEnd) && (
        <div className="p-score_status">
          <div className="p-score_status_item">
            <div className="p-score_status_item_wrapper">
              <h4 className="p-score_status_item_name">FOULS</h4>
              <div className="p-score_status_item_board">{teamFoul?.home || 0}</div>
            </div>
            <div className="p-score_status_item_bonus_wrapper">
              <div className="p-score_status_item_bonus">{getBonus(true)}</div>
            </div>
          </div>
          <div className="p-score_status_logo">
            <div className="p-score_status_item_container">
              <div className="p-score_status_item_bonus">{getBonus(false)}</div>
            </div>
          </div>
          <div className="p-score_status_item">
            <div className="p-score_status_item_wrapper">
              <h4 className="p-score_status_item_name">FOULS</h4>
              <div className="p-score_status_item_board">{teamFoul?.away || 0}</div>
            </div>
          </div>
        </div>
      )}
      <div className="p-score_team-wrapper">
        <div className="p-score_team">
          <div className="p-score_team_img-wrapper">
            {gameData?.homeTeam?.logoUrl ? (
              <img src={gameData?.homeTeam?.logoUrl} alt="" className="p-score_team_img" />
            ) : (
              <NoLogo
                diameter={412}
                sportsId={sportsId}
                name={homeTeamNameJa}
                name_en={homeTeamNameEn}
                color={homeTeamMaster?.color}
                locale={isJa ? "ja" : "en"}
              />
            )}
          </div>
          <div className="p-score_team_score">
            <h3 className="p-score_team_name" style={{ display: "block" }}>
              <Typography
                text={gameData ? getTeamName(true, gameData, isJa) : ""}
                style={css`
                  word-break: break-word;
                  word-wrap: break-word;
                  overflow-wrap: break-word;
                `}
              />
            </h3>
          </div>
        </div>
        <div className="p-score_team">
          <div className="p-score_team_img-wrapper">
            {gameData?.awayTeam?.logoUrl ? (
              <img src={gameData?.awayTeam?.logoUrl} alt="" className="p-score_team_img" />
            ) : (
              <NoLogo
                diameter={412}
                sportsId={sportsId}
                name={awayTeamNameJa}
                name_en={awayTeamNameEn}
                color={awayTeamMaster?.color}
                locale={isJa ? "ja" : "en"}
              />
            )}
          </div>
          <span className="p-score_team_score p-score_team_score--away">
            <h3 className="p-score_team_name" style={{ display: "block" }}>
              <div>
                <Typography
                  text={gameData ? getTeamName(false, gameData, isJa) : ""}
                  style={css`
                    word-break: break-word;
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                  `}
                />
              </div>
            </h3>
            {/* <span ref={awayScoreEl}>{latestPoints.away}</span> */}
          </span>
        </div>
      </div>
    </Wrapper>
  );
};

type WrapperProps = {
  scale: number;
  isShownTime: boolean;
  isLiveGame: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  transform: scale(${({ scale }) => scale})
    translateY(
      ${({ scale }) => {
        return scale === 1 ? "0px" : "-57px";
      }}
    );
  box-sizing: border-box;
  position: relative;
  transition: transform 0.5s, top 0.5s;
  display: flex;
  justify-content: space-between;
  margin: ${({ isLiveGame }) => (isLiveGame ? "0" : " 0 120px 0 120px")};
  margin-bottom: ${({ scale }) => {
    return scale === 1 ? "-70px" : "-90px";
  }};
  .p-score_status {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    top: 330px;
    z-index: 2000;
    &_logo {
      display: flex;
      justify-content: flex-end;
      width: 400px;
    }
    &_item {
      display: flex;
      width: 250px;
      justify-content: space-around;
      &_name {
        text-align: center;
        font-weight: bold;
        font-size: 38px;
        margin-bottom: 24px;
      }
      &_board {
        border: 1px solid #ffffff;
        background-color: #111111;
        font-size: 66px;

        text-align: center;
        font-weight: bold;
        padding: 3px 37px;
        border-radius: 9px;
        width: 122px;
        height: 102px;
      }
      &_bonus {
        &_wrapper {
          padding-top: 80px;
          margin-right: 32px;
          margin-left: 32px;
        }
        div {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background-color: #80fb20;
          box-shadow: 0px 0px 8px 8px #499c0980;
          margin-top: 16px;
          margin-bottom: 16px;
        }
      }
    }
  }
  .p-score {
    &_team {
      width: 450px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &_score {
        h3 {
          font-weight: bold;
          text-align: center;
          font-size: 50px;
          line-height: 1;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 70px;
          width: 100%;
        }
        text-align: center;
        color: #fdf182;
        font-size: 130px;
        font-weight: 500;
        line-height: 100px;
        position: relative;
        top: -23px;
        padding: 20px 0 30px 0;
        span {
          position: relative;
          display: block;
        }
      }
      &_img {
        width: 100%;
        max-height: 100%;
        object-fit: contain;
        filter: drop-shadow(0px 8px 8px rgba(255, 255, 255, 0.7));
      }
      &_img-wrapper {
        width: 100%;
        height: 412px;
        display: flex;
        align-items: center;
        top: 64px;
        padding: 0 15px 0 15px;
      }
    }
    &_team-wrapper {
      position: absolute;
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 64px;
    }
  }
  .p-score-point {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    top: 80px;
    &_wrapper {
      width: 964px;
      display: flex;
      justify-content: space-between;
    }
    &_point {
      line-height: 1;
      font-size: 180px;
      width: 257px;
      text-align: center;
      font-family: "OswaldWebFontBold";
    }
  }
  .p-score_status_item_container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }
`;
