import React, { useEffect } from "react";
import { goLP } from "assets/js/goLP";

const Home: React.FC = () => {
  useEffect(() => {
    goLP(0);
  }, []);
  return <div></div>;
};

export default Home;
