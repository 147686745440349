import { useAsyncMemo } from "@spolive-team/hooks";
import { GiftComment } from "models/giftComment";
import { useContext, useMemo } from "react";
import firebase from "firebase/app";
import { LiveGameContext } from "components/pages/GamePreview/LiveGame";
import { SubscribeUser } from "@spolive-team/model";

export const useSubscribeUser = (cheerGiftComment: GiftComment | null) => {
  const { homeSubscribeUsers, awaySubscribeUsers } = useContext(LiveGameContext);
  const subscribeUser = useMemo(() => {
    if (!cheerGiftComment) return undefined;
    if (cheerGiftComment.isHomeTeamSelect)
      return homeSubscribeUsers?.find(user => user.uid === cheerGiftComment.uid);
    return awaySubscribeUsers?.find(user => user.uid === cheerGiftComment.uid);
  }, [awaySubscribeUsers, cheerGiftComment, homeSubscribeUsers]);
  return subscribeUser;
};

// callable functionsのgetSubscribeUsersを使ってsubscribeUsersを取得するhooks
export const useSubscribeUsers = (sportsId: string, teamMasterId?: string) => {
  const subscribeUsers = useAsyncMemo(async () => {
    if (!teamMasterId) return undefined;
    try {
      const subscribeUsers = await getSubscribeUsers(sportsId, teamMasterId);
      return subscribeUsers;
    } catch (e) {
      console.error("getSubscribeUsersでエラー発生", e);
      return undefined;
    }
  }, [sportsId, teamMasterId]);
  return subscribeUsers as SubscribeUser[] | undefined;
};

const getSubscribeUsers = async (sportsId: string, teamMasterId: string) => {
  // getSubscribeUsersCallableを呼び出す
  const getSubscribeUsersCallable = await firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("modules-getSubscribeUsers")({
    sportsId,
    communityId: teamMasterId,
    type: "team"
  });
  return getSubscribeUsersCallable.data.subscribeUsers as SubscribeUser[];
};
