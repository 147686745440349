import React, { useCallback, useEffect, useRef } from "react";
import { GiftComment } from "models/giftComment";
import { SuperCheer } from "./componets/SuperCheer";
import anime from "animejs";
import { wait } from "assets/js/wait";
import { usePrevious } from "hooks/use-previous";

const useAnimatedSuperCheer = ({
  cheerGiftComment,
  animateEnd
}: {
  cheerGiftComment: GiftComment | null;
  animateEnd: () => void;
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const commentRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const superCheerContentRef = useRef<HTMLDivElement>(null);
  const isAnimating = useRef(false);
  const prevCheerGiftComment = usePrevious(cheerGiftComment);

  const initializeAnimation = useCallback(async () => {
    if (wrapperRef.current && commentRef.current && imageRef.current) {
      const isHome = cheerGiftComment?.isHomeTeamSelect;
      const wrapperWidth = wrapperRef.current.offsetWidth;
      if (prevCheerGiftComment?.isHomeTeamSelect !== isHome) {
        const wrapper = anime({
          targets: wrapperRef.current,
          translateX: isHome ? -wrapperWidth : wrapperWidth,
          duration: 0
        });
        await wrapper.finished;
      }
      const superCheerContent = anime({
        targets: superCheerContentRef.current,
        opacity: 0,
        duration: 0,
        easing: "linear"
      });
      await superCheerContent.finished;
      const center = (wrapperRef.current.clientWidth - 370) / 2;

      const superCheerContentPosition = center - imageRef.current.offsetLeft;
      const image = anime({
        targets: imageRef.current,
        translateX: isHome ? superCheerContentPosition : superCheerContentPosition,
        scale: 3,
        translateY: -160,
        duration: 0,
        easing: "linear"
      });
      await image.finished;
    }
  }, [cheerGiftComment?.isHomeTeamSelect, prevCheerGiftComment?.isHomeTeamSelect]);

  useEffect(() => {
    (async () => {
      if (
        !cheerGiftComment ||
        isAnimating.current ||
        cheerGiftComment.id === prevCheerGiftComment?.id
      )
        return;
      isAnimating.current = true;
      await initializeAnimation();
      await wait(1000);
      const wrapper = anime({
        targets: wrapperRef.current,
        translateX: 0,
        duration: 200,
        easing: "linear"
      });
      await wrapper.finished;
      await wait(1000);
      const superCheerContent = anime({
        targets: superCheerContentRef.current,
        opacity: 1,
        translateX: 0,
        duration: 200,
        easing: "linear"
      });
      await superCheerContent.finished;
      await wait(2000);
      const image = anime({
        targets: imageRef.current,
        scale: 1,
        duration: 500,
        easing: "linear",
        translateX: 0,
        translateY: "-50%"
      });
      await image.finished;
      await wait(3000);
      isAnimating.current = false;

      animateEnd();
    })();
  }, [animateEnd, cheerGiftComment, initializeAnimation, prevCheerGiftComment?.id]);

  return {
    wrapperRef,
    commentRef,
    imageRef,
    superCheerContentRef
  };
};

type Props = {
  homeLogo: string;
  awayLogo: string;
  cheerGiftComment: GiftComment | null;
  iconUrl: string;
  isShow: boolean;
  homeTeamColor: string | undefined;
  awayTeamColor: string | undefined;
  animateEnd: () => void;
};

export const AnimatedSuperCheer: React.FC<Props> = ({
  homeLogo,
  awayLogo,
  cheerGiftComment,
  iconUrl = "",
  isShow,
  homeTeamColor,
  awayTeamColor,
  animateEnd
}) => {
  const { wrapperRef, commentRef, imageRef, superCheerContentRef } = useAnimatedSuperCheer({
    cheerGiftComment,
    animateEnd
  });

  return (
    <SuperCheer
      homeLogo={homeLogo}
      awayLogo={awayLogo}
      cheerGiftComment={cheerGiftComment}
      iconUrl={iconUrl}
      isShow={isShow}
      homeTeamColor={homeTeamColor}
      awayTeamColor={awayTeamColor}
      wrapperRef={wrapperRef}
      commentRef={commentRef}
      imageRef={imageRef}
      superCheerContentRef={superCheerContentRef}
    />
  );
};
