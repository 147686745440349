import React, { useEffect } from "react";
import setHtmlMeta from "assets/js/setHtmlMeta";
import styled from "styled-components";
import poweredByLogo from "./assets/poweredByLogo.png";
import photoGallery from "./assets/photoGallery.png";
import { useAsyncMemo } from "@spolive-team/hooks";
import { fetchTeamMaster } from "assets/js/firebase";
import { useQueryParams } from "hooks/useQueryParams";
import { sportsIconGreen } from "assets/js/sportsImages";
import { fetchPwpUrl } from "repositories/pwpUrlRepository";
import { fetchLeagueMaster } from "repositories/leagueMasterRepository";
import { fetchOrganization } from "repositories/organizationRepository";
import { Association } from "@spolive-team/model";

const viewportSize = 1200;

type Query = {
  pwpType: Association;
  pwpId: string;
};

export const AlbumSplash = (): JSX.Element => {
  const { pwpType, pwpId } = useQueryParams<Query>();

  const teamLogo = useAsyncMemo(async () => {
    const pwpUrl = await fetchPwpUrl({ pwpType, pwpId });
    if (!pwpUrl || "sportsId" in pwpUrl === false) {
      return undefined;
    }

    const sportsId = pwpUrl.sportsId;
    switch (pwpUrl.type) {
      case "team": {
        const teamMaster = await fetchTeamMaster(sportsId, pwpUrl.teamMasterId);
        return teamMaster?.logoUrl || sportsIconGreen[sportsId];
      }
      case "tour": {
        const leagueMaster = await fetchLeagueMaster(sportsId, pwpUrl.leagueMasterId);
        return leagueMaster?.logoUrl || sportsIconGreen[sportsId];
      }
      case "org":
        const organization = await fetchOrganization(sportsId, pwpUrl.organizationId);
        return organization?.logoUrl || sportsIconGreen[sportsId];
    }
  }, [pwpType, pwpId]);

  useEffect(() => {
    setHtmlMeta({ viewport: "width=" + viewportSize });
  }, []);

  if (!teamLogo) {
    return <></>;
  }

  return (
    <Wrapper>
      {/* dev.generateCard の有無に応じてOGPをスクリーンショットするか判定するためコンテンツの読み取りが終わるまではこのクラス名のdivを表示しない */}
      <div className="generatedCard">
        <img src={poweredByLogo} alt="Powered BY" className="poweredBy" />
        <img src={teamLogo} alt="teamLogo" className="teamLogo" />
        <img src={photoGallery} alt="GALLERY" className="gallery" />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .generatedCard {
    position: relative;
    width: ${viewportSize}px;
    height: ${viewportSize * 0.525}px;
    background: #fff;
    display: flex;
    align-items: center;
  }
  .generateCard {
    width: fit-content;
    height: fit-content;
  }
  .poweredBy {
    position: absolute;
    top: -2px;
    right: 0px;
    width: 348px;
  }
  .teamLogo {
    width: 400px;
    height: 400px;
    margin-left: 56px;
    margin-right: 28px;
    object-fit: contain;
  }
  .gallery {
    width: 630px;
    height: 324px;
  }
`;
