import firebase from "firebase/app";
import { createContext } from "react";
import { User } from "models/user";
import colorTheme from "theme";

type FirebaseContextValue = {
  auth: firebase.auth.Auth | null;
  firestore: firebase.firestore.Firestore | null;
  realtimeDb: firebase.database.Database | null;
};

export const FirebaseContext = createContext<FirebaseContextValue>({
  auth: null,
  firestore: null,
  realtimeDb: null
});

type UserContextValue = {
  user: User | null;
};

export const UserContext = createContext<UserContextValue>({
  user: null
});

export const ThemeContext = createContext(null as unknown as typeof colorTheme);
