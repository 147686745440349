import { SportsDic } from "models/sportsDic";

export const skijump: SportsDic = {
  playerHasUniformNumber: true,
  isFixedPlayerPositionNum: false,
  teamImgIsCircle: true,
  startingMemberNum: 50,
  reserveMemberNum: 0,
  statTypes: [
    {
      key: "jump",
      name: "ジャンプ",
      name_en: "Jump",
      point: 1,
      iconText: "J"
    }
  ],
  infringementNames: [],
  positionNames: [
    {
      num: 1,
      name: "滑走順"
    }
  ],
  defaultPosition: [],
  addCheerIcons: ["⛄"]
};
