import { SportsDic } from "models/sportsDic";

export const americanfootball: SportsDic = {
  playerHasUniformNumber: false,
  hasUniformNumber: true,
  hasPosition: true,
  isFixedPlayerPositionNum: false,
  teamImgIsCircle: false,
  startingMemberNum: 11,
  reserveMemberNum: 30,
  isCountDownTime: true,
  isPeriodFinish: true,
  statTypes: [
    {
      key: "Kickoff",
      name: "キックオフ",
      name_en: "Kickoff",
      name_short: "KO",
      name_short_en: "KO",
      color: "gray",
      iconText: "KO"
    },
    {
      key: "Kick",
      name: "キック",
      name_en: "Kick",
      name_short: "K",
      name_short_en: "K",
      iconText: "K",
      color: "gray"
    },
    {
      key: "KickoffReturn",
      name: "キックオフリターン",
      name_en: "Kickoff Return",
      name_short: "KR",
      name_short_en: "KR",
      iconText: "KR",
      color: "gray"
    },
    {
      key: "Pass",
      name: "パス",
      name_en: "Pass",
      name_short: "Pass",
      name_short_en: "Pass",
      iconText: "Pass",
      color: "gray"
    },
    {
      key: "PassIncomplete",
      name: "パス失敗",
      name_en: "Pass Incomplete",
      name_short: "PD",
      name_short_en: "PD",
      iconText: "PD",
      color: "gray"
    },
    {
      key: "Run",
      name: "ラン",
      name_en: "Run",
      name_short: "Run",
      name_short_en: "Run",
      iconText: "Run",
      color: "gray"
    },
    {
      key: "Punt",
      name: "パント",
      name_en: "Punt",
      name_short: "Punt",
      name_short_en: "Punt",
      iconText: "Punt",
      color: "gray"
    },
    {
      key: "PuntReturn",
      name: "パントリターン",
      name_en: "Punt Return",
      name_short: "RET",
      name_short_en: "RET",
      iconText: "RET",
      color: "gray"
    },
    {
      key: "BlockedPunt",
      name: "パントブロック",
      name_en: "Blocked Punt",
      name_short: "BP",
      name_short_en: "BP",
      iconText: "BP",
      color: "gray"
    },
    {
      key: "Fumble",
      name: "ファンブル",
      name_en: "Fumble",
      name_short: "Fum",
      name_short_en: "Fum",
      iconText: "Fum",
      color: "gray"
    },
    {
      key: "FumbleRecovered",
      name: "ファンブルリカバー",
      name_en: "Fumble Recovered",
      name_short: "FR",
      name_short_en: "FR",
      iconText: "FR",
      color: "gray"
    },
    {
      key: "TurnoverOnDowns",
      name: "ターンオーバー",
      name_en: "Turnover on Downs",
      name_short: "TO",
      name_short_en: "TO",
      iconText: "TO",
      color: "gray"
    },
    {
      key: "Interception",
      name: "インターセプト",
      name_en: "Interception",
      name_short: "INT",
      name_short_en: "INT",
      iconText: "INT",
      color: "gray"
    },
    {
      key: "Sack",
      name: "QBサック",
      name_en: "Sack",
      name_short: "Sk",
      name_short_en: "Sk",
      iconText: "Sk",
      color: "gray"
    },
    {
      key: "Tackle",
      name: "ロスタックル",
      name_en: "Tackle",
      name_short: "Tk",
      name_short_en: "Tk",
      iconText: "Tk",
      color: "gray"
    },
    {
      key: "Touchdown",
      name: "タッチダウン",
      name_en: "Touchdown",
      name_short: "TD",
      name_short_en: "TD",
      point: 6,
      gamePoint: 6,
      iconText: "TD",
      color: "gray"
    },
    {
      key: "ExtraPointGood",
      name: "PAT成功",
      name_en: "Extra Point Good",
      name_short: "PAT",
      name_short_en: "PAT",
      point: 1,
      gamePoint: 1,
      iconText: "PAT",
      color: "gray"
    },
    {
      key: "ExtraPointNoGood",
      name: "PAT失敗",
      name_en: "Extra Point No Good",
      name_short: "PAT✕",
      name_short_en: "PAT✕",
      iconText: "PAT✕",
      color: "gray"
    },
    {
      key: "2PTConversion",
      name: "2PT成功",
      name_en: "2PT Conversion",
      name_short: "2PT",
      name_short_en: "2PT",
      point: 2,
      gamePoint: 2,
      iconText: "2PT",
      color: "gray"
    },
    {
      key: "2PTConversionFails",
      name: "2PT失敗",
      name_en: "2PT Conversion Fails",
      name_short: "2PT✕",
      name_short_en: "2PT✕",
      iconText: "2PT✕",
      color: "gray"
    },
    {
      key: "FieldGoal",
      name: "フィールドゴール成功",
      name_en: "FieldGoal",
      name_short: "FG",
      name_short_en: "FG",
      point: 3,
      gamePoint: 3,
      iconText: "FG",
      color: "gray"
    },
    {
      key: "MissedFieldGoal",
      name: "フィールドゴール失敗",
      name_en: "Missed Field Goal",
      name_short: "FG✕",
      name_short_en: "FG✕",
      iconText: "FG✕",
      color: "gray"
    },
    {
      key: "BlockedFieldGoal",
      name: "フィールドゴールブロック",
      name_en: "Blocked Field Goal",
      name_short: "BFG",
      name_short_en: "BFG",
      iconText: "BFG",
      color: "gray"
    },
    {
      key: "Safety",
      name: "セーフティ",
      name_en: "Safety",
      name_short: "Sf",
      name_short_en: "Sf",
      point: 2,
      iconText: "Sf",
      color: "gray"
    },
    {
      key: "Penalty",
      name: "ペナルティ",
      name_en: "Penalty",
      name_short: "Penalty",
      name_short_en: "Penalty",
      iconText: ""
    },
    {
      key: "TimeOut",
      name: "タイムアウト",
      name_en: "TimeOut",
      name_short: "",
      name_short_en: "",
      iconText: "TO",
      isNoPlayer: true,
      color: "gray"
    },
    {
      key: "TeamChange",
      name: "攻守交代",
      name_en: "Team Change",
      name_short: "",
      name_short_en: "",
      iconText: "🔃",
      isNoPlayer: true,
      color: "gray"
    }
  ],
  initialPeriod: [
    {
      id: "firstQuarter",
      name: "1Q",
      name_en: "1Q",
      nameScore: "1Q",
      nameScore_en: "1Q",
      order: 1
    },
    {
      id: "secondQuarter",
      name: "2Q",
      name_en: "2Q",
      nameScore: "2Q",
      nameScore_en: "2Q",
      order: 2
    },
    {
      id: "halfTime",
      name: "HT",
      name_en: "half time",
      isBreak: true,
      order: 3
    },
    {
      id: "thirdQuarter",
      name: "3Q",
      name_en: "3Q",
      nameScore: "3Q",
      nameScore_en: "3Q",
      order: 4
    },
    {
      id: "fourthQuarter",
      name: "4Q",
      name_en: "4Q",
      nameScore: "4Q",
      nameScore_en: "4Q",
      order: 5
    }
  ],

  periodNames: {
    firstQuarter: {
      id: "firstQuarter",
      name: "1Q",
      name_en: "1Q",
      nameScore: "1Q",
      nameScore_en: "1Q",
      order: 1,
      displayMaxTimeMin: 12
    },
    secondQuarter: {
      id: "secondQuarter",
      name: "2Q",
      name_en: "2Q",
      nameScore: "2Q",
      nameScore_en: "2Q",
      order: 2,
      displayMaxTimeMin: 12
    },
    halfTime: {
      id: "halfTime",
      name: "HT",
      name_en: "half time",
      isBreak: true,
      order: 3
    },
    thirdQuarter: {
      id: "thirdQuarter",
      name: "3Q",
      name_en: "3Q",
      nameScore: "3Q",
      nameScore_en: "3Q",
      order: 4,
      displayMaxTimeMin: 12
    },
    fourthQuarter: {
      id: "fourthQuarter",
      name: "4Q",
      name_en: "4Q",
      nameScore: "4Q",
      nameScore_en: "4Q",
      order: 5,
      displayMaxTimeMin: 12
    },
    OT: {
      id: "OT",
      name: "OT",
      name_en: "OT",
      nameScore: "OT",
      nameScore_en: "OT",
      order: 6,
      isExtra: true,
      displayMaxTimeMin: 12
    }
  },
  infringementNames: [],
  defaultPosition: [],
  addCheerIcons: ["🏉"]
};
