import React, { FC, ReactElement } from "react";
import styled from "styled-components";
import defaultPlayerPhoto from "assets/images/score_card/UrayasuDRocks/default_player.png";

type Props = {
  playerNamePrimary: string;
  playerNameSecondary: string;
  position: string;
  playerPhotoUrl: string;
};

const PlayerDataLayer: FC<Props> = ({
  playerNamePrimary,
  playerNameSecondary,
  position,
  playerPhotoUrl
}): ReactElement => {
  return (
    <Wrapper>
      <div className="playerPhotoWrapper">
        <img
          src={playerPhotoUrl}
          alt="playerPhoto"
          onError={e => {
            e.currentTarget.src = defaultPlayerPhoto;
          }}
          className="playerPhoto"
        />
      </div>
      <div className="effect"></div>
      <div className="playerDataWrapper">
        <div className="position">{position}</div>
        <div className="playerNamePrimary">{playerNamePrimary}</div>
        <div className="playerNameSecondary">{playerNameSecondary}</div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  .effect {
    position: absolute;
    width: 771px;
    height: 356px;
    left: 0px;
    top: 500px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  }
  .playerDataWrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    box-sizing: border-box;
    position: absolute;
    width: 772px;
    overflow: hidden;
    word-break: break-all;
    left: 0px;
    bottom: 320px;
    padding: 0px 30px 0px 30px;
    box-sizing: border-box;
    .position {
      font-family: "Alverata";
      font-style: italic;
      font-weight: 700;
      font-size: 60px;
      line-height: 70px;
      width: 100%;

      background: linear-gradient(180deg, #f9d431 0%, #f39d3e 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
    .playerNamePrimary {
      font-family: "RobotoWebFont";
      font-style: italic;
      font-weight: 900;
      font-size: 50px;
      line-height: 59px;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: #ffffff;

      text-shadow: 0px 0px 25px rgba(0, 0, 0, 0.7);
    }
    .playerNameSecondary {
      font-family: "RobotoWebFont";
      font-style: normal;
      font-weight: 900;
      font-size: 26px;
      line-height: 30px;
      letter-spacing: 3px;

      color: #ffffff;

      text-shadow: 0px 0px 25px rgba(0, 0, 0, 0.7);
    }
  }
  .playerPhotoWrapper {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 772px;
    height: 862px;
    overflow: hidden;

    .playerPhoto {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 856px;
      height: 856px;
    }
  }
`;

export default PlayerDataLayer;
