import React, { FC, ReactElement } from "react";
import styled from "styled-components";
import bgImg from "assets/images/cheer_battle_result_card/bgImg.png";
import Rank from "./Rank";

type Props = {
  rank: string;
  teamColor: string;
};

/* Rank must set same hierarchy as Background Image,
   Because it can't apply mix-blend-mode: overlay to Background Image. */
const BgLayer: FC<Props> = ({ rank, teamColor }): ReactElement => {
  return (
    <Wrapper>
      <BgImg src={bgImg} />
      <div className="rankWrapper">
        <Rank rank={rank} teamColor={teamColor} />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  .confettiImg {
    width: 100%;
  }
  .rankWrapper {
    position: absolute;
    left: 50px;
    top: 165px;
  }
`;

const BgImg = styled.img`
  width: 100%;
  position: absolute;
  bottom: 0px;
`;

export default BgLayer;
