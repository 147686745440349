import React, { FC, ReactElement } from "react";
import styled from "styled-components";

type Props = {
  name: string;
  nameEn: string;
};

const Name: FC<Props> = ({ name, nameEn }): ReactElement => {
  return (
    <NameWrapper>
      <div className="name">{name}</div>
      <div className="nameEn">{nameEn}</div>
    </NameWrapper>
  );
};

const NameWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .name {
    width: 100%;
    font-family: "M + 1c";
    font-style: normal;
    font-weight: 900;
    font-size: 115px;
    line-height: 171px;
    text-align: center;
    letter-spacing: 5px;
    color: #ffffff;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
    word-wrap: break-word;
  }
  .nameEn {
    width: 100%;
    font-family: "Futura";
    font-style: italic;
    font-weight: bold;
    font-size: 42px;
    line-height: 56px;
    -webkit-text-stroke: 1.25px #ffffff;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
    word-wrap: break-word;
  }
`;

export default Name;
