import { last } from "lodash";
import { Game } from "models/game";
import { isShowGamePeriodAfterEndGame } from "assets/sportsData";
import { SportsEnum } from "models/sports";
import { getInning } from "assets/sportsData/baseball";
import { findPeriodNameCreator, findPeriodTimeCreator } from "libs/periods";

export const getGameTimeTextForPeriod = (
  gameData: Game | undefined,
  sportsId: string,
  isJa: boolean
): string | null => {
  if (!gameData) {
    return null;
  }
  if (sportsId === SportsEnum.baseball) {
    return getBaseballLabelText(gameData);
  }
  const currentPeriodId = gameData?.period_currentStatusId;
  const periodList = gameData?.periodList;
  const periodContract = gameData?.periods?.periods;
  const findPeriodName = findPeriodNameCreator(periodContract, sportsId);

  // 試合が終わっている場合かつ
  // isShowGamePeriodAfterEndGameがtrueの場合(テニスなど)
  // には最後のperiod名を表示
  if (gameData?.time_isGameEnd && isShowGamePeriodAfterEndGame(sportsId)) {
    const lastPeriod =
      periodContract && periodContract?.length > 1
        ? last(periodContract)
        : periodList && periodList?.length > 1
        ? last(periodList)
        : null;
    if (!lastPeriod?.id) {
      return null;
    }
    return findPeriodName(lastPeriod.id, isJa);
  }

  if (!currentPeriodId) {
    return null;
  }
  const startedAt = findPeriodTimeCreator(gameData.periodList, gameData?.periodTimeStamps)(
    "startedAt",
    currentPeriodId
  );
  if (!startedAt) {
    return null;
  }
  return findPeriodName(currentPeriodId, isJa);
};

export const getBaseballLabelText = (gameData: Game) => {
  const inning = getInning(gameData);
  if (!inning) return "";
  return inning.period?.name + (inning.isTop ? "表" : "裏");
};
