import { Association, PwpUrl } from "@spolive-team/model";
import firebase from "firebase/app";

export const fetchPwpUrl = async ({
  pwpType,
  pwpId
}: {
  pwpType: Association;
  pwpId: string;
}): Promise<PwpUrl | undefined> => {
  const doc = await firebase.firestore().doc(`urls/webLp/${pwpType}/${pwpId}`).get();
  if (!doc.exists) {
    return undefined;
  }
  return { ...doc.data(), id: doc.id } as PwpUrl;
};
