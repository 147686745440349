import React, { FC, ReactElement } from "react";
import styled from "styled-components";
import defaultTeamIcon from "assets/images/score_card/default_team.png";
import Game from "./Game";
import Name from "./Name";
import { TeamPosition } from "../types";

type Props = {
  playerName: string;
  playerNameEn: string;
  gameName: string;
  gameStatus: string;
  homeTeamIcon?: string | null;
  awayTeamIcon?: string | null;
  homeScore: string;
  awayScore: string;
  teamPosition: TeamPosition;
};

const TextLayer: FC<Props> = ({
  playerName,
  playerNameEn,
  gameName,
  gameStatus,
  homeScore,
  awayScore,
  homeTeamIcon,
  awayTeamIcon,
  teamPosition
}): ReactElement => {
  return (
    <Wrapper>
      <div className="container">
        <Name name={playerName} nameEn={playerNameEn} />
        <Game
          gameName={gameName}
          gameStatus={gameStatus}
          homeScore={homeScore}
          awayScore={awayScore}
          homeTeamIcon={homeTeamIcon ?? defaultTeamIcon}
          awayTeamIcon={awayTeamIcon ?? defaultTeamIcon}
          teamPosition={teamPosition}
        />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 0px;
    left: 0px;
    background: linear-gradient(0deg, #000000 30.72%, rgba(0, 0, 0, 0) 94.44%);
  }
`;

export default TextLayer;
