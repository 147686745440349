import { atomFamily, selectorFamily, useRecoilCallback, useRecoilValue } from "recoil";
import { Team } from "models/team";
import { useEffect } from "react";
import { fetchLeagueTeamPlayers } from "assets/js/firebase";
import { Player } from "@spolive-team/model";

const playersState = atomFamily<
  Player[],
  {
    sportsId: string;
    leagueId: string;
    teamId: string;
  }
>({
  key: "player",
  default: []
});

/**
 * 試合で表示する選手を設定するフック
 */
export const useSetPlayers = ({
  sportsId,
  leagueId,
  team
}: {
  sportsId: string;
  leagueId: string;
  team: Team | null | undefined;
}) => {
  const setPlayers = useRecoilCallback(
    ({ set }) =>
      async () => {
        if (!team?.id) {
          return;
        }

        const players = await fetchLeagueTeamPlayers(sportsId, leagueId, team.id);
        set(playersState({ sportsId, leagueId, teamId: team.id }), players);
      },
    [leagueId, sportsId, team]
  );
  useEffect(() => {
    setPlayers();
  }, [setPlayers]);
};

const playerSelector = selectorFamily<
  Player | undefined,
  { sportsId: string; leagueId: string; teamId: string; playerId: string }
>({
  key: "playerSelector",
  get: ({ sportsId, leagueId, teamId, playerId }) => {
    return ({ get }) => {
      const players = get(playersState({ sportsId, leagueId, teamId }));
      return players.find(player => player.id === playerId);
    };
  }
});

/**
 * 試合で表示する選手を取得するフック
 */
export const useGetPlayer = ({
  sportsId,
  leagueId,
  teamId,
  playerId
}: {
  sportsId: string;
  leagueId: string;
  teamId: string;
  playerId: string;
}) => {
  const player = useRecoilValue(
    playerSelector({
      sportsId,
      leagueId,
      teamId: teamId ?? "",
      playerId: playerId ?? ""
    })
  );

  return player;
};
