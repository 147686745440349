import React, { FC, ReactElement } from "react";
import styled from "styled-components";
import defaultTeamIcon from "assets/images/score_card/default_team.png";
import { TeamPosition } from "../types";

type Props = {
  homeTeamScore: string;
  awayTeamScore: string;
  homeTeamLogoUrl?: string | null;
  awayTeamLogoUrl?: string | null;
  gameName: string;
  gameStatus: string;
  teamPosition: TeamPosition;
};

const GameStatusLayer: FC<Props> = ({
  homeTeamScore,
  awayTeamScore,
  homeTeamLogoUrl,
  awayTeamLogoUrl,
  gameName,
  gameStatus,
  teamPosition
}): ReactElement => {
  return (
    <Wrapper teamPosition={teamPosition}>
      <div className="gameStatusBar" />
      <div className="gameStatusWrapper">
        <div className="gameStatus">{gameStatus}</div>
        <div className="gameName">{gameName}</div>
      </div>
      <div className="score">
        <img className="teamLogo" src={homeTeamLogoUrl ?? defaultTeamIcon} alt="homeTeamLogo" />
        <div className="homeTeamScore">{homeTeamScore}</div>
        <div className="scoreBorder" />
        <div className="awayTeamScore">{awayTeamScore}</div>
        <img className="teamLogo" src={awayTeamLogoUrl ?? defaultTeamIcon} alt="awayTeamLogo" />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ teamPosition: TeamPosition }>`
  position: relative;
  width: 100%;
  height: 100%;
  .gameStatusBar {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    clip-path: polygon(77.2% 0, 100% 0%, 100% 100%, 0% 100%, 0% 85.6%, 77.2% 85.6%);
    background: #d9d9d9;
    opacity: 0.1;
  }
  .score {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 228px;
    right: 0px;
    top: 300px;
    .teamLogo {
      width: 150px;
      height: 150px;
      padding: 15px;
      box-sizing: border-box;
    }
    .scoreBorder {
      width: 104px;
      border: 2px solid #ffffff;
      margin: 8px 0px 8px 0px;
      box-sizing: border-box;
    }
    .homeTeamScore {
      font-family: "OswaldWebFont";
      font-style: normal;
      font-weight: 700;
      font-size: 92px;
      line-height: 117px;
      text-transform: uppercase;
      color: ${props => (props.teamPosition === "home" ? "#f5ab3b" : "#ffffff")};
      text-shadow: 0px 0px 25px rgba(0, 0, 0, 0.7);
    }
    .awayTeamScore {
      font-family: "OswaldWebFont";
      font-style: normal;
      font-weight: 700;
      font-size: 92px;
      line-height: 117px;
      text-transform: uppercase;
      color: ${props => (props.teamPosition === "away" ? "#f5ab3b" : "#ffffff")};
      text-shadow: 0px 0px 25px rgba(0, 0, 0, 0.7);
    }
  }

  .gameStatusWrapper {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 144px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .gameStatus {
      font-family: "RobotoWebFont";
      font-style: italic;
      font-weight: 700;
      font-size: 40px;
      line-height: 47px;
      color: white;
    }
    .gameName {
      font-family: "RobotoWebFont";
      font-style: italic;
      font-weight: 500;
      font-size: 27px;
      line-height: 32px;
      color: white;
    }
  }
`;

export default GameStatusLayer;
