import { rugby } from "./rugby";
import { soccer } from "./soccer";
import { skijump } from "./skijump";
import { darts } from "./darts";
import { tennis } from "./tennis";
import { basketball3x3 } from "./basketball3x3";
import { volleyball } from "./volleyball";
import { flyingdisc } from "./flyingdisc";
import { baseball } from "./baseball";
import { americanfootball } from "./americanfootball";
import { hockey } from "./hockey";
import { handball } from "./handball";
import { SportsDic, PeriodDic, StatsDic, Position, PositionLang } from "models/sportsDic";
import { basketball } from "./basketball";
import { badminton } from "./badminton";
import { group } from "./group";
import { PlayerPosition } from "@spolive-team/model";
import { DefaultLang, Lang, isValidLang } from "models/locale";

const defaultSportsId = "rugby";
const sportsList: { [sportsId: string]: SportsDic } = {
  rugby,
  soccer,
  skijump,
  tennis,
  darts,
  basketball3x3,
  volleyball,
  flyingdisc,
  baseball,
  americanfootball,
  hockey,
  basketball,
  badminton,
  group,
  handball
};

const getSportsData = (sportsId = defaultSportsId): SportsDic => {
  return sportsList[sportsId] ? sportsList[sportsId] : sportsList[defaultSportsId];
};

export const statTypes = (sportsId = defaultSportsId) => sportsList[sportsId].statTypes;
export const infringementNames = (sportsId = defaultSportsId) =>
  sportsList[sportsId].infringementNames;
export const positionNames = (sportsId = defaultSportsId) => sportsList[sportsId].positionNames;
export const periodNames = (sportsId = defaultSportsId): { [id: string]: PeriodDic } => {
  return sportsList[sportsId].periodNames || {};
};

export const getFirstPeriodId = (sportsId: string): null | string => {
  if (!Object.keys(periodNames)?.length) {
    return null;
  }
  return Object.keys(periodNames).sort(
    (a, b) => getPeriodIndex(sportsId, a) - getPeriodIndex(sportsId, b)
  )[0];
};
export const getPeriodIndex = (sportsId: string, id: string) => {
  const periods = periodNames(sportsId);
  if (!periods || !Object.keys(periods)?.length) {
    return 0;
  }
  return periods[id].order;
};

export const playerHasUniformNumber = (sportsId = defaultSportsId) =>
  getSportsData(sportsId).playerHasUniformNumber;
export const isFixedPlayerPositionNum = (sportsId = defaultSportsId) =>
  getSportsData(sportsId).isFixedPlayerPositionNum;
export const teamImgIsCircle = (sportsId = defaultSportsId) =>
  getSportsData(sportsId).teamImgIsCircle;
export const addCheerIcons = (sportsId = defaultSportsId) => getSportsData(sportsId).addCheerIcons;
export const isShowGamePeriodAfterEndGame = (sportsId = defaultSportsId) =>
  getSportsData(sportsId).isShowGamePeriodAfterEndGame;
export const isShowLastPeriodGamePoint = (sportsId = defaultSportsId) =>
  getSportsData(sportsId).isShowLastPeriodGamePoint;

export const getStatData = (type: string, sportsId: string): StatsDic | null => {
  const stats = statTypes(sportsId).filter(stat => stat.key === type);
  return stats[0] ? stats[0] : null;
};

export const getIsCountDownTime = (sportsId: string) => sportsList[sportsId].isCountDownTime;

export const getPeriodNames = (sportsId: string) => sportsList[sportsId].periodNames;

export const toLang = (lang: string): Lang => {
  if (isValidLang(lang)) {
    return lang as Lang;
  } else if (isValidLang(lang.split("_")[0].toLowerCase())) {
    return lang.split("_")[0].toLowerCase() as Lang;
  }
  return DefaultLang;
};

// ポジション情報のマスタを得る
export const getMasterPositionList = (
  sportsId: string,
  localeDetectedWords: string
): Position[] => {
  // localeチェック
  const lang = toLang(localeDetectedWords);
  // ポジションマスタ
  const positions = getSportsData(sportsId).positionList ?? ({} as PositionLang);
  const positionsLocal: Position[] = positions[lang] ?? [];
  if (positionsLocal.length <= 0) {
    return [];
  }
  return positionsLocal;
};

// プレイヤーに設定されたposition idからポジションマスタの情報を引く
export const getPlayerPositionDetails = (
  sportsId: string,
  localeDetectedWords: string,
  playerPositionList?: PlayerPosition[]
): Position[] => {
  // プレイヤーポジションからマスタデータを引く
  if (playerPositionList == null || playerPositionList.length <= 0) {
    return [];
  }
  const positionsLocal = getMasterPositionList(sportsId, localeDetectedWords);
  return playerPositionList.reduce((curry, pp) => {
    const found = positionsLocal.find(f => f.id === pp.id);
    if (found != null) {
      curry.push(found);
    }
    return curry;
  }, [] as Position[]);
};
