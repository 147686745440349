import dayjs from "dayjs";
import { Game, getPeriodById, Period } from "models/game";
import { periodNames, getPeriodNames } from "assets/sportsData";
import { SportsEnum } from "../models/sports";
import { Timestamp } from "@firebase/firestore-types";
import { findPeriodScoreNameCreator } from "./periods";

export type GameStatus = "hidden" | "finished" | "live" | "active";
export type PointStatus = "versus" | "point";
export type GameLabel =
  | "Others.cancelledGame"
  | "Others.postponedGame"
  | "Others.finishedGame"
  | "Others.todayGame"
  | "Others.duringGame"
  | "Game.breakingNewsOnly"
  | "Others.liveGame"
  | "Others.liveStreaming"
  | "Others.beforeGame"
  | "Game.hasMovie"
  | "Others.playingVideo";

const LIMIT_OF_START_GAME = -7;

const getGameBaseTime = (stopGameTimestamp?: Timestamp) => {
  return stopGameTimestamp ? dayjs(stopGameTimestamp.toDate()) : dayjs();
};
const getGameTimeMin = ({
  diffTimeSec,
  baseTime,
  maxTimeMin,
  stopGameTimestamp
}: {
  maxTimeMin: number | null;
  diffTimeSec: number;
  baseTime: dayjs.Dayjs;
  stopGameTimestamp?: Timestamp;
}): number => {
  const nowTime = getGameBaseTime(stopGameTimestamp);
  const gameMin = nowTime.add(-1 * diffTimeSec, "second").diff(baseTime, "minute");
  if (maxTimeMin && gameMin > maxTimeMin) {
    return maxTimeMin;
  }
  if (gameMin < 0) {
    return 0;
  }
  return gameMin;
};

const getGameTimeSec = ({
  diffTimeSec,
  baseTime,
  stopGameTimestamp
}: {
  diffTimeSec: number;
  baseTime: dayjs.Dayjs;
  stopGameTimestamp?: Timestamp;
}) => {
  const nowTime = getGameBaseTime(stopGameTimestamp);
  const gameSec = nowTime.add(-1 * diffTimeSec, "second").diff(baseTime, "second");
  return gameSec % 60;
};

export const getPeriodTime = (
  sportsId: string,
  gameData: Game,
  isCountDown: boolean = false
): { sec: number; min: number; period: Period } | undefined => {
  if (!gameData.periodList || !gameData?.period_currentStatusId) return undefined;
  const currentPeriod = getPeriodById(gameData.periodList, gameData?.period_currentStatusId);
  if (!currentPeriod?.startedAt) return undefined;
  // ピリオドが終了したタイミングで時刻を空にしておく（次のピリオドが始まったときに画面に一瞬前のピリオドの時刻が表示されるため）
  if (currentPeriod && currentPeriod.finishedAt) {
    return {
      sec: 0,
      min: 0,
      period: currentPeriod
    };
  }
  const baseTime = dayjs.unix(currentPeriod.startedAt.seconds);
  const diffTimeSec = currentPeriod.diffTimeSec || 0;

  const periodDic = periodNames(sportsId)[gameData?.period_currentStatusId];
  let maxTimeMin: undefined | number;
  if (periodDic?.isExtra) {
    maxTimeMin = gameData?.periodOT ?? periodDic?.displayMaxTimeMin;
  } else {
    maxTimeMin = gameData?.periodTime ?? periodDic?.displayMaxTimeMin;
  }
  // 0は許容する
  if (maxTimeMin === undefined) return undefined;

  const gameTimeMin = getGameTimeMin({ baseTime, diffTimeSec, maxTimeMin });
  let gameTimeSec = getGameTimeSec({ baseTime, diffTimeSec });
  if (gameTimeMin === maxTimeMin) {
    gameTimeSec = 0;
  }
  if (isCountDown) {
    return {
      sec: (60 - gameTimeSec) % 60,
      min: maxTimeMin - gameTimeMin - (gameTimeSec !== 0 ? 1 : 0),
      period: currentPeriod
    };
  } else {
    return {
      sec: gameTimeSec,
      min: gameTimeMin,
      period: currentPeriod
    };
  }
};

export const isStartFirstHalfSecondHalfGame = (gameData: Game) => {
  return !!gameData?.time_firstHalfStartTime;
};

export const isNotGameStartDate = (gameData: Game) => {
  const {
    time_firstHalfStartTime,
    time_isGameEnd,
    time_isGameCancelled,
    time_isGamePostponed,
    info_datetime
  } = gameData;
  if (!info_datetime) return false;
  const gameDate = dayjs.unix(info_datetime.seconds).format("YYYY:MM:DD");

  const isGameStartDate =
    dayjs().add(LIMIT_OF_START_GAME, "day").format("YYYY:MM:DD") <= gameDate &&
    gameDate <= dayjs().format("YYYY:MM:DD");

  return (
    time_isGameCancelled ||
    time_isGamePostponed ||
    time_isGameEnd ||
    !isGameStartDate ||
    !time_firstHalfStartTime
  );
};

export const zeroPadding = (num: number, length: number) => {
  return ("0000000000" + num).slice(-length);
};

export const getCurrentPeriodId = (sportsId: string, gameData: Game) => {
  // ピリオド共通化
  if (
    gameData?.periods &&
    gameData.periods.periods.length > 0 &&
    gameData?.period_currentStatusId
  ) {
    const period = gameData.periods.periods.find(p => p.id === gameData.period_currentStatusId);
    if (!period) return null;
    return gameData.period_currentStatusId;
  }
  if (gameData?.periodList && gameData.periodList.length > 0 && gameData?.period_currentStatusId) {
    const periodNames = getPeriodNames(sportsId);
    const period = getPeriodById(gameData.periodList, gameData.period_currentStatusId);
    if (!period || !periodNames) return null;
    return gameData.period_currentStatusId;
  }
  //試合区切りの入っていない前半後半形式の試合用。ラグビー、サッカー対応。フライングディスク等の前半後半形式は途対応が必要。
  switch (sportsId) {
    case SportsEnum.soccer:
    case SportsEnum.rugby:
      if (gameData?.time_firstHalfStartTime && !gameData?.time_isFirstHalfEnd) {
        return "firstHalf";
      }
      if (
        gameData?.time_firstHalfStartTime &&
        gameData?.time_isFirstHalfEnd &&
        !gameData?.time_secondHalfStartTime
      ) {
        return "halfTime";
      }
      if (
        gameData?.time_firstHalfStartTime &&
        gameData?.time_isFirstHalfEnd &&
        gameData?.time_secondHalfStartTime &&
        !gameData?.time_isGameEnd
      ) {
        return "secondHalf";
      }
      return null;
    default:
      return null;
  }
};

export const getCurrentScoreName = (
  sportsId: string,
  gameData: Game,
  isJa: boolean
): string | null => {
  const hasPeriodModel = gameData?.periods?.periods && gameData.periods.periods.length > 0;
  const hasPeriodList = gameData?.periodList && gameData.periodList.length > 0;
  if (gameData?.period_currentStatusId && (hasPeriodModel || hasPeriodList)) {
    const findPeriodScore = findPeriodScoreNameCreator(gameData?.periods?.periods, sportsId);
    const periodName = findPeriodScore(gameData?.period_currentStatusId, isJa);
    if (periodName) return periodName;
  }

  //試合区切りの入っていない前半後半形式の試合用。ラグビー、サッカー対応。フライングディスク等の前半後半形式は途対応が必要。
  const periodNames = getPeriodNames(sportsId);
  switch (sportsId) {
    case SportsEnum.soccer:
    case SportsEnum.rugby:
      if (gameData?.time_firstHalfStartTime && !gameData?.time_isFirstHalfEnd) {
        return isJa
          ? periodNames?.["firstHalf"].nameScore ?? null
          : periodNames?.["firstHalf"].nameScore_en ?? null;
      }
      if (
        gameData?.time_firstHalfStartTime &&
        gameData?.time_isFirstHalfEnd &&
        !gameData?.time_secondHalfStartTime
      ) {
        return isJa
          ? periodNames?.["halfTime"].nameScore ?? null
          : periodNames?.["halfTime"].nameScore_en ?? null;
      }
      if (
        gameData?.time_firstHalfStartTime &&
        gameData?.time_isFirstHalfEnd &&
        gameData?.time_secondHalfStartTime &&
        !gameData?.time_isGameEnd
      ) {
        return isJa
          ? periodNames?.["secondHalf"].nameScore ?? null
          : periodNames?.["secondHalf"].nameScore_en ?? null;
      }
      return null;
    default:
      return null;
  }
};
