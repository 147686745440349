export const primaryColor = "#3BC3A3";

export const textColor = "#F1F1F1";
export const grayTextColor = "#979797";
export const darkTextColor = "rgba(0,0,0,0.87)";
export const btnTextColor = "#6F6F6F";
export const placeholderTextColor = "#626262";
export const oddColor = "#333333";
export const tournamentBorderColor = "#999999";
export const evenColor = "rgba(59,195,163,0.3)";
export const headerColor = "#161616";
export const homeHeaderColor = "#0F0F0F";
export const homeHeaderIconColor = "#F1F1F1";
export const homeFooterColor = "#0F0F0F";
export const homeFooterTextColor = "#F1F1F1";
export const homeFooterIconColor = "#F1F1F1";
export const bgColor = "#0F0F0F";
export const darkBgColor = "#2e2e2e";
export const blankTeamColor = "#222222";
export const dangerColor = "#F8501C";
export const contentColor = "#333333";
export const darkContentColor = "rgba(0, 0, 0, 0.54)";
export const blackContentColor = "#222222";
export const blackContentColorTranslucent = "rgba(34, 34, 34, 0.88)";
export const grayContentColor = "#4e4e4e";
export const lightTextColor = "rgba(255,255,255,0.54)";
export const inputColor = "#222222";
export const inputColorTranslucent = "rgba(255,255,255,0.15)";
export const contentBorderColor = "rgba(255,255,255,0.12)";
export const contentLightBorderColor = "rgba(255,255,255,0.06)";
export const whiteColor = "#ffffff";

export const defaultHomeColor = "#013F98";
export const defaultAwayColor = "#E90029";

// 新規のカラーコード
export const zabuton = "rgba(0,0,0,0.5)";
export const backgroundQuaternary = "#707070";
export const textDescription = "#AAAAAA";
export const textPrimary = "#F1F1F1";
export const textSecondary = "#333333";
export const textComplementary = "#333";
export const buttonSecondary = "#F1F1F1";
export const buttonTertiary = "#707070";
export const buttonQuaternary = "#333";
export const backgroundPrimary = "#0F0F0F";
export const backgroundSecondary = "#2A2A2A";
export const backgroundTertiary = "#414141";
