import React, { createContext, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Game } from "models/game";
import { Score } from "./Score";
import { Basic } from "./Basic";
import { CheerComments } from "./CheerComments";
import { CheerComment } from "models/cheerComment";
import { GiftComment } from "models/giftComment";
import { ProductMap } from "models/billing";
import { useInterval } from "use-interval";
import { AnimatedSuperCheer } from "./AnimatedSuperCheer";
import { useScale } from "hooks/useScale";
import { RotationSuperCheer } from "./RotationSuperCheer";
import { useSubscribeUsers } from "hooks/useSubscribeUser";
import { SubscribeUser } from "@spolive-team/model";
import { Timeline } from "models/timeline";

const TIME_TO_DISPLAY_BASIC_AND_CHEERCOMMENTS = 6000;

type STATUS = "basic" | "super" | "comment" | "cheerGiftComment";

const FOUL_TYPES = {
  FOUL: "Foul",
  U_FOUL: "UFoul",
  D_FOUL: "DISQUALIFYING"
};

type Props = {
  sportsId: string;
  leagueId: string;
  gameId: string;
  locale: "ja" | "en";
  gameData: Game | undefined;
  latestPoints: {
    away: number;
    home: number;
  };
  totalHomeCheerIcon: number;
  totalAwayCheerIcon: number;
  cheerComments: CheerComment[];
  cheerGiftComments: GiftComment[];
  productList: ProductMap;
  isShownFooter: boolean;
  isShownTime: boolean;
  timelineData: Timeline[] | undefined;
};

export const LiveGame: React.FC<Props> = ({
  sportsId,
  leagueId,
  gameId,
  gameData,
  latestPoints,
  totalHomeCheerIcon,
  totalAwayCheerIcon,
  cheerComments,
  cheerGiftComments,
  productList,
  isShownFooter,
  isShownTime,
  locale,
  timelineData
}) => {
  const reverseCheerGiftComments = useMemo(() => cheerGiftComments.reverse(), [cheerGiftComments]);
  const [cheerGiftCommentIndex, setCheerGiftCommentIndex] = useState(
    reverseCheerGiftComments.length > 0 ? reverseCheerGiftComments.length - 1 : -1
  );

  const rotationCheerGiftComment = useMemo(() => {
    return [...cheerGiftComments].sort((a, b) => {
      const aProductId = a.productId ?? "";
      const bProductId = b.productId ?? "";
      const priceA = productList[aProductId]?.price ?? 0;
      const priceB = productList[bProductId]?.price ?? 0;
      return priceA < priceB ? 1 : -1;
    });
  }, [cheerGiftComments, productList]);
  const [rotationCheerGiftCommentIndex, setRotationCheerGiftCommentIndex] = useState(
    rotationCheerGiftComment.length - 1
  );

  const [prevStatus, setPrevStatus] = useState<STATUS>("basic");
  const isJa = useMemo(() => locale === "ja", [locale]);

  // 3x3の特殊仕様対応。ファウル数を表示する
  const teamFoul:
    | {
        away: number;
        home: number;
      }
    | undefined = useMemo(() => {
    if (!timelineData || timelineData.length === 0 || sportsId !== "basketball3x3") {
      return;
    }
    const data = {
      away: 0,
      home: 0
    };
    timelineData.forEach(timeline => {
      if (
        timeline.type === FOUL_TYPES.FOUL ||
        timeline.type === FOUL_TYPES.U_FOUL ||
        timeline.type === FOUL_TYPES.D_FOUL
      ) {
        if (timeline.type === "Foul") {
          return data[timeline.player.home ? "home" : "away"]++;
        }
        return (data[timeline.player.home ? "home" : "away"] += 2);
      }
    });
    return data;
  }, [sportsId, timelineData]);

  const [status, setStatus] = useState<STATUS>("basic");
  const [intervalTime, setIntervalTime] = useState<number | null>(
    TIME_TO_DISPLAY_BASIC_AND_CHEERCOMMENTS
  );
  const getNextRotationCheerGiftCommentIndex = useCallback(() => {
    if (rotationCheerGiftCommentIndex >= rotationCheerGiftComment.length - 1) {
      return 0;
    } else {
      return rotationCheerGiftCommentIndex + 1;
    }
  }, [rotationCheerGiftComment.length, rotationCheerGiftCommentIndex]);

  useInterval(() => {
    let nextStatus: STATUS = "basic";
    switch (prevStatus) {
      case "basic":
        if (cheerComments.length) {
          nextStatus = "comment";
        } else if (rotationCheerGiftComment.length) {
          nextStatus = "cheerGiftComment";
          setRotationCheerGiftCommentIndex(getNextRotationCheerGiftCommentIndex());
        }
        break;
      case "comment":
        if (rotationCheerGiftComment.length) {
          nextStatus = "cheerGiftComment";
          setRotationCheerGiftCommentIndex(getNextRotationCheerGiftCommentIndex());
        } else {
          nextStatus = "basic";
        }
        break;
      case "cheerGiftComment":
        nextStatus = "basic";
        break;
      default:
        nextStatus = "basic";
    }
    setStatus(nextStatus);
    setIntervalTime(TIME_TO_DISPLAY_BASIC_AND_CHEERCOMMENTS);
    setPrevStatus(nextStatus);
  }, intervalTime);

  const scoreScale = useMemo(() => {
    return status === "basic" ? 1 : 0.7;
  }, [status]);

  const getIconUrl = useCallback(
    (productId: string) => {
      if (!productId) {
        return "";
      }
      const messageImageUrl = productList?.[productId]?.messageImageUrl;
      // 2022/12/3のイベントで一部の商品のみスコアボード用の画像を表示する必要があったので追加
      // scoreboardImageUrlにはスコアボード用に画像のサイズを変更した画像のURLが入ってくる
      const scoreboardImageUrl = productList?.[productId]?.scoreboardImageUrl;
      return (scoreboardImageUrl ? scoreboardImageUrl : messageImageUrl) ?? "";
    },
    [productList]
  );

  const iconUrl = useMemo(() => {
    return getIconUrl(reverseCheerGiftComments?.[cheerGiftCommentIndex]?.productId);
  }, [getIconUrl, cheerGiftCommentIndex, reverseCheerGiftComments]);

  const showNewGift = useCallback(() => {
    setCheerGiftCommentIndex(state => {
      if (state < reverseCheerGiftComments.length - 1) {
        setIntervalTime(null);
        setStatus("super");
        return state + 1;
      }
      setIntervalTime(TIME_TO_DISPLAY_BASIC_AND_CHEERCOMMENTS);
      return state;
    });
  }, [reverseCheerGiftComments.length]);

  useEffect(() => {
    if (status !== "super") {
      showNewGift();
    }
  }, [showNewGift, status]);

  useEffect(() => {
    setRotationCheerGiftCommentIndex(rotationCheerGiftComment.length - 1);
  }, [rotationCheerGiftComment.length]);

  const [isVisibleSuperCheer, setIsVisibleSuperCheer] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (status === "super") {
      setIsVisibleSuperCheer(true);
    } else {
      timeout = setTimeout(() => setIsVisibleSuperCheer(false), 100);
    }
    return () => clearTimeout(timeout);
  }, [status]);

  const homeSubscribeUsers = useSubscribeUsers(
    sportsId,
    gameData?.homeTeam?.masterTeamId || undefined
  );
  const awaySubscribeUsers = useSubscribeUsers(
    sportsId,
    gameData?.awayTeam?.masterTeamId || undefined
  );

  const { horizontalMargin, widthRatio } = useScale();

  const contextValue = useMemo<LiveGameContextType>(
    () => ({
      homeSubscribeUsers,
      awaySubscribeUsers
    }),
    [homeSubscribeUsers, awaySubscribeUsers]
  );

  return (
    <LiveGameContext.Provider value={contextValue}>
      <Wrapper className="p-gameSignage">
        <Score
          gameData={gameData}
          latestPoints={latestPoints}
          sportsId={sportsId}
          scale={scoreScale}
          isShownTime={isShownTime}
          teamFoul={teamFoul}
        />
        <div
          className="p-gameSignage__contents"
          style={{
            marginLeft: -horizontalMargin,
            width: widthRatio * 100 + "%"
          }}
        >
          <Basic
            sportsId={sportsId}
            leagueId={leagueId}
            gameId={gameId}
            gameData={gameData}
            totalHomeCheerIcon={totalHomeCheerIcon}
            totalAwayCheerIcon={totalAwayCheerIcon}
            homeTeamColor={gameData?.homeTeam?.masterTeamData?.color}
            awayTeamColor={gameData?.awayTeam?.masterTeamData?.color}
            isShow={status === "basic"}
          />
          <CheerComments
            isJa={isJa}
            comments={cheerComments}
            isShow={status === "comment"}
            homeTeamColor={gameData?.homeTeam?.masterTeamData?.color}
            awayTeamColor={gameData?.awayTeam?.masterTeamData?.color}
            isShownFooter={isShownFooter}
          />
          {isVisibleSuperCheer && cheerGiftCommentIndex >= 0 && (
            <AnimatedSuperCheer
              homeLogo={gameData?.homeTeam?.logoUrl!}
              awayLogo={gameData?.awayTeam?.logoUrl!}
              cheerGiftComment={reverseCheerGiftComments[cheerGiftCommentIndex]}
              iconUrl={iconUrl}
              isShow={status === "super"}
              homeTeamColor={gameData?.homeTeam?.masterTeamData?.color}
              awayTeamColor={gameData?.awayTeam?.masterTeamData?.color}
              animateEnd={showNewGift}
            />
          )}
          {status === "cheerGiftComment" &&
            rotationCheerGiftComment[rotationCheerGiftCommentIndex] && (
              <RotationSuperCheer
                homeLogo={gameData?.homeTeam?.logoUrl!}
                awayLogo={gameData?.awayTeam?.logoUrl!}
                cheerGiftComment={rotationCheerGiftComment[rotationCheerGiftCommentIndex]}
                iconUrl={getIconUrl(
                  rotationCheerGiftComment[rotationCheerGiftCommentIndex]?.productId
                )}
                isShow={status === "cheerGiftComment"}
                homeTeamColor={gameData?.homeTeam?.masterTeamData?.color}
                awayTeamColor={gameData?.awayTeam?.masterTeamData?.color}
              />
            )}
        </div>
      </Wrapper>
    </LiveGameContext.Provider>
  );
};

const Wrapper = styled.div`
  position: relative;
  height: 1080px;
  width: 1920px;
  box-sizing: border-box;
  font-family: Oswald;
  display: flex;
  flex-direction: column;
  .p-gameSignage {
    position: relative;
    line-height: 100px;
    &__contents {
      position: absolute;
      display: flex;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
`;

type LiveGameContextType = {
  homeSubscribeUsers?: SubscribeUser[];
  awaySubscribeUsers?: SubscribeUser[];
};

export const LiveGameContext = createContext<LiveGameContextType>({
  homeSubscribeUsers: undefined,
  awaySubscribeUsers: undefined
});
