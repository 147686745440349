import React, { useState, useEffect, useRef, useCallback, useContext, useMemo } from "react";
import { Game } from "models/game";
import styled from "styled-components";
import { defaultHomeTeamColor, defaultAwayTeamColor } from "assets/config";
import anime from "animejs";

import { FirebaseContext } from "contexts";
import { Cheer } from "models/cheer";
import { CheerIcon } from "../../atoms/CheerIcon";
import neonBefore from "assets/images/neon_before.png";
import neon from "assets/images/neon.png";
import neonEnd from "assets/images/neon_end.png";
import { GameDataContext } from "./hooks/gameData";
import dayjs from "dayjs";
import spolive from "assets/images/spolive_main_logo_icon_web.png";

type Props = {
  sportsId: string;
  leagueId: string;
  gameId: string;
  gameData: Game | undefined;
  totalHomeCheerIcon: number;
  totalAwayCheerIcon: number;
  homeTeamColor: string | undefined;
  awayTeamColor: string | undefined;
  isShow: boolean;
};

export const Basic: React.FC<Props> = ({
  sportsId,
  leagueId,
  gameId,
  totalHomeCheerIcon,
  totalAwayCheerIcon,
  homeTeamColor,
  awayTeamColor,
  isShow
}) => {
  // DBから1度に取得する最大数
  const ONCE_FETCH_MAX_NUM = 30;
  // 画面上に表示するアイコン最大数
  const ONCE_DISPLAY_MAX_NUM = 5;

  const firebaseRef = useRef(useContext(FirebaseContext));

  const { gameData, isJa, isShownFooter } = useContext(GameDataContext);

  const fetchCheerIcons = useCallback(async () => {
    const { firestore } = firebaseRef.current;
    const snapshot = await firestore
      ?.collection(`sportsData/${sportsId}/league/${leagueId}/games/${gameId}/cheerIcon`)
      .orderBy("createdAt", "desc")
      .limit(ONCE_FETCH_MAX_NUM)
      .get();

    const cheers = snapshot?.docs.map(
      doc =>
        ({
          ...doc.data(),
          id: doc.id
        } as Cheer)
    );
    return cheers;
  }, [gameId, leagueId, sportsId]);

  const getRatio = useCallback(() => {
    if (totalHomeCheerIcon === 0 && totalAwayCheerIcon === 0) return 50;
    return (totalHomeCheerIcon / (totalAwayCheerIcon + totalHomeCheerIcon)) * 100;
  }, [totalAwayCheerIcon, totalHomeCheerIcon]);

  const totalHomeCheerIconEl = useRef<HTMLSpanElement>(null);
  const totalAwayCheerIconEl = useRef<HTMLSpanElement>(null);

  // 更新される前の応援数
  const [prevHomeIcon] = useState(totalHomeCheerIcon);
  const [prevAwayIcon] = useState(totalAwayCheerIcon);

  const animateGage = useCallback((cheerIconEl, totalCheerIcon) => {
    anime({
      targets: cheerIconEl.current,
      textContent: totalCheerIcon,
      round: 1,
      duration: 500,
      easing: "easeInOutExpo"
      // begin: function (items) {
      //   console.log(items);
      //   // const value = (items.animations[0] as any).currentValue;
      //   // // console.log(items.animations[0]);
      //   // console.log((items.animations[0] as any).tweens[0].value);
      //   // cheerIconEl.current.innerHTML = 31000;
      //   cheerIconEl.current.value = 31000;
      // },
      // update: function (items) {
      //   const value = (items.animations[0] as any).currentValue;
      //   cheerIconEl.current.innerHTML = numberToComma(value);
      // },
    });
  }, []);

  const [ratio, setRatio] = useState<number>(0);
  const [awayCheerIcon, setAwayCheerIcon] = useState<Cheer[]>([]);
  const [homeCheerIcon, setHomeCheerIcon] = useState<Cheer[]>([]);

  useEffect(() => {
    (async () => {
      animateGage(totalAwayCheerIconEl, totalAwayCheerIcon);
      animateGage(totalHomeCheerIconEl, totalHomeCheerIcon);
      const cheers = await fetchCheerIcons();
      if (cheers) {
        const _awayCheerIcons: Cheer[] = [];
        const _homeCheerIcons: Cheer[] = [];
        await Promise.all(
          cheers.map(async cheer => {
            if (cheer.isHome) {
              _homeCheerIcons.push(cheer);
            } else {
              _awayCheerIcons.push(cheer);
            }
          })
        );
        setAwayCheerIcon(_awayCheerIcons.splice(0, ONCE_DISPLAY_MAX_NUM));
        setHomeCheerIcon(_homeCheerIcons.splice(0, ONCE_DISPLAY_MAX_NUM));
      }
      setRatio(getRatio());
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animateGage, totalHomeCheerIcon, totalAwayCheerIcon]);

  const isCheerBattleStart = useMemo(() => {
    if (!gameData?.info_datetime) return false;
    const gameStartTime = dayjs(gameData?.info_datetime?.toDate());
    return gameStartTime.isBefore(dayjs().add(7, "d"));
  }, [gameData?.info_datetime]);

  const isCheerBattleEnd = useMemo(() => {
    return gameData?.isCheerBattleEnd;
  }, [gameData?.isCheerBattleEnd]);

  const cheerBattleStatusLabel = useMemo(() => {
    if (isJa) {
      return isCheerBattleStart
        ? isCheerBattleEnd
          ? "応援バトル終了"
          : "応援バトル中"
        : "応援バトル開始前";
    } else {
      return isCheerBattleStart
        ? isCheerBattleEnd
          ? "Cheering Battle Ends"
          : "Cheering Battle"
        : "Before Cheering Start";
    }
  }, [isCheerBattleEnd, isCheerBattleStart, isJa]);

  return (
    <Wrapper isShow={isShow}>
      <div className="previewBasic-outer">
        <div className="previewBasic-status-wrapper">
          <GameStartStatus className="previewBasic-game-start-status">
            <img
              src={isCheerBattleStart ? (gameData?.isCheerBattleEnd ? neonEnd : neon) : neonBefore}
              alt=""
              className="previewBasic-game-start-status_img"
            />
            <div
              className={
                "previewBasic-game-start-status_text " +
                (isCheerBattleStart && !isCheerBattleEnd
                  ? "previewBasic-game-start-status_text--live "
                  : "") +
                (!isJa && !(isCheerBattleStart && !isCheerBattleEnd)
                  ? "previewBasic-game-start-status_text--en"
                  : "")
              }
            >
              {cheerBattleStatusLabel}
            </div>
          </GameStartStatus>
        </div>
        <Point className="previewBasic-point">
          <span ref={totalHomeCheerIconEl} className="previewBasic-point_point">
            {numberToComma(prevHomeIcon)}
          </span>
          <span ref={totalAwayCheerIconEl} className="previewBasic-point_point">
            {numberToComma(prevAwayIcon)}
          </span>
        </Point>
        <StatusBar
          ratio={ratio}
          className="previewBasic-gameSignage_bottombar previewBasic-statusBar"
          homeTeamColor={homeTeamColor}
          awayTeamColor={awayTeamColor}
        >
          <div className="previewBasic-statusBar_red" />
          <div className="previewBasic-statusBar_line" />
        </StatusBar>
        <CheerIcons>
          <div className="previewBasic-cheerIcon">
            <div className="previewBasic-cheerIcon_homeCheerIcon">
              <>
                {homeCheerIcon.map((homeCheerIcon, index) => (
                  <CheerIcon key={homeCheerIcon.id} icon={homeCheerIcon.icon} index={index} />
                ))}
              </>
            </div>
            <div className="previewBasic-cheerIcon_awayCheerIcon">
              <>
                {awayCheerIcon.map((awayCheerIcon, index) => (
                  <CheerIcon key={awayCheerIcon.id} icon={awayCheerIcon.icon} index={index} />
                ))}
              </>
            </div>
          </div>
        </CheerIcons>
      </div>
      {isShownFooter && (
        <Footer>
          {isJa ? (
            <>
              <img className="icon" src={spolive} alt="" />
              <span>アプリをダウンロードして応援に参加しよう！</span>
            </>
          ) : (
            <>
              <span className="icon_before">Download the</span>
              <img className="icon" src={spolive} alt="" />
              <span>app and join the community!</span>
            </>
          )}
        </Footer>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ isShow: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: opacity 0.5s;
  opacity: ${({ isShow }) => (isShow ? 1 : 0)};
  font-family: "OswaldWebFont";
  .previewBasic-status {
    display: flex;
    align-items: flex-end;
    position: relative;
    &_logo {
      width: 172px;
      margin-right: 30px;
      margin-left: 30px;
    }
    &_item {
      display: flex;
      width: 250px;
      justify-content: space-around;
      &_name {
        text-align: center;
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 4px;
      }
      &_board {
        border: 1px solid #ffffff;
        font-size: 40px;
        font-weight: bold;
        padding: 3px 37px;
        border-radius: 4px;
      }
      &_bonus {
        display: grid;
        height: 65px;
        div {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: #008001;
          margin: auto;
        }
      }
    }
  }
  .previewBasic-status-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    top: -55px;
  }
  .previewBasic-outer {
    height: 200px;
  }
`;

type StatusBarProps = {
  ratio: number;
  awayTeamColor: string | undefined;
  homeTeamColor: string | undefined;
};

const StatusBar = styled.div<StatusBarProps>`
  background-color: ${({ awayTeamColor }) => awayTeamColor || defaultAwayTeamColor};
  position: absolute;
  left: 0px;
  height: 83px;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  &::before {
    content: "";
    width: 100%;
    height: calc(100% - 8px);
    display: block;
    border: 4px solid #ffffff;
    position: "absolute";
    z-index: 1;
  }
  .previewBasic-statusBar {
    &_red {
      position: absolute;
      background-color: ${({ homeTeamColor }) => homeTeamColor || defaultHomeTeamColor};
      transition: width 0.5s;
      width: calc(${({ ratio }) => ratio}% - 4px);
      height: 100%;
      left: 0;
      border-right: 8px solid #ffffff;
    }
    &_line {
      width: 0;
      z-index: 2;
      position: absolute;
      height: calc(100% + 8px);
      top: -4px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border: 2px dashed #8b8b8b;
    }
  }
`;

const CheerIcons = styled.div`
  .previewBasic-cheerIcon {
    &_awayCheerIcon {
      font-size: 40px;
      position: absolute;
      display: flex;
      right: 30px;
      bottom: 0px;
      flex-flow: row-reverse;
    }
    &_homeCheerIcon {
      font-size: 40px;
      position: absolute;
      display: flex;
      left: 30px;
      bottom: 0px;
    }
  }
`;

const Point = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 32px 0 32px;
  box-sizing: border-box;
  .previewBasic-point {
    &_point {
      display: block;
      color: #000000;
      left: 32px;
      top: -5px;
      font-weight: 400px;
      font-size: 80px;
      letter-spacing: 3px;

      &--away {
        right: 40px;
        left: auto;
      }
      text-shadow: 5px 5px 1px #ffffff, -5px 5px 1px #ffffff, 5px -5px 1px #ffffff,
        -5px -5px 1px #ffffff, 5px 0px 1px #ffffff, 0px 5px 1px #ffffff, -5px 0px 1px #ffffff,
        0px -5px 1px #ffffff;
    }
  }
`;

const GameStartStatus = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  .previewBasic-game-start-status {
    &_img {
      width: 615px;
    }
    &_img-spolive {
      height: 45px;
    }
    &_text {
      position: absolute;
      font-size: 47px;
      color: #788e8a;
      font-weight: bold;
      font-family: "NotoSansJPWebFont";
      &--live {
        color: #ffadad;
      }
      &--en {
        font-size: 35px;
      }
    }
  }
`;

const Footer = styled.div`
  font-family: "NotoSansJPWebFont";
  height: 92px;
  width: 100%;
  background: #000000;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 55px;
    position: relative;
    margin-right: 10px;
    margin-top: 3px;
  }
  .img-spolive {
    height: 45px;
  }
  .icon {
    margin-top: 0;
  }
  .icon_before {
    display: inline-block;
    margin-right: 10px;
  }
  span {
    color: #ffffff;
    font-size: 40px;
    font-weight: bold;
  }
`;

const numberToComma = (num: number) => {
  return String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
};
