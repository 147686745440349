import { useCallback, useLayoutEffect, useState } from "react";

/**
 * DOMのwidth・heightを取得するために作成しました。
 * @module useOffset
 * @param  {React.RefObject<HTMLElement>} ref - useRefで作成したref
 * @return {object[]} - offsetHightとoffsetWidthを返す
 */

type Offset = {
  offsetHight: number | undefined;
  offsetWidth: number | undefined;
};

const useOffset = (ref: React.RefObject<HTMLElement>): [Offset, () => void] => {
  const [offset, setOffset] = useState<Offset>({
    offsetHight: undefined,
    offsetWidth: undefined
  });
  useLayoutEffect(() => {
    setOffset({
      offsetHight: ref.current?.offsetHeight,
      offsetWidth: ref.current?.offsetWidth
    });
    console.log(ref.current?.offsetHeight);
  }, [ref]);

  const update = useCallback(() => {
    setOffset({
      offsetHight: ref.current?.offsetHeight,
      offsetWidth: ref.current?.offsetWidth
    });
  }, [ref]);
  return [offset, update];
};

export default useOffset;
