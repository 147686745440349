import { Organization } from "@spolive-team/model";
import firebase from "firebase/app";

export const fetchOrganization = async (
  sportsId: string,
  organizationId: string
): Promise<Organization | undefined> => {
  const doc = await firebase
    .firestore()
    .doc(`sportsData/${sportsId}/organization/${organizationId}`)
    .get();
  if (!doc.exists) {
    return undefined;
  }
  return { ...doc.data(), id: doc.id } as Organization;
};
