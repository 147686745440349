import React from "react";
import styled from "styled-components";

import { getGameTimeTextForPeriod } from "assets/js/gameTimeTextGenerator";
import { Game } from "models/game";

type Props = {
  sportsId: string;
  gameData: Game | undefined;
  isJa: boolean;
};

export const GamePeriodLabel: React.FC<Props> = ({ gameData, sportsId, isJa }) => {
  const gamePeriodText = getGameTimeTextForPeriod(gameData, sportsId, isJa);

  if (!gameData || !gamePeriodText) {
    return null;
  }

  return <Wrapper className="a-gamePeriodLabel">{gamePeriodText}</Wrapper>;
};

const Wrapper = styled.span`
  font-size: 30px;
  font-weight: bold;
  line-height: 1;
  box-sizing: border-box;
  text-align: center;
  padding: 12px 42px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
`;
