import React, { FC, ReactElement } from "react";
import styled from "styled-components";
import placeIcon from "assets/images/game_card/multi_team_sports_card/place.svg";

type Props = {
  gameName: string;
  stageName: string;
  gameDate: string;
  gamePlace: string;
};

const DataLayer: FC<Props> = ({ gameName, stageName, gameDate, gamePlace }): ReactElement => {
  return (
    <Wrapper>
      <div className="gameDate">{gameDate}</div>
      <div className="gameNameWrapper">
        <div className="gameName">{gameName}</div>
      </div>
      <div className="stageNameWrapper">
        <div className="stageName">{stageName}</div>
      </div>
      <div className="gamePlace">
        <img className="placeIcon" src={placeIcon} alt="placeIcon" />
        <span>{gamePlace}</span>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .gameDate {
    font-family: "OswaldWebFontBold";
    font-weight: normal;
    font-size: 44px;
    line-height: 66px;

    text-align: center;

    color: #ffffff;

    margin-top: 120px;

    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  }

  .gameNameWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
  }

  .stageNameWrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 150px;
  }

  .gameName {
    font-family: "NotoSansJPWebFont";
    font-style: normal;
    font-weight: bold;
    font-size: 76px;
    line-height: 120%;

    text-align: center;

    color: #ffffff;

    // 3行以上は省略
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .stageName {
    font-family: "NotoSansJPWebFont";
    font-style: normal;
    font-weight: bold;
    font-size: 60px;
    line-height: 60px;

    text-align: center;

    color: #ffffff;

    // 3行以上は省略
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .gamePlace {
    font-family: "NotoSansJPWebFont";
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 52px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 1px;

    color: #ffffff;

    /* margin-top: 30px; */

    display: flex;
    justify-content: center;
    align-items: center;

    .placeIcon {
      margin: 0px 10px 0px 10px;
    }
  }
`;

export default DataLayer;
