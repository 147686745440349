import axios from "axios";

export const fetchOgImage = async (
  sportsId: string,
  leagueId: string,
  gameId: string,
  locale: string = "ja",
  timelineId: string,
  ogpPath: string
) => {
  let fetchUrl =
    process.env.REACT_APP_HTTP_FUNCTIONS_ROOT +
    `${ogpPath}?sportsId=${sportsId}&leagueId=${leagueId}&gameId=${gameId}&locale=${locale}`;
  if (timelineId) {
    fetchUrl += "&timelineId=" + timelineId;
  }
  try {
    const imageUrl = await axios(fetchUrl).then(res => res.data.imgUrl);
    const parsedUrl = new URL(imageUrl);
    parsedUrl.searchParams.append("t", `${Date.now()}`);
    return `${parsedUrl}`;
  } catch (e) {
    return "";
  }
};
