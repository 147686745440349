import React, { useContext } from "react";
import { Route, Switch } from "react-router";
import Home from "components/pages/Home";
import ShareCollection from "components/pages/ShareCollection";
import { GameShare, CheerBattleShare } from "components/pages/Share";
import PlaySplash from "components/pages/GameSplash/Play";
import EventSplash from "components/pages/GameSplash/Event";
import { GamePreview } from "components/pages/GamePreview/";
import styled from "styled-components";
import { ThemeContext } from "contexts";
import paths from "./paths";
import "./App.css";
import { hot, setConfig } from "react-hot-loader";
import GenScoreCard from "components/pages/GenScoreCard";
import GenChangePlayerCard from "components/pages/GenChangePlayerCard";
import GenCheerBattleResultCard from "components/pages/GenCheerBattleResultCard";
import GenGameCard from "components/pages/GenGameCard";
import { TeamList } from "components/pages/TeamPage/TeamList";
import { TeamPage } from "components/pages/TeamPage/TeamPage";
import { PlayerPage } from "components/pages/TeamPage/PlayerPage";
import PreviewScoreCard from "components/pages/PreviewScoreCard";
import PreviewChangePlayerCard from "components/pages/PreviewChangePlayerCard";
import { AlbumSplash } from "components/pages/AlbumSplash";

const App: React.FC = () => {
  const { color } = useContext(ThemeContext);

  return (
    <Wrapper className="App" color={color}>
      <Switch>
        <Route path={paths.home} component={Home} exact />
        <Route path={paths.shareCollection} component={ShareCollection} />
        <Route path={paths.share} component={GameShare} />
        <Route path={paths.cheerBattleShare} component={CheerBattleShare} />
        <Route path={paths.gameSplash} component={GenGameCard} />
        <Route path={paths.playSplash} component={PlaySplash} />
        <Route path={paths.eventSplash} component={EventSplash} />
        <Route path={paths.gamePreview} component={GamePreview} />
        <Route path={paths.cheerBattle} component={GenCheerBattleResultCard} />
        <Route path={paths.previewScoreCard} component={PreviewScoreCard} />
        <Route path={paths.previewChangePlayerCard} component={PreviewChangePlayerCard} />
        <Route path={paths.genScoreCard} component={GenScoreCard} />
        <Route path={paths.leaguePage} component={TeamList} />
        <Route path={paths.teamPage} component={TeamPage} />
        <Route path={paths.playerPage} component={PlayerPage} />
        <Route path={paths.genChangePlayerCard} component={GenChangePlayerCard} />
        <Route path={paths.albumSplash} component={AlbumSplash} />
      </Switch>
    </Wrapper>
  );
};

setConfig({ reloadHooks: false });
export default hot(module)(App);

const Wrapper = styled.div<{ color: any }>`
  background-color: ${({ color }) => color.bg};
  min-height: 100vh;
  overflow: auto;
  color: ${({ color }) => color.text};
`;
