import React, { FC, ReactElement } from "react";
import useReactRouter from "use-react-router";
import styled from "styled-components";
import qs from "qs";
import GameCard from "components/organisms/GameCard";
import { GameContextProvider } from "libs/gameContext";

type QueryParam = {
  sportsId: string;
  leagueId: string;
  gameId: string;
  locale?: "ja" | "en";
  homePoint?: string;
  awayPoint?: string;
};

const GenGameCard: FC = (): ReactElement => {
  const { location } = useReactRouter();
  const url = decodeURIComponent(location.search);
  const queryParam = qs.parse(url.replace("?", "")) as QueryParam;

  return (
    <Wrapper>
      <GameContextProvider
        sportsId={queryParam.sportsId}
        leagueId={queryParam.leagueId}
        gameId={queryParam.gameId}
        locale={queryParam.locale ?? "ja"}
        homePoint={
          Number.isNaN(Number(queryParam.homePoint)) ? undefined : Number(queryParam.homePoint)
        }
        awayPoint={
          Number.isNaN(Number(queryParam.awayPoint)) ? undefined : Number(queryParam.awayPoint)
        }
      >
        <GameCard
          sportsId={queryParam.sportsId}
          leagueId={queryParam.leagueId}
          gameId={queryParam.gameId}
          locale={queryParam.locale ?? "ja"}
          homePoint={queryParam.homePoint ?? null}
          awayPoint={queryParam.awayPoint ?? null}
        />
      </GameContextProvider>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 1200px;
  height: 630px;
`;

export default GenGameCard;
