import React, { FC, useEffect, useState } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";

import { User } from "models/user";
import { FirebaseContext, UserContext } from "contexts";

const FirebaseApp: FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const auth = firebase.auth();
  const firestore = firebase.firestore();
  const realtimeDb = firebase.database();

  const unsubscribe = auth.onAuthStateChanged(async firebaseUser => {
    if (firebaseUser) {
      //TODO:ログインしている状態:DBにデータがあったらそれを読み込む,なければ書き込む等の処理
      // setUser(userData);
    } else {
      setUser(null);
    }
  });

  useEffect(() => {
    return unsubscribe;
  });

  return (
    <FirebaseContext.Provider value={{ auth, firestore, realtimeDb }}>
      <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
    </FirebaseContext.Provider>
  );
};

export default FirebaseApp;
