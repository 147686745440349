import React, { FC, ReactElement } from "react";
import styled, { css } from "styled-components";
import confettiImg from "assets/images/cheer_battle_result_card/confetti.png";
import teamIconInnerRing from "assets/images/cheer_battle_result_card/inner_team_icon_ring.svg";
import teamIconOuterRing from "assets/images/cheer_battle_result_card/outer_team_icon_ring.svg";
import { sportsIconGreen } from "assets/js/sportsImages";

type Props = {
  sportsId: string;
  rank: string;
  cheerCount: string;
  teamName: string;
  teamIcon: string;
  teamColor: string;
  gameDate: string;
  gameName: string;
};

const DataLayer: FC<Props> = ({
  sportsId,
  rank,
  teamColor,
  teamName,
  teamIcon,
  cheerCount,
  gameDate,
  gameName
}): ReactElement => {
  const isWinner = rank === "1";
  return (
    <Wrapper teamColor={teamColor} isWinner={isWinner}>
      <div className="teamInfo">
        <div className="teamIconWrapper">
          <div className="teamIconImgWrapper">
            <img
              className="teamIconImg"
              src={teamIcon}
              alt="teamIcon"
              onError={e => (e.currentTarget.src = sportsIconGreen[sportsId])}
            />
          </div>
          {isWinner && (
            <>
              <img className="teamIconOuterRing" src={teamIconOuterRing} alt="teamIconOuterRing" />
              <img className="teamIconInnerRing" src={teamIconInnerRing} alt="teamIconInnerRing" />
            </>
          )}
        </div>

        <div className="teamInfoLeft">
          <div className="teamName">{teamName}</div>
          <div className="cheerCount">{cheerCount}</div>
        </div>
      </div>
      <div className="gameInfo">
        <div className="gameDate">{gameDate}</div>
        <div className="gameName">{gameName}</div>
      </div>
      {isWinner && <img className="confetti" src={confettiImg} alt="confettiImg" />}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ teamColor: string; isWinner: boolean }>`
  width: 100%;
  height: 100%;
  position: relative;
  .confetti {
    position: absolute;
    left: 0px;
    top: 0px;
  }
  .teamInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    .teamInfoLeft {
      display: flex;
      flex-direction: column;
      text-align: left;
      margin-left: 10px;
      width: 570px;
      overflow-wrap: break-word;
    }
  }
  .teamIconWrapper {
    width: 247px;
    height: 247px;
    margin-left: 350px;
    position: relative;
  }
  .teamIconInnerRing {
    width: 240px;
    height: 240px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .teamIconOuterRing {
    width: 247px;
    height: 247px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .teamIconImgWrapper {
    width: 235px;
    height: 235px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background: #ffffff;
    ${props =>
      !props.isWinner &&
      css`
        border: 7px solid black;
      `}
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .teamIconImg {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
  .teamName {
    font-family: "NotoSansJPWebFont";
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 50px;
    color: #ffffff;
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.65);
  }
  .cheerCount {
    font-family: "RobotoWebFont";
    font-style: italic;
    font-weight: bold;
    font-size: 90px;
    line-height: 100px;
    color: #ffffff;
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.65);
  }
  .gameInfo {
    width: 100%;
    position: absolute;
    bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: "NotoSansJPWebFont";
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
  }
`;

export default DataLayer;
