import React, { useMemo } from "react";
import styled from "styled-components";
import { CheerComment } from "models/cheerComment";
import { CheerCommentItem } from "./componets/CheerCommentItem";
import { useParameter } from "./hooks/useParameter";

type Props = {
  comments: CheerComment[];
  isShow: boolean;
  homeTeamColor: string | undefined;
  awayTeamColor: string | undefined;
  isShownFooter: boolean;
  isJa?: boolean;
};

const FONT_SIZE = 48;
const LINE_HEIGHT = 1.25;

export const CheerComments: React.FC<Props> = ({
  comments,
  isShow,
  homeTeamColor,
  awayTeamColor,
  isShownFooter,
  isJa
}) => {
  const awayTeamComments = useMemo(() => {
    return comments
      .filter(item => item.isHomeTeamSelectOrNeutral === false)
      .sort((a, b) => a.createdAt.seconds - b.createdAt.seconds);
  }, [comments]);

  const homeTeamComments = useMemo(() => {
    return comments
      .filter(item => item.isHomeTeamSelectOrNeutral !== false)
      .sort((a, b) => a.createdAt.seconds - b.createdAt.seconds);
  }, [comments]);

  const { hidden } = useParameter();

  return (
    <Wrapper
      isShow={isShow}
      awayTeamColor={awayTeamColor}
      homeTeamColor={homeTeamColor}
      isShownFooter={isShownFooter}
    >
      <div className="p-cheerComment">
        {!(hidden === "home") ? <CheerCommentItem comments={homeTeamComments} /> : <div />}
        {!(hidden === "away") ? (
          <CheerCommentItem comments={awayTeamComments} isHome={false} />
        ) : (
          <div />
        )}
      </div>
      {isShownFooter && (
        <Footer>
          <span>
            {isJa
              ? "「チャット」に投稿しよう！チームが勇気づけられるコメントありがとうございます！"
              : "“Chat” on the app! Thank you for your comments that encourage the team!"}
          </span>
        </Footer>
      )}
    </Wrapper>
  );
};

type WrapperProps = {
  isShow: boolean;
  awayTeamColor: string | undefined;
  homeTeamColor: string | undefined;
  isShownFooter: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: opacity 0.5s;
  opacity: ${({ isShow }) => (isShow ? 1 : 0)};
  display: flex;
  flex-direction: column;
  font-family: "RobotoWebFont", "NotoSansJPWebFont";
  background: linear-gradient(0deg, #000000 82.49%, rgba(0, 0, 0, 0) 97.73%);
  height: 430px;
  .p-cheerComment {
    padding-left: 32px;
    padding-left: calc(10% - 160px);
    padding-right: 32px;
    padding-left: calc(10% - 160px);
    display: flex;
    justify-content: space-between;
    height: 100%;
    &_comments-home {
      padding-top: 10px;
      width: 45%;
      bottom: 0px;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }
    &_comments-away {
      padding-top: 10px;
      width: 45%;
      bottom: 0px;
      text-align: right;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }
    &_comment {
      font-size: ${FONT_SIZE}px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      line-height: ${LINE_HEIGHT};
      width: 100%;
      margin-top: 20px;
    }
  }
`;

const Footer = styled.div`
  font-family: "NotoSansJPWebFont";
  position: absolute;
  height: 92px;
  width: 100%;
  background: #ffffff;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: #333333;
    font-size: 40px;
    font-weight: bold;
  }
`;
