import React, { useCallback, useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import anime from "animejs";
import { emoji2text } from "libs/textConvert";
import { Emoji } from "../../models/cheer";
import { images } from "libs/images";

type Props = {
  icon: Emoji;
  index: number;
};

export const CheerIcon: React.FC<Props> = ({ icon, index }) => {
  const convertedEmojiText = emoji2text(icon);
  const twemoji = images?.["cheerIcon_" + convertedEmojiText];

  const cheerIconEl = useRef<HTMLDivElement>(null);

  const animateCheerIcons = useCallback(index => {
    const tl = anime.timeline({
      duration: 1500,
      easing: "linear"
    });

    if (index === 0) {
      tl.add(
        {
          targets: cheerIconEl.current,
          opacity: 0,
          translateY: -340,
          translateX: -10
        },
        10
      );
    }
    if (index === 1) {
      tl.add(
        {
          targets: cheerIconEl.current,
          opacity: 0,
          translateY: -340,
          translateX: -10
        },
        200
      );
    }
    if (index === 2) {
      tl.add(
        {
          targets: cheerIconEl.current,
          opacity: 0,
          translateY: -340,
          translateX: 15
        },
        0
      );
    }
    if (index === 3) {
      tl.add(
        {
          targets: cheerIconEl.current,
          opacity: 0,
          translateY: -340,
          translateX: 15
        },
        100
      );
    }
    if (index === 4) {
      tl.add(
        {
          targets: cheerIconEl.current,
          opacity: 0,
          translateY: -340,
          translateX: 15
        },
        0
      );
    }
  }, []);

  useEffect(() => {
    animateCheerIcons(index);
  }, [animateCheerIcons, index]);

  return (
    <div ref={cheerIconEl}>
      <FadeIn>
        <img src={twemoji} style={{ width: "50px", height: "50px" }} alt="cheerIcon" />
      </FadeIn>
    </div>
  );
};

const fadeIn = keyframes`
0% {
  opacity: 0
}
20% {
    opacity: 1;
  }
80% {
  opacity: 1
}
100% {
  opacity: 0
}
`;

const FadeIn = styled.div`
  animation: ${fadeIn} 1.5s ease 0s alternate forwards;
`;
