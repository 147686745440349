import { sortBy } from "lodash";
import { Timeline as TimelineType, Stats, PeriodGamePoint } from "models/timeline";
import { periodNames, statTypes } from "assets/sportsData";
import { SportsEnum } from "models/sports";
import { getBaseballScore } from "assets/sportsData/baseball";
import { PeriodContract } from "models/game";

export const calculateTimelineData: (
  timelines: {
    [id: string]: TimelineType;
  },
  sportsId: string,
  specifiedTimelineId?: string,
  periodContract?: PeriodContract[]
) => {
  timelineData: TimelineType[];
  latestPoints: {
    home: number;
    away: number;
  };
  specifiedPlay: TimelineType | undefined;
  // テニスなどのセット内の取得ゲーム数
  latestGamePoints: PeriodGamePoint | undefined;
} = (timelines, sportsId, specifiedTimelineId = "", periodContract) => {
  let specifiedPlay: TimelineType | undefined = undefined;
  const latestPoints = {
    home: 0,
    away: 0
  };
  if (!timelines) {
    return {
      timelineData: [],
      latestPoints,
      latestGamePoints: undefined,
      specifiedPlay
    };
  }
  const hasPeriodDefine = Object.keys(timelines).some(id => timelines[id].isNewGamePeriodGame);
  const latestGamePoints = !hasPeriodDefine ? undefined : ({} as PeriodGamePoint);
  if (hasPeriodDefine) {
    Object.keys(timelines).forEach(
      id => (latestGamePoints![timelines[id].periodId!] = { home: 0, away: 0 })
    );
  }
  const timelineData: TimelineType[] = [];
  Object.entries(timelines).map(IdAndData => {
    const entry = IdAndData[1];
    entry.id = IdAndData[0];

    const hasPeriod =
      entry.hasOwnProperty("isFirstHalf") ||
      // 試合区切り定義がある場合
      (entry.hasOwnProperty("isNewGamePeriodGame") && entry.hasOwnProperty("periodId"));
    if (
      !entry.hasOwnProperty("type") ||
      !entry.hasOwnProperty("time") ||
      !hasPeriod ||
      !entry.hasOwnProperty("player") ||
      (!entry.player.home && !entry.player.away)
    ) {
      // console.log("不正なデータです" + JSON.stringify(entry));
      return undefined;
    }

    if (!entry.isNewGamePeriodGame) {
      entry.isFirstHalf = entry.isFirstHalf ? true : false;
    }
    if (!Number(entry.time)) {
      entry.time = "0.0";
    }
    return timelineData.unshift(entry);
  });
  //ソート
  const sortedTimelineData = hasPeriodDefine
    ? periodContract
      ? sortBy(timelineData, [
          tl => -1 * periodContract.findIndex(p => p.id === tl.periodId),
          tl =>
            (periodContract.find(p => p.id === tl.periodId)?.timerType === "CountDown"
              ? 1 // カウントダウンの場合のみ昇順
              : -1) * Number(tl.time) // カウントアップまたは時間非表示の場合は降順
        ])
      : sortBy(timelineData, [
          tl => {
            const order = periodNames(sportsId)[tl.periodId!]?.order || 0;
            const setMin = order * 120;
            // TODO:3x3も対応する
            // 試合時間がカウントダウン型の試合（アメフト，3x3）
            if (sportsId === "americanfootball") {
              return -setMin;
            }
            return -setMin - Number(tl.time);
          }
        ])
    : sortBy(timelineData, [
        "isFirstHalf",
        data => {
          return 60 - Number(data.time);
        }
      ]);
  //古い順
  const addPointTimelineData = sortedTimelineData
    .reverse()
    .map(entry => {
      if (!specifiedTimelineId || !specifiedPlay) {
        statTypes(sportsId)?.map((stat): Stats | undefined => {
          if (stat.point && entry.type === stat.key) {
            /**
+           * テニスの場合，セットカウントが加算された場合，
+           * ゲームカウントが加算されないように
+           */
            if (sportsId === "tennis" && entry.type === "set") {
              return undefined;
            }
            if (entry.player.home) {
              console.log("home", latestPoints.home + stat.point);
              latestPoints.home = latestPoints.home + stat.point;
            } else {
              console.log("away", latestPoints.away + stat.point);
              latestPoints.away = latestPoints.away + stat.point;
            }
          }

          // console.log("entry", entry);
          // console.log("stat", stat);
          // console.log("hasPeriodDefine", hasPeriodDefine);
          // console.log("entry.periodId", entry.periodId);
          if (
            (stat.gamePoint || stat?.point || entry?.point) &&
            entry.type === stat.key &&
            hasPeriodDefine &&
            latestGamePoints &&
            entry.periodId
          ) {
            const currentGamePoints = latestGamePoints[entry.periodId!];
            if (currentGamePoints) {
              if (entry.player.home) {
                // アメフト形式
                if (stat.gamePoint) {
                  currentGamePoints.home = currentGamePoints.home + stat.gamePoint;
                  // ホッケー形式
                } else if (stat?.point) {
                  currentGamePoints.home = currentGamePoints.home + stat.point;
                  // 野球ー形式
                } else if (entry?.point) {
                  currentGamePoints.home = currentGamePoints.home + entry.point;
                }
              } else {
                if (stat.gamePoint) {
                  currentGamePoints.away = currentGamePoints.away + stat.gamePoint;
                } else if (stat?.point) {
                  currentGamePoints.away = currentGamePoints.away + stat.point;
                } else if (entry?.point) {
                  currentGamePoints.away = currentGamePoints.away + entry.point;
                }
              }
            }
          }
          return undefined;
        });
      }
      if (specifiedTimelineId === entry.id) {
        specifiedPlay = entry;
      }

      return {
        ...entry,
        points: { ...latestPoints }
      };
    })
    .reverse();
  if (sportsId === SportsEnum.baseball) {
    const baseballScore = getBaseballScore(timelineData);
    latestPoints.home = baseballScore.home;
    latestPoints.away = baseballScore.away;
  }
  return {
    timelineData: addPointTimelineData,
    latestPoints,
    latestGamePoints,
    specifiedPlay
  };
};
