import { SportsDic } from "models/sportsDic";

export const darts: SportsDic = {
  playerHasUniformNumber: false,
  isFixedPlayerPositionNum: false,
  teamImgIsCircle: true,
  startingMemberNum: 15,
  reserveMemberNum: 8,
  statTypes: [
    {
      key: "bull",
      name: "ブル",
      name_en: "BULL",
      point: 1,
      iconText: "B"
    }
  ],
  infringementNames: [
    {
      key: "knockon",
      name: "ノックオン",
      name_en: "Knock-on"
    },
    {
      key: "throwforward",
      name: "スローフォワード",
      name_en: "Throw Forward"
    },
    {
      key: "offside",
      name: "オフサイド",
      name_en: "Offside"
    },
    {
      key: "notrollaway",
      name: "ノットロールアウェイ",
      name_en: "Not Roll Away"
    },
    {
      key: "overthetop",
      name: "オーバーザトップ",
      name_en: "Over The Top"
    },
    {
      key: "notreleasetheball",
      name: "ノットリリースザボール",
      name_en: "Not Release The Ball"
    },
    {
      key: "knotstraight",
      name: "ノットストレート",
      name_en: "Not Straight"
    },
    {
      key: "collapsing",
      name: "コラプシング",
      name_en: "Collapsing"
    },
    {
      key: "hightackle",
      name: "ハイタックル",
      name_en: "High Tackle"
    }
  ],
  positionNames: [],
  defaultPosition: [],
  addCheerIcons: ["🎯"]
};
