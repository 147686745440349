import { LeagueMaster } from "@spolive-team/model";
import firebase from "firebase/app";

export const fetchLeagueMaster = async (
  sportsId: string,
  leagueMasterId: string
): Promise<LeagueMaster | undefined> => {
  const doc = await firebase
    .firestore()
    .doc(`sportsData/${sportsId}/leagueMaster/${leagueMasterId}`)
    .get();
  if (!doc.exists) {
    return undefined;
  }
  return { ...doc.data(), id: doc.id } as LeagueMaster;
};
