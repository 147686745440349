import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import backButton from "assets/images/backButton.png";
import selectingPlayerImage from "assets/images/selectingPlayerImage.png";
import firebase from "firebase";
import { Player } from "@spolive-team/model";
import { TeamMaster } from "models/teamMaster";
import { Header } from "./internal/Header";
import media, { generateMedia } from "styled-media-query";
import { Footer } from "./internal/Footer";
import { FaUserAlt } from "react-icons/fa";
import { defaultSportsImage } from "../../../assets/js/images";

const customMedia = generateMedia({
  verySmall: "360px"
});

export const TeamPage = () => {
  const location = useLocation();
  const res = location.pathname.split("/");
  const sportsId = res[3];
  const teamMasterId = res[4];
  const [teamData, setTeamData] = useState<TeamMaster>();
  const [players, setPlayers] = useState<Player[] | undefined | null>(undefined);

  useEffect(() => {
    (async () => {
      const qs = await firebase
        .firestore()
        .collection(`sportsData/${sportsId}/teamMaster/${teamMasterId}/players`)
        .orderBy("name")
        .get();
      if (qs.size === 0) return setPlayers(null);
      const players = qs.docs.map(doc => {
        const setdata = { id: doc.id, ...doc.data() };
        return setdata as Player;
      });
      setPlayers(players);
    })();
  }, [sportsId, teamMasterId]);

  useEffect(() => {
    (async () => {
      firebase
        .firestore()
        .collection(`sportsData/${sportsId}/teamMaster/`)
        .doc(teamMasterId)
        .get()
        .then(doc => {
          setTeamData(doc.data() as TeamMaster);
        });
    })();
  }, [sportsId, teamMasterId]);

  const getPlayerBgClassName = (uniformNumber?: string) => {
    if (uniformNumber) {
      return "player_name_bg";
    } else {
      return "player_name_bg_without_uniformNumber";
    }
  };

  return (
    <Wrapper>
      <Header />
      <div className="subHeader_area">
        <Link
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            textDecoration: "none"
          }}
          to={{
            pathname: `/sports/tournaments/${sportsId}/${teamData?.teamIds[0].leagueId}`
          }}
        >
          <div className="backButton_wrapper">
            <img src={backButton} alt="backButton" className="backButton" />
          </div>
          <div className="subHeader_mainText">チーム一覧に戻る</div>
        </Link>
      </div>
      <div
        className="teamBgWrapper"
        style={{ backgroundImage: `url(${teamData?.backgroundImgUrl})` }}
      >
        {teamData?.logoUrl ? (
          <img src={teamData.logoUrl} alt="teamLogo" className="teamLogo" />
        ) : (
          <div className="dummy_team_logo_wrapper">
            <img src={defaultSportsImage[sportsId]} alt="teamLogo" className="dummy_team_logo" />
          </div>
        )}
        <div className="team_name">{teamData?.name}</div>
      </div>
      <div className="teamColorBar" style={{ backgroundColor: teamData?.color }} />
      <div className="mainContent_title_wrapper">
        <div className="mainContent_title">選手</div>
      </div>
      <div className="player_items_container">
        {players &&
          players.map(p => (
            <Link
              to={{
                pathname: `/sports/player/${sportsId}/${teamMasterId}/${p.id}`
              }}
              style={{ textDecoration: "none" }}
              className="teamItemContainer"
            >
              {p.photoUrl ? (
                <img className="player_photo" src={p.photoUrl} alt="playerPhoto" />
              ) : (
                <FaUserAlt className="no_user_photo_icon" color="gray" />
              )}
              <div className={getPlayerBgClassName(p.uniformNumber)} />
              <div
                className="player_name_wrapper"
                style={!p.uniformNumber ? { height: 48, bottom: 130 } : undefined}
              >
                <div className="player_number">{p.uniformNumber}</div>
                <div className="player_name">{p.name}</div>
              </div>
            </Link>
          ))}
      </div>
      {players === null && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginTop: 72
          }}
        >
          <p style={{ fontSize: 16, fontWeight: 700 }}>チームの登録待ちです。</p>
          <div style={{ height: 16 }} />
          <p style={{ fontSize: 12, color: "white", opacity: 0.8 }}>
            登録完了後、選手情報を閲覧できます。
          </p>
          <div style={{ height: 16 }} />
          <img src={selectingPlayerImage} className="noPlayersImage" alt="noPlayers" />
        </div>
      )}
      <div className="footer_wrapper">
        <Footer />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-height: 100vh;
  position: relative;
  padding-bottom: 64px;
  .header_area {
    background-color: #000000;
    height: 64px;
    display: flex;
    align-items: center;
    padding-left: 18px;
  }
  .spolive_logo {
    width: 113px;
    height: 32px;
  }
  .subHeader_area {
    background-color: #222222;
    height: 40px;
    display: flex;
    align-items: center;
  }
  .backButton_wrapper {
    margin-left: 21px;
    margin-right: 15px;
  }
  .backButton {
    width: 6px;
    height: 10px;
  }
  .subHeader_mainText {
    font-size: 14px;
    color: white;
  }
  .teamBgWrapper {
    height: 184px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1;
  }
  .teamLogo {
    object-fit: contain;
    width: 112px;
    height: 112px;
  }
  .dummy_teamLogo {
    background-color: #c4c4c4;
    width: 112px;
    height: 112px;
  }
  .team_name {
    font-size: 24px;
    font-weight: 700;
  }
  .teamColorBar {
    height: 40px;
  }
  .mainContent_title_wrapper {
    display: flex;
    margin-top: 32px;
    justify-content: center;
    align-items: center;
    margin-bottom: 28px;
  }
  .mainContent_title {
    font-weight: 700;
    font-size: 18px;
  }
  .player_items_container {
    margin: auto;
    display: grid;
    grid-gap: 24px 24px;
    padding: 10px;
    margin-bottom: 50px;
    place-items: center;
    ${media.greaterThan("medium")`
    width: 872px;
    grid-template-columns: repeat(4, 200px);
  `}
    ${media.between("medium", "large")`
    width: 648px;
    grid-template-columns: repeat(3, 200px);
  `}
  ${media.lessThan("medium")`
    width: 344px;
    grid-template-columns: repeat(2, 160px);
  `}
  ${customMedia.lessThan("verySmall")`
    width: 160px;
    grid-template-columns: repeat(1, 160px);
  `}
  }
  .teamItemContainer {
    cursor: pointer;
    height: 240px;
  }
  .player_name_bg {
    height: 72px;
    position: relative;
    bottom: 77px;
    background-color: #000000;
    opacity: 0.4;
  }
  .player_name_bg_without_uniformNumber {
    height: 48px;
    position: relative;
    bottom: 52px;
    background-color: #000000;
    opacity: 0.4;
  }
  .player_name_wrapper {
    position: relative;
    bottom: 170px;
    display: flex;
    margin-top: 20px;
    height: 72px;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    ${media.greaterThan("medium")`
    width: 200px;
  `}
    ${media.between("medium", "large")`
    width: 200px;
  `}
    ${media.lessThan("medium")`
    width: 160px;
  `}
  }
  .player_number {
    padding-left: 10px;
    color: white;
  }
  .player_name {
    font-weight: 700;
    padding-left: 10px;
    color: white;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 5px;
  }
  .player_photo {
    object-fit: cover;
    ${media.greaterThan("medium")`
    width: 200px;
    height: 240px;
  `}
    ${media.between("medium", "large")`
    width: 200px;
    height: 240px
  `}
    ${media.lessThan("medium")`
    width: 160px;
    height: 194px
  `}
  }
  .no_user_photo_icon {
    width: 200px;
    height: 240px;
    ${media.lessThan("medium")`
    width: 160px;
    height: 194px;
  `}
  }
  .dummy_team_logo_wrapper {
    width: 112px;
    height: 112px;
    background-color: white;
    border-radius: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dummy_team_logo {
    width: 80px;
    height: 80px;
  }
  .noPlayersImage {
    width: 289px;
    height: 289px;
  }
  .footer_wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    ${media.greaterThan("medium")`
    display: none
  `}
  }
`;
