import React from "react";
import styled from "styled-components";

type Props = {
  className?: string;
  state: boolean;
  text?: string;
  text_on?: string;
  text_off?: string;
  onClick: () => void;
};
export const ToggleButton: React.FC<Props> = ({
  className,
  state,
  text,
  text_on,
  text_off,
  onClick
}) => {
  return (
    <Wrapper className={className}>
      <div className="toggle_button" data-state={state} onClick={onClick} />
      <span>
        {text}
        {state ? (text_on ? text_on : "ON") : text_off ? text_off : "OFF"}
      </span>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  text-align: center;
  width: 100px;
  span {
    font-size: 10px;
    display: block;
  }
  .toggle_button {
    display: block;
    position: relative;
    margin: 0 auto;
    color: #fff;
    background-color: #242424;
    white-space: nowrap;
    border-radius: 5px;
    width: 65px;
    height: 24px;
    transition: background-color 0.5s;
    background-color: #999;
    cursor: pointer;
    &:hover {
      opacity: 0.75;
    }
    &[data-state="true"] {
      background-color: $primary;
      &:after {
        left: 31px;
      }
    }
    &:after {
      content: "";
      position: absolute;
      transition: left 0.5s;
      border-radius: 5px;
      top: 1px;
      left: 1px;
      width: 50%;
      height: 22px;
      background-color: #fff;
    }
  }
`;
