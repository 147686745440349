import React, { FC, ReactElement } from "react";
import styled, { css } from "styled-components";
import { RugbyScoreEvent } from "../types";

type Props = {
  eventType: RugbyScoreEvent;
};

const getDisplayEventText = (eventType: RugbyScoreEvent): string => {
  switch (eventType) {
    case "try":
      return "TRY";
    case "conversionGoal":
      return "Conversion Goal";
    case "dropGoal":
      return "Drop Goal";
    case "penaltyGoal":
      return "Penalty Goal";
    default:
      return "";
  }
};

const EventDataLayer: FC<Props> = ({ eventType }): ReactElement => {
  return (
    <Wrapper eventType={eventType}>
      <div className="event">{getDisplayEventText(eventType)}</div>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ eventType: RugbyScoreEvent }>`
  width: 100%;
  height: 100%;
  position: relative;
  .event {
    position: absolute;
    left: 30px;
    font-family: "Alverata";
    font-style: italic;
    font-weight: 900;
    ${props => {
      // event によってフォントサイズや配置場所が異なる
      switch (props.eventType) {
        case "try":
          return css`
            font-size: 160px;
            letter-spacing: 5px;
            bottom: 85px;
          `;
        case "dropGoal":
          return css`
            font-size: 138px;
            bottom: 120px;
          `;
        case "penaltyGoal":
          return css`
            font-size: 110px;
            bottom: 144px;
          `;
        case "conversionGoal":
          return css`
            font-size: 85px;
            bottom: 160px;
          `;
        default:
          return css`
            font-size: 160px;
            bottom: 144px;
          `;
      }
    }}
    /* identical to box height */

    background: linear-gradient(0deg, #f9d431 0%, #f39d3e 105%);
    width: 100%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
`;

export default EventDataLayer;
