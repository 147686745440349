export const Japanese = "ja";
export const English = "en";
export const langList = [Japanese, English] as const;
export type Lang = (typeof langList)[number];
export type LanguagesMap = { [Property in Lang]?: string };
export const DefaultLang: Lang = Japanese;

export const isValidLang = (lang: string) => langList.includes(lang as Lang);

export const toLang = (lang: string): Lang => {
  if (isValidLang(lang)) {
    return lang as Lang;
  } else if (isValidLang(lang.split("_")[0].toLowerCase())) {
    return lang.split("_")[0].toLowerCase() as Lang;
  }
  return DefaultLang;
};
