import React, { FC, ReactElement } from "react";
import styled, { css } from "styled-components";

export type Props = {
  logoImgUrl: string;
  description: string;
  widthPx: string;
  heightPx: string;
  positionType: PositionType;
  leftRightPx: string;
  topBottomPx: string;
};

export type PositionType = "leftTop" | "rightTop" | "leftBottom" | "rightBottom";

/**
 * ロゴを任意の場所（絶対座標）に配置するためのレイヤー
 */
const LogoLayer: FC<Props> = (props: Props): ReactElement => {
  return (
    <Wrapper
      positionType={props.positionType}
      leftRightPx={props.leftRightPx}
      topBottomPx={props.topBottomPx}
      width={props.widthPx}
      height={props.heightPx}
    >
      <img className="logoImg" src={props.logoImgUrl} alt={props.description} />
    </Wrapper>
  );
};

const Wrapper = styled.div<{
  positionType: PositionType;
  leftRightPx: string;
  topBottomPx: string;
  width: string;
  height: string;
}>`
  width: 100%;
  height: 100%;
  position: relative;
  .logoImg {
    position: absolute;
    ${props => {
      switch (props.positionType) {
        case "leftTop":
          return css`
            left: ${props.leftRightPx}px;
            top: ${props.topBottomPx}px;
          `;
        case "rightTop":
          return css`
            right: ${props.leftRightPx}px;
            top: ${props.topBottomPx}px;
          `;
        case "leftBottom":
          return css`
            left: ${props.leftRightPx}px;
            bottom: ${props.topBottomPx}px;
          `;
        case "rightBottom":
          return css`
            right: ${props.leftRightPx}px;
            bottom: ${props.topBottomPx}px;
          `;
        default:
          return css`
            left: ${props.leftRightPx}px;
            top: ${props.topBottomPx}px;
          `;
      }
    }}
    width: ${props => props.width}px;
    height: ${props => props.height}px;
  }
`;

export default LogoLayer;
