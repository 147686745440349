import React, { FC, ReactElement } from "react";
import styled from "styled-components";
import spoliveIcon from "assets/images/icon.png";
import spoliveLogo from "assets/images/logo.svg";

type Props = {
  bgImg: string;
};

const BgLayer: FC<Props> = ({ bgImg }): ReactElement => {
  return (
    <Wrapper>
      <div className="bgImgWrapper">
        <img src={bgImg} alt="bgImg" className="bgImg" />
      </div>
      <div className="logoWrapper">
        <img src={spoliveIcon} alt="spoLiveIcon" className="icon" />
        <img src={spoliveLogo} alt="spoliveLogo" className="logo" />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  .bgImgWrapper {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: #000000;

    .bgImg {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      opacity: 0.6;
    }
  }

  .logoWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 35px;
    top: 35px;
    .icon {
      width: 68px;
      height: 68px;
      margin-right: 15px;
    }
    .logo {
      height: 60px;
    }
  }
`;

export default BgLayer;
