import { useContext, useEffect, useRef, useState } from "react";
import { FirebaseContext } from "contexts";
import { Product, ProductMap } from "models/billing";

export const useCheerGiftProducts = () => {
  const [productList, setProductList] = useState<ProductMap>({});
  const [loading, setLoading] = useState(true);
  const firebaseRef = useRef(useContext(FirebaseContext));

  useEffect(() => {
    const { firestore } = firebaseRef.current;
    if (!firestore) {
      throw new Error("Firestore is not initialized");
    }
    firestore
      .collection("billing")
      .doc("v1")
      .collection("products")
      .get()
      .then(async querySnapshot => {
        const products: ProductMap = {};
        querySnapshot.docs.forEach(doc => (products[doc.id] = doc.data() as Product));
        setProductList(products);
        setLoading(false);
      });
  }, []);

  return { productList, loading };
};
