import { SportsDic } from "models/sportsDic";

export const badminton: SportsDic = {
  isFixedPlayerPositionNum: false,
  playerHasUniformNumber: false,
  hasPosition: false,
  hasUniformNumber: false,
  teamImgIsCircle: true,
  isSetMatch: true,
  isShowGamePeriodAfterEndGame: true,
  isShowLastPeriodGamePoint: false,
  startingMemberNum: 2,
  reserveMemberNum: 0,
  isHiddenReserveMember: true,
  statTypes: [
    {
      key: "point",
      name: "ポイント",
      name_en: "POINT",
      gamePoint: 1,
      color: "white",
      iconText: "P",
      isHiddenStat: true
    },
    {
      key: "set",
      name: "ゲーム",
      name_en: "GAME",
      point: 1,
      iconText: "G",
      isNoPlayer: true
    }
  ],
  infringementNames: [],
  initialPeriod: [
    {
      id: "first",
      name: "1G",
      name_en: "1G",
      nameScore: "1G",
      nameScore_en: "1G",
      isHiddenDisplayTime: true,
      order: 1
    },
    {
      id: "second",
      name: "2G",
      name_en: "2G",
      nameScore: "2G",
      nameScore_en: "2G",
      isHiddenDisplayTime: true,
      order: 2
    },
    {
      id: "third",
      name: "3G",
      name_en: "3G",
      nameScore: "3G",
      nameScore_en: "3G",
      isHiddenDisplayTime: true,
      order: 3
    }
  ],
  periodNames: {
    first: {
      id: "first",
      name: "1G",
      name_en: "1G",
      nameScore: "1G",
      nameScore_en: "1G",
      isHiddenDisplayTime: true,
      displayMaxTimeMin: 120,
      order: 1
    },
    second: {
      id: "second",
      name: "2G",
      name_en: "2G",
      nameScore: "2G",
      nameScore_en: "2G",
      isHiddenDisplayTime: true,
      displayMaxTimeMin: 120,
      order: 2
    },
    third: {
      id: "third",
      name: "3G",
      name_en: "3G",
      nameScore: "3G",
      nameScore_en: "3G",
      isHiddenDisplayTime: true,
      displayMaxTimeMin: 120,
      order: 3
    }
  },
  addCheerIcons: ["🏸"]
};
