import React, { FC, ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import BgLayer from "./BgLayer";
import { CardCanvas, Layer } from "./LayerSystem";
import { CardData } from "./types";
import DataLayer from "./DataLayer";
import { sportsIconGreen } from "assets/js/sportsImages";
import {
  fetchCheerTeamSelect,
  fetchCheerTotalPoint,
  fetchGame,
  fetchLeagueTeam,
  fetchTeamMaster
} from "assets/js/firebase";
import setHtmlMeta from "assets/js/setHtmlMeta";

const CARD_HEIGHT = 630;
const CARD_WIDTH = 1200;

type Props = {
  sportsId: string;
  leagueId: string;
  gameId: string;
  teamId?: string;
  uid?: string;
  locale: "ja" | "en";
};

const makeGameDate = (date: Date | undefined): string => {
  if (!date) return "";
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const min = date.getMinutes();
  return `${year}年${month}月${day}日 ${hour}:${min}〜`;
};

const getUserCheerTeamId = async (
  sportsId: string,
  leagueId: string,
  gameId: string,
  uid: string
): Promise<string | undefined> => {
  const cheerTeamSelect = await fetchCheerTeamSelect(sportsId, leagueId, gameId, uid);
  return cheerTeamSelect?.selectTeamId;
};

const makeCardData = async (
  sportsId: string,
  leagueId: string,
  gameId: string,
  locale: "ja" | "en",
  teamId?: string,
  uid?: string
): Promise<CardData> => {
  const cheerTotalPointData = await fetchCheerTotalPoint(sportsId, leagueId, gameId);
  let cheerTeamId = teamId;
  if (uid) {
    cheerTeamId = await getUserCheerTeamId(sportsId, leagueId, gameId, uid);
  }
  // teamId is not specified, display top rank team
  const targetTeamCheerResults = cheerTeamId
    ? cheerTotalPointData.teamCheerResult?.filter(val => val.teamId === cheerTeamId)
    : cheerTotalPointData.teamCheerResult?.filter(val => val.rank === 1);
  if (!targetTeamCheerResults) {
    throw new Error("teamCheerResult is empty.");
  }
  const targetTeamCheerResult = targetTeamCheerResults[0];
  const leagueTeam = await fetchLeagueTeam(sportsId, leagueId, targetTeamCheerResult.teamId);
  if (!leagueTeam.masterTeamId) {
    throw new Error("masterTeamId is undefined.");
  }
  const game = await fetchGame(sportsId, leagueId, gameId);

  const teamMaster = await fetchTeamMaster(sportsId, leagueTeam.masterTeamId);
  return {
    teamName: teamMaster.name,
    teamIcon: teamMaster.logoUrl ?? sportsIconGreen[sportsId],
    teamColor: teamMaster.color ?? "white",
    gameDate: makeGameDate(game.info_datetime?.toDate()),
    gameName: locale === "ja" ? game.info_leagueText ?? "" : game.info_leagueText_en ?? "",
    cheerCount: targetTeamCheerResult.cheerTotalPoint.toLocaleString(),
    rank: targetTeamCheerResult.rank.toString()
  };
};

const MultiTeamSportsCard: FC<Props> = ({
  sportsId,
  leagueId,
  gameId,
  uid,
  teamId,
  locale
}): ReactElement => {
  const [cardData, setCardData] = useState<CardData | null>(null);
  useEffect(() => {
    const load = async () => {
      setCardData(await makeCardData(sportsId, leagueId, gameId, locale, teamId, uid));
    };
    load();
  }, [sportsId, leagueId, gameId, teamId, uid, locale]);
  useEffect(() => {
    setHtmlMeta({ viewport: "width=" + CARD_WIDTH });
  }, []);
  return (
    <Wrapper>
      {cardData && (
        <div className="p-cheerPoints">
          <Card teamColor={cardData.teamColor}>
            <CardCanvas width={CARD_WIDTH} height={CARD_HEIGHT}>
              <Layer layerProperty={1} width={CARD_WIDTH} height={CARD_HEIGHT}>
                <BgLayer rank={cardData.rank} teamColor={cardData.teamColor} />
              </Layer>
              <Layer layerProperty={2} width={CARD_WIDTH} height={CARD_HEIGHT}>
                <DataLayer
                  sportsId={sportsId}
                  rank={cardData.rank}
                  teamName={cardData.teamName}
                  teamIcon={cardData.teamIcon}
                  teamColor={cardData.teamColor}
                  gameDate={cardData.gameDate}
                  gameName={cardData.gameName}
                  cheerCount={cardData.cheerCount}
                />
              </Layer>
              <Layer layerProperty={3} width={CARD_WIDTH} height={CARD_HEIGHT}></Layer>
              <Layer layerProperty={3} width={CARD_WIDTH} height={CARD_HEIGHT}>
                <div className="cardBorder" />
              </Layer>
            </CardCanvas>
          </Card>
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .p-cheerPoints {
    width: 1200px;
    height: 630px;
  }
`;

const Card = styled.div<{ teamColor: string }>`
  .cardBorder {
    height: 100%;
    width: 100%;
    border: 12px solid ${props => props.teamColor};
    box-sizing: border-box;
  }
`;

export default MultiTeamSportsCard;
