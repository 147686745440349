import React, { useState } from "react";
import styled from "styled-components";
import { useGameDataSnapshot } from "hooks/use-gameDataSnapshot";

import { LiveGame } from "components/pages/GamePreview/LiveGame";
import { ToggleButton } from "components/atoms/ToggleButton";
import { GameDataContext } from "./hooks/gameData";
import displayBg from "assets/images/img_bg_light.jpg";
import { useGamePoints } from "./hooks/useGamePoints";
import { useScale } from "hooks/useScale";
import { useParameter } from "./hooks/useParameter";
import { useSetPlayers } from "./hooks/useGamePlayers";

export const GamePreview: React.FC = () => {
  const { sportsId, leagueId, gameId, locale, isShownFooter, isShownTime } = useParameter();
  const isJa = locale === "ja";
  const [isShownFooterState, setIsShownFooterState] = useState(
    isShownFooter === "false" ? false : true
  );
  const [isShownTimeState, setIsShownTimeState] = useState(isShownTime === "false" ? false : true);
  const {
    gameData,
    placeImageUrl,
    latestPoints,
    latestGamePoints,
    totalHomeCheerIcon,
    totalAwayCheerIcon,
    cheerComments,
    cheerGiftComments,
    productList,
    loading,
    error,
    timelineData
  } = useGameDataSnapshot({
    sportsId,
    leagueId,
    gameId
  });

  const { firstHalf, lastHalf } = useGamePoints(timelineData);
  const { scale } = useScale();
  useSetPlayers({ sportsId, leagueId, team: gameData?.homeTeam });
  useSetPlayers({ sportsId, leagueId, team: gameData?.awayTeam });

  if (loading || error) {
    // 読み込み中に期待外の画面を表示させない
    return null;
  }

  return (
    <GameDataContext.Provider
      value={{
        sportsId,
        leagueId,
        gameId,
        isJa,
        gameData,
        placeImageUrl,
        latestPoints,
        isShownTime: isShownTimeState,
        latestGamePoints,
        isShownFooter: isShownFooterState,
        firstHalf,
        lastHalf
      }}
    >
      <Wrapper className="p-gamePreview">
        <Background>
          <img className="p-gameSignage-display_bgImage" src={displayBg} alt="bgImage" />
          <div className="p-gameSignage-display_bg" />
          <div className="p-gamePreview_frame" style={{ transform: `scale(${scale})` }}>
            <LiveGame
              sportsId={sportsId}
              leagueId={leagueId}
              gameId={gameId}
              locale={locale}
              gameData={gameData}
              latestPoints={latestPoints}
              totalHomeCheerIcon={totalHomeCheerIcon}
              totalAwayCheerIcon={totalAwayCheerIcon}
              cheerComments={cheerComments}
              cheerGiftComments={cheerGiftComments}
              productList={productList}
              isShownFooter={isShownFooterState}
              isShownTime={isShownTimeState}
              timelineData={timelineData}
            />
          </div>
          <ToggleHeader>
            <ToggleButton
              state={isShownFooterState}
              onClick={() => setIsShownFooterState(state => !state)}
              text_on={"表示"}
              text_off={"非表示"}
              text={"フッター"}
            />
            <ToggleButton
              state={isShownTimeState}
              onClick={() => setIsShownTimeState(state => !state)}
              text_on={"表示"}
              text_off={"非表示"}
              text={"タイマー"}
            />
          </ToggleHeader>
        </Background>
      </Wrapper>
    </GameDataContext.Provider>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  .p-gamePreview {
    &_frame {
      transform-origin: center center;
    }
  }
`;

const ToggleHeader = styled.div`
  opacity: 0;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  &:hover {
    opacity: 1;
  }
`;

const Background = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .p-gameSignage-display_bgImage {
    position: absolute;
    width: 100%;
  }
  .p-gameSignage-display_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(31, 31, 31, 0.7);
    backdrop-filter: blur(11px);
  }
`;
