import React, { useContext } from "react";
import styled from "styled-components";
import { ThemeContext } from "contexts";
type GameStatus = {
  status: string;
  label: string;
  text: string;
};
export const GameStatusLabel: React.FC<{
  gameStatus: GameStatus;
}> = ({ gameStatus }) => {
  const { color } = useContext(ThemeContext);
  const { status, label, text }: GameStatus = gameStatus;

  if (status === "hidden") {
    return null;
  }

  let bg = color.primary;
  let textColor = color.text;
  switch (label) {
    case "red":
      bg = color.danger;
      break;
    case "white":
      bg = color.white;
      textColor = color.darkText;
      break;
  }
  return (
    <Wrapper bg={bg} color={textColor} className="a-gameStatusLabel">
      {text}
    </Wrapper>
  );
};

const Wrapper = styled.span<{ bg: string; color: string }>`
  font-size: 30px;
  font-weight: bold;
  line-height: 1;
  box-sizing: border-box;
  text-align: center;
  padding: 12px 42px;
  border-radius: 8px;
  background-color: ${({ bg }) => bg};
  color: ${({ color }) => color};
`;
