import { SportsDic } from "models/sportsDic";

export const flyingdisc: SportsDic = {
  playerHasUniformNumber: true,
  hasPosition: true,
  hasUniformNumber: true,
  isFixedPlayerPositionNum: false,
  teamImgIsCircle: true,
  startingMemberNum: 70,
  reserveMemberNum: 0,
  positionList: {
    ja: [
      {
        id: "qb",
        name: "QB"
      },
      {
        id: "handler",
        name: "ハンドラー"
      },
      {
        id: "middle",
        name: "ミドル"
      },
      {
        id: "deep",
        name: "ディープ"
      }
    ],
    en: [
      {
        id: "qb",
        name: "QB"
      },
      {
        id: "handler",
        name: "Handler"
      },
      {
        id: "middle",
        name: "Middle"
      },
      {
        id: "deep",
        name: "Deep"
      }
    ]
  },
  statTypes: [
    {
      key: "goal",
      name: "ゴール",
      name_en: "Goal",
      point: 1,
      iconText: "G",
      isAssist: true
    },
    {
      key: "callahangoal",
      name: "キャラハンゴール",
      name_en: "Callahan Goal",
      point: 1,
      color: "gray",
      iconText: "CG"
    },
    {
      key: "greatestgoal",
      name: "グレイテストゴール",
      name_en: "Greatest Goal",
      point: 1,
      color: "gray",
      iconText: "GR"
    }
  ],
  periodNames: {
    firstHalf: {
      id: "gametime",
      name: "試合時間",
      name_en: "GameTime",
      nameScore: "",
      nameScore_en: "",
      displayMaxTimeMin: 120,
      order: 1
    }
  },
  infringementNames: [],
  addCheerIcons: [],
  defaultPosition: []
};

// export const FlyingdiscPeriodNames = {
//   firstHalf: "firstHalf"
// } as const;

// /* eslint-disable */
// export type FlyingdiscPeriodNames =
//   typeof FlyingdiscPeriodNames[keyof typeof FlyingdiscPeriodNames];
