import React, { useEffect, useRef, useContext } from "react";
import useReactRouter from "use-react-router";
import qs from "qs";

import useGameData from "hooks/use-gameData";
import { FirebaseContext } from "contexts";
import { fetchOgImage } from "assets/js/fetchOgImage";
import { goLP } from "assets/js/goLP";
import { isMobile } from "assets/js/platform";
import { getTitleDescription } from "assets/js/metaTextGenerater";
import { wait } from "assets/js/wait";
import setHtmlMeta from "assets/js/setHtmlMeta";
import { goAppStore } from "assets/js/goAppStore";

declare global {
  interface Window {
    prerenderReady: boolean;
  }
}

const Share: React.FC<{ ogpPath: string }> = ({ ogpPath }) => {
  const { location } = useReactRouter();
  const url = decodeURIComponent(location.search);
  const {
    sportsId,
    leagueId,
    gameId,
    locale = "ja",
    timelineId,
    ogp,
    debug = false // デバッグ用
  } = qs.parse(url.replace("?", "")) as { [key: string]: string };
  const { gameData, loading, specifiedPlay, leagueData } = useGameData({
    sportsId,
    leagueId,
    gameId,
    timelineId,
    isGetPlayersData: Boolean(timelineId)
  });
  const firebaseRef = useRef(useContext(FirebaseContext));
  const { firestore } = firebaseRef.current;

  // document.location.href = "https://web.spo.live/share/" + location.search;
  if (isMobile) {
    //アプリがインストールされている場合deeplink実行
    document.location.href = "spolive://gameDetail" + location.search;
    goAppStore(500);
  }

  useEffect(() => {
    const ogImageLoad = async () => {
      if (!!gameData?.isSubscriptionContents || !!gameData?.subscriptionContentTeamMasterId) {
        window.prerenderReady = true;
        if (debug) {
          return;
        }
        return goLP(1000);
      }
      if (!loading && gameData && leagueData && firestore) {
        if (ogp !== "hidden") {
          window.prerenderReady = false;
          const ogpImageUrl = await fetchOgImage(
            sportsId,
            leagueId,
            gameId,
            locale,
            timelineId,
            ogpPath
          );
          const { title, description } = await getTitleDescription(
            Boolean(locale === "ja"),
            gameData,
            sportsId,
            firestore,
            leagueData,
            specifiedPlay
          );
          setHtmlMeta({
            title,
            description,
            url: document.location.href,
            imageUrl: ogpImageUrl
          });
          await wait(500);
          window.prerenderReady = true;
        }
        if (debug) {
          return;
        }
        goLP(1000);
      }
    };
    ogImageLoad();
  }, [
    sportsId,
    leagueId,
    gameId,
    locale,
    loading,
    gameData,
    firestore,
    leagueData,
    specifiedPlay,
    ogp,
    timelineId,
    ogpPath,
    debug
  ]);

  if (!sportsId || !leagueId || !gameId) {
    if (isMobile) {
      goAppStore(500);
    } else {
      goLP(1000);
    }
    return null;
  }
  return <div />;
};

export const GameShare = () => <Share ogpPath={"modules-getShareImageUrl"} />;
export const CheerBattleShare = () => <Share ogpPath={"modules-getCheerBattleImageUrl"} />;
