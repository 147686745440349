import React, { FC, ReactElement } from "react";
import { HomeAwaySportsCard } from "./HomeAwaySportsCard";
import { MultiTeamSportsCard } from "./MultiTeamSportsCard";

type Props = {
  sportsId: string;
  leagueId: string;
  gameId: string;
  locale: "ja" | "en";
  homePoint: string | null;
  awayPoint: string | null;
};

const GameCard: FC<Props> = ({
  sportsId,
  leagueId,
  gameId,
  locale,
  homePoint,
  awayPoint
}): ReactElement => {
  const cardRoute = (sportsId: string): ReactElement => {
    switch (sportsId) {
      case "motorsports":
        return (
          <MultiTeamSportsCard
            sportsId={sportsId}
            leagueId={leagueId}
            gameId={gameId}
            locale={locale}
          />
        );
      default:
        return (
          <HomeAwaySportsCard
            sportsId={sportsId}
            leagueId={leagueId}
            gameId={gameId}
            locale={locale}
            homePoint={homePoint}
            awayPoint={awayPoint}
          />
        );
    }
  };
  return cardRoute(sportsId);
};

export default GameCard;
