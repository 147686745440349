import { Game, Points } from "models/game";
import { PeriodGamePoint } from "models/timeline";
import { createContext } from "react";

type GameDataContextType = {
  sportsId: string;
  leagueId: string;
  gameId: string;
  isJa: boolean;
  gameData: Game | undefined;
  placeImageUrl: string;
  latestPoints: { home: number; away: number };
  isShownTime: boolean;
  latestGamePoints: PeriodGamePoint | undefined;
  isShownFooter: boolean;
  firstHalf: Points;
  lastHalf: Points;
};

export const GameDataContext = createContext<GameDataContextType>({
  sportsId: "",
  leagueId: "",
  gameId: "",
  isJa: true,
  gameData: undefined,
  placeImageUrl: "",
  latestPoints: { home: 0, away: 0 },
  isShownTime: true,
  latestGamePoints: undefined,
  isShownFooter: true,
  firstHalf: { home: "-", away: "-" },
  lastHalf: { home: "-", away: "-" }
});
