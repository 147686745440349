import React, { FC, ReactElement } from "react";
import styled from "styled-components";

const EffectLayer: FC = (): ReactElement => {
  return (
    <Wrapper>
      <div className="effect" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  .effect {
    position: absolute;
    width: 242px;
    height: 1206px;
    left: -236px;
    top: -390px;

    background: #e60012;
    transform: rotate(19.68deg);
  }
`;

export default EffectLayer;
