import { periodNames } from "assets/sportsData";
import { Period, PeriodContract, PeriodTimeStampContract } from "models/game";
import firebase from "firebase";
type Timestamp = firebase.firestore.Timestamp;

/**
 * ピリオド名を取得する
 * @param currentPeriodId string
 * @param isJa boolean
 * @returns string
 */
export interface findPeriodName {
  (periodId: string, isJa: boolean): string | null;
}

const findPeriodNameFromPeriodContract = (periodContract: PeriodContract[]): findPeriodName => {
  return (periodId: string, isJa: boolean) => {
    const period = periodContract.find(p => p.id === periodId);
    const name_ja = period?.name_ja;
    const name_en = period?.name_en;
    return isJa ? name_ja || name_en || null : name_en || name_ja || null;
  };
};

const findPeriodNameDefault = (sportsId: string): findPeriodName => {
  return (periodId: string, isJa: boolean) => {
    const name_ja = periodNames(sportsId)?.[periodId]?.name;
    const name_en = periodNames(sportsId)?.[periodId]?.name_en;
    return isJa ? name_ja || name_en || null : name_en || name_ja || null;
  };
};

export const findPeriodNameCreator = (
  periodContract: PeriodContract[] | undefined,
  sportsId: string
): findPeriodName => {
  const findFromContract =
    periodContract && periodContract.length > 0
      ? findPeriodNameFromPeriodContract(periodContract)
      : null;

  const findDefault = findPeriodNameDefault(sportsId);
  return (periodId: string, isJa: boolean) => {
    const resultFromContract = findFromContract ? findFromContract(periodId, isJa) : null;
    return resultFromContract !== null ? resultFromContract : findDefault(periodId, isJa);
  };
};

/**
 * ピリオドのスコア名を取得する
 * ピリオド共通化以前は、スコア用の名前も用意されていたため、このような処理をfindPeriodNameCreatorと別に用意
 * @param currentPeriodId string
 * @param isJa boolean
 * @returns string
 */
export interface findPeriodName {
  (periodId: string, isJa: boolean): string | null;
}

const findPeriodScoreNameDefault = (sportsId: string): findPeriodName => {
  return (periodId: string, isJa: boolean) => {
    const name_ja = periodNames(sportsId)?.[periodId]?.nameScore;
    const name_en = periodNames(sportsId)?.[periodId]?.nameScore_en;
    return isJa ? name_ja || name_en || null : name_en || name_ja || null;
  };
};

export const findPeriodScoreNameCreator = (
  periodContract: PeriodContract[] | undefined,
  sportsId: string
): findPeriodName => {
  // ピリオド共通化以降は、スコア用のデータは別途用意しないので、findPeriodNameFromPeriodContractを流用
  const findFromContract =
    periodContract && periodContract.length > 0
      ? findPeriodNameFromPeriodContract(periodContract)
      : null;

  const findDefault = findPeriodScoreNameDefault(sportsId);
  return (periodId: string, isJa: boolean) => {
    const resultFromContract = findFromContract ? findFromContract(periodId, isJa) : null;
    return resultFromContract !== null ? resultFromContract : findDefault(periodId, isJa);
  };
};

/**
 * ピリオド時刻を取得する
 * @param timestampType "startedAt" | "finishedAt"
 * @param currentPeriodId string
 * @returns Timestamp | null
 */
export interface findPeriodTime {
  (timestampType: "startedAt" | "finishedAt", currentPeriodId: string): Timestamp | null;
}

const findPeriodTimeFromPeriodList = (periodList: Period[]): findPeriodTime => {
  return (timestampType: "startedAt" | "finishedAt", currentPeriodId: string) => {
    return periodList.find(p => p.id === currentPeriodId)?.[timestampType] || null;
  };
};

const findPeriodTimeFromPeriodTimeStampContract = (
  periodTimeStampContract: PeriodTimeStampContract[]
): findPeriodTime => {
  return (timestampType: "startedAt" | "finishedAt", currentPeriodId: string) => {
    return (
      periodTimeStampContract.find(p => p.periodId === currentPeriodId)?.[timestampType] || null
    );
  };
};

export const findPeriodTimeCreator = (
  periodList: Period[] | undefined,
  periodTimeStampContract: PeriodTimeStampContract[] | undefined
): findPeriodTime => {
  const findFromContract =
    periodTimeStampContract && periodTimeStampContract.length > 0
      ? findPeriodTimeFromPeriodTimeStampContract(periodTimeStampContract)
      : null;
  const findFromPeriodList =
    periodList && periodList.length > 0 ? findPeriodTimeFromPeriodList(periodList) : null;

  const findDefault = (timestampType: "startedAt" | "finishedAt", currentPeriodId: string) => null;
  return (timestampType: "startedAt" | "finishedAt", currentPeriodId: string) => {
    const resultFromContract = findFromContract
      ? findFromContract(timestampType, currentPeriodId)
      : null;
    const resultFromPeriodList = findFromPeriodList
      ? findFromPeriodList(timestampType, currentPeriodId)
      : null;
    return (
      resultFromContract || resultFromPeriodList || findDefault(timestampType, currentPeriodId)
    );
  };
};
