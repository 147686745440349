import { SportsDic } from "models/sportsDic";

export const basketball3x3: SportsDic = {
  playerHasUniformNumber: true,
  hasPosition: false,
  hasUniformNumber: true,
  isFixedPlayerPositionNum: false,
  teamImgIsCircle: true,
  startingMemberNum: 4,
  reserveMemberNum: 0,
  isCountDownTime: true,
  isDisplaySeconds: true,
  isHiddenReserveMember: true,
  isPeriodFinish: true,
  statTypes: [
    {
      key: "point1",
      name: "1PT成功",
      name_en: "Made 1PT",
      name_short: "1PT成功",
      name_short_en: "Made 1PT",
      point: 1,
      gamePoint: 1,
      iconText: "+1",
      useRanking: true,
      buttonText: "+1",
      buttonGroup: "shoot"
    },
    {
      key: "1MISSED",
      name: "シュート失敗",
      name_en: "Missed shoot",
      name_short: "シュート失敗",
      name_short_en: "Missed shoot",
      color: "gray",
      iconText: "1P-",
      useRanking: true,
      buttonText: "+1 MISSED",
      buttonGroup: "shoot"
    },
    {
      key: "point2",
      name: "2PT成功",
      name_en: "Made 2PT",
      name_short: "2PT成功",
      name_short_en: "Made 2PT",
      point: 2,
      gamePoint: 2,
      iconText: "+2",
      useRanking: true,
      buttonText: "+2",
      buttonGroup: "shoot"
    },
    {
      key: "2MISSED",
      name: "2PTシュート失敗",
      name_en: "Missed 2PT shoot",
      name_short: "2PTシュート失敗",
      name_short_en: "Missed 2PT shoot",
      color: "gray",
      iconText: "2P-",
      useRanking: true,
      buttonText: "+2 MISSED",
      buttonGroup: "shoot"
    },
    {
      key: "freeThrow",
      name: "フリースロー成功",
      name_en: "Made free throw",
      name_short: "フリースロー成功",
      name_short_en: "Made free throw",
      point: 1,
      gamePoint: 1,
      iconText: "+FT",
      useRanking: true,
      buttonText: "FT Made",
      buttonGroup: "FT"
    },
    {
      key: "FTMissed",
      name: "フリースロー失敗",
      name_en: "Missed free throw",
      name_short: "フリースロー失敗",
      name_short_en: "Missed free throw",
      iconText: "FT-",
      color: "gray",
      useRanking: true,
      buttonText: "FT Missed",
      buttonGroup: "FT"
    },
    {
      key: "Rebound",
      name: "リバウンド",
      name_en: "Rebound",
      name_short: "リバウンド",
      name_short_en: "Rebound",
      iconText: "REB",
      useRanking: true,
      buttonText: "REBOUND",
      buttonGroup: "OTHER"
    },
    {
      key: "Foul",
      name: "ファウル",
      name_en: "Foul",
      name_short: "ファウル",
      name_short_en: "Foul",
      iconText: "F",
      color: "gray",
      useRanking: true,
      buttonText: "FOUL",
      buttonGroup: "OTHER",
      statsName: "チームファウル",
      statsNameEn: "Team fouls"
    },
    {
      key: "UFoul",
      name: "アンスポーツマンライクファウル",
      name_en: "Unsportsmanlike foul",
      name_short: "アンスポーツマンライクファウル",
      name_short_en: "Unsportsmanlike foul",
      iconText: "UF",
      color: "gray",
      useRanking: true,
      buttonText: "U-FOUL",
      buttonGroup: "OTHER2",
      aggregateAddPoint: 2,
      aggregateKey: "Foul"
    },
    {
      key: "DISQUALIFYING",
      name: "ディスクォリファイングファウル",
      name_en: "Disqualifying foul",
      name_short: "ディスクォリファイングファウル",
      name_short_en: "Disqualifying foul",
      iconText: "DF",
      color: "gray",
      useRanking: true,
      buttonText: "D-FOUL",
      buttonGroup: "OTHER2",
      aggregateAddPoint: 2,
      aggregateKey: "Foul"
    },
    {
      key: "Challenge",
      name: "チャレンジ",
      name_en: "Challenge",
      name_short: "チャレンジ",
      name_short_en: "Challenge",
      iconText: "CH",
      color: "gray",
      useRanking: true,
      buttonText: "CHALLENGE",
      buttonGroup: "OTHER2"
    },
    {
      key: "timeout",
      name: "タイムアウト",
      name_en: "Time-out ",
      iconText: "TO",
      color: "gray",
      isNoPlayer: true,
      isUseEventTimer: true,
      isHiddenStat: false,
      useRanking: false,
      buttonText: "TIMEOUT",
      buttonGroup: "TIMEOUT",
      isNotTimeLineInput: true
    },
    {
      key: "officialTimeout",
      name: "TVタイムアウト",
      name_en: "TV Time-out",
      iconText: "TVTO",
      color: "gray",
      isNoPlayer: true,
      isUseEventTimer: true,
      isHiddenStat: true,
      useRanking: false,
      buttonText: "TV",
      buttonGroup: "TIMEOUT",
      isNotTimeLineInput: true
    }
  ],
  infringementNames: [
    {
      key: "steal",
      name: "スティール",
      name_en: "Steal"
    },
    {
      key: "block",
      name: "ブロック",
      name_en: "Block"
    }
  ],
  // リーグごとに試合区切りデータを変更できるようにする必要があるかも
  initialPeriod: [
    {
      id: "1st",
      name: "",
      name_en: "",
      nameScore: "",
      nameScore_en: "",
      timeMin: 10,
      order: 1
    },
    {
      id: "OT",
      name: "OT",
      name_en: "OT",
      order: 5,
      isExtra: true,
      timeMin: 0,
      isBreak: false
    }
  ],
  periodNames: {
    "1st": {
      id: "1st",
      name: "",
      name_en: "",
      nameScore: "",
      nameScore_en: "",
      timeMin: 10,
      order: 1,
      isLastPeriod: true,
      isBreak: false
    },
    OT: {
      id: "OT",
      name: "OT",
      name_en: "OT",
      nameScore: "OT",
      nameScore_en: "OT",
      order: 5,
      isExtra: true,
      timeMin: 0,
      isBreak: false,
      displayMaxTimeMin: 0
    }
  },
  addCheerIcons: ["🏀"]
};
