import React, { FC, ReactElement } from "react";
import styled from "styled-components";

type Props = {
  gameName: string;
  gameStatus: string;
};

const GameStatusLayer: FC<Props> = ({ gameName, gameStatus }): ReactElement => {
  return (
    <Wrapper>
      <div className="gameStatusWrapper">
        <div className="gameStatus">{gameStatus}</div>
        <div className="gameName">{gameName}</div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  .gameStatusWrapper {
    position: absolute;
    left: 0px;
    top: 868px;
    width: 100%;
    height: 132px;
    background: #100d30;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .gameStatus {
    font-family: "RobotoWebFont";
    font-style: italic;
    font-weight: 700;
    font-size: 40px;
    line-height: 47px;
  }
  .gameName {
    font-family: "RobotoWebFont";
    font-style: italic;
    font-weight: 500;
    font-size: 27px;
    line-height: 32px;
  }
`;

export default GameStatusLayer;
