import { SportsDic } from "models/sportsDic";

export const tennis: SportsDic = {
  playerHasUniformNumber: false,
  isFixedPlayerPositionNum: false,
  teamImgIsCircle: true,
  isShowGamePeriodAfterEndGame: true, // 試合が終わっても試合カードに最後のピリオド名を表示する
  isShowLastPeriodGamePoint: true, // 試合カードには、合計点でなく最後のピリオドの点数を表示する
  startingMemberNum: 15,
  reserveMemberNum: 8,
  statTypes: [
    {
      key: "set",
      name: "セット",
      name_en: "SET",
      point: 1,
      iconText: "S"
    },
    {
      key: "game",
      name: "ゲーム",
      name_en: "GAME",
      gamePoint: 1,
      color: "white",
      iconText: "G",
      isHiddenStat: true
    }
  ],
  infringementNames: [
    {
      key: "ace",
      name: "エース",
      name_en: "Ace"
    },
    {
      key: "double_fault",
      name: "ダブルフォルト",
      name_en: "Double Fault"
    },
    {
      key: "break_succeed",
      name: "ブレイク成功",
      name_en: "Break"
    }
  ],
  periodNames: {
    first: {
      id: "first",
      name: "1SET",
      name_en: "1SET",
      nameScore: "1",
      nameScore_en: "1",
      isHiddenDisplayTime: true,
      displayMaxTimeMin: 120,
      order: 1
    },
    second: {
      id: "second",
      name: "2SET",
      name_en: "2SET",
      nameScore: "2",
      nameScore_en: "2",
      isHiddenDisplayTime: true,
      displayMaxTimeMin: 120,
      order: 2
    },
    third: {
      id: "third",
      name: "3SET",
      name_en: "3SET",
      nameScore: "3",
      nameScore_en: "3",
      isHiddenDisplayTime: true,
      displayMaxTimeMin: 120,
      order: 3
    },
    forth: {
      id: "forth",
      name: "4SET",
      name_en: "4SET",
      nameScore: "4",
      nameScore_en: "4",
      isHiddenDisplayTime: true,
      displayMaxTimeMin: 120,
      order: 4
    },
    fifth: {
      id: "fifth",
      name: "5SET",
      name_en: "5SET",
      nameScore: "5",
      nameScore_en: "5",
      isHiddenDisplayTime: true,
      displayMaxTimeMin: 120,
      order: 5
    },
    sixth: {
      id: "sixth",
      name: "6SET",
      name_en: "6SET",
      nameScore: "6",
      nameScore_en: "6",
      isHiddenDisplayTime: true,
      displayMaxTimeMin: 120,
      order: 6
    },
    seventh: {
      id: "seventh",
      name: "7SET",
      name_en: "7SET",
      nameScore: "7",
      nameScore_en: "7",
      isHiddenDisplayTime: true,
      displayMaxTimeMin: 120,
      order: 7
    }
  },
  positionNames: [],
  defaultPosition: [],
  addCheerIcons: ["🎾"]
};
