import { SportsDic } from "models/sportsDic";

export const group: SportsDic = {
  isFixedPlayerPositionNum: false,
  playerHasUniformNumber: false,
  hasPosition: false,
  hasUniformNumber: false,
  teamImgIsCircle: true,
  isSetMatch: false,
  isShowGamePeriodAfterEndGame: false,
  isShowLastPeriodGamePoint: false,
  startingMemberNum: 0,
  reserveMemberNum: 0,
  isHiddenReserveMember: true,
  statTypes: [],
  infringementNames: [],
  initialPeriod: [
    {
      id: "start",
      name: "開始",
      name_en: "Start",
      nameScore: "",
      nameScore_en: "",
      order: 1
    }
  ],
  periodNames: {
    start: {
      id: "start",
      name: "開始",
      name_en: "Start",
      nameScore: "",
      nameScore_en: "",
      isHiddenDisplayTime: true,
      displayMaxTimeMin: 240,
      order: 1
    }
  },
  isShowEventScreen: true
};
