import React, { FC, ReactElement } from "react";
import styled from "styled-components";
import defaultPlayer from "assets/images/change_player_card/default_player_out.png";

type Props = {
  playerName: string;
  playerNameEn: string;
  uniformNumber: string;
  playerPhoto?: string;
};

const OutPlayerLayer: FC<Props> = ({
  playerName,
  playerNameEn,
  uniformNumber,
  playerPhoto
}): ReactElement => {
  return (
    <Wrapper>
      <div className="playerInfoWrapper">
        <div className="playerPhotoWrapper">
          <img
            src={playerPhoto ?? defaultPlayer}
            className="playerPhoto"
            onError={e => {
              e.currentTarget.src = defaultPlayer;
            }}
            alt={playerName}
          />
        </div>
        <div className="playerInfo">
          <div className="upperSection">
            <span className="uniformNumber">{uniformNumber}</span>
            <span className="playerName">{playerName}</span>
          </div>
          <span className="playerNameEn">{playerNameEn}</span>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  .playerInfoWrapper {
    position: absolute;
    left: -78px;
    top: 80px;
    background-color: black;
    transform: skew(-30deg);
    transform-origin: bottom left;
  }
  .playerPhotoWrapper {
    position: relative;
    .playerPhoto {
      position: absolute;
      width: 600px;
      height: 600px;
      left: 0px;
      top: 0px;
      transform: skew(30deg);
    }
  }
  .playerInfo {
    display: flex;
    height: 136px;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 10px 50px 10px 240px;
    transform: skew(30deg);
    text-align: left;
    .upperSection {
      display: flex;
      justify-content: left;
      .uniformNumber {
        margin: 0px 20px 0px 5px;
        font-family: Oswald;
        font-style: normal;
        font-weight: bold;
        font-size: 46px;
        line-height: 64px;
        color: #000000;
        -webkit-text-stroke: 3px #ffffff;
        letter-spacing: -3px;
      }
      .playerName {
        font-family: "M + 1c";
        font-style: normal;
        font-weight: 900;
        font-size: 46px;
        line-height: 64px;
        color: #ffffff;
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
      }
    }
    .playerNameEn {
      font-family: Oswald;
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 44px;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: #ffffff;
      text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
    }
  }
`;

export default OutPlayerLayer;
