import React, { useRef } from "react";
import { GiftComment } from "models/giftComment";
import { SuperCheer } from "./componets/SuperCheer";

type Props = {
  homeLogo: string;
  awayLogo: string;
  cheerGiftComment: GiftComment | null;
  iconUrl: string;
  isShow: boolean;
  homeTeamColor: string | undefined;
  awayTeamColor: string | undefined;
};

export const RotationSuperCheer: React.FC<Props> = ({
  homeLogo,
  awayLogo,
  cheerGiftComment,
  iconUrl = "",
  isShow,
  homeTeamColor,
  awayTeamColor
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const commentRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const superCheerContentRef = useRef<HTMLDivElement>(null);

  return (
    <SuperCheer
      homeLogo={homeLogo}
      awayLogo={awayLogo}
      cheerGiftComment={cheerGiftComment}
      iconUrl={iconUrl}
      isShow={isShow}
      homeTeamColor={homeTeamColor}
      awayTeamColor={awayTeamColor}
      wrapperRef={wrapperRef}
      commentRef={commentRef}
      imageRef={imageRef}
      superCheerContentRef={superCheerContentRef}
    />
  );
};
