import styled from "styled-components";

export const CardCanvas = styled.div<{ width: number; height: number }>`
  position: relative;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  overflow: hidden; // はみ出した分は表示しない
`;

export const Layer = styled.div<{
  width: number;
  height: number;
  layerProperty?: number;
}>`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: ${props => props.layerProperty ?? "auto"};
`;
