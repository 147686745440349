import React, { FC, ReactElement } from "react";
import styled from "styled-components";
import leagueLogo from "assets/images/sports/rugby/league_logo/japan_rugby_league_one.png";

const LeagueLogoLayer: FC = (): ReactElement => {
  return (
    <Wrapper>
      <img className="leagueLogo" src={leagueLogo} alt="leagueLogo" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  .leagueLogo {
    position: absolute;
    right: 30px;
    top: 20px;
    width: 160px;
    height: 80px;
  }
`;

export default LeagueLogoLayer;
