import React, { FC, ReactElement } from "react";
import styled from "styled-components";
import tryImg from "assets/images/score_card/try.svg";
import conversionGoalImg from "assets/images/score_card/conversion_goal.svg";
import dropGoalImg from "assets/images/score_card/drop_goal.svg";
import penaltyGoalImg from "assets/images/score_card/penalty_goal.svg";
import { RugbyScoreEvent } from "../types";

type Props = {
  event: RugbyScoreEvent;
};

const ScoreEventTextLayer: FC<Props> = ({ event }): ReactElement => {
  return (
    <Wrapper>
      {event === "try" && <TextImg src={tryImg} />}
      {event === "dropGoal" && <TextImg src={dropGoalImg} />}
      {event === "conversionGoal" && <TextImg src={conversionGoalImg} />}
      {event === "penaltyGoal" && <TextImg src={penaltyGoalImg} />}
    </Wrapper>
  );
};

const TextImg = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

export default ScoreEventTextLayer;
