import { useMemo } from "react";
import useReactRouter from "use-react-router";
import qs from "qs";

/**
 * URLからクエリパラメータをパースして取得するhook
 * @returns クエリパラメータ
 */
export const useQueryParams = <T = {}>() => {
  const { location } = useReactRouter();
  const query = useMemo(() => {
    const url = decodeURIComponent(location.search);
    return qs.parse(url.replace("?", "")) as unknown as T;
  }, [location.search]);

  return query;
};
