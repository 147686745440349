import React, { FC, ReactElement } from "react";
import styled from "styled-components";
import defaultPlayer from "assets/images/score_card/default_player.png";

type Props = {
  playerPhoto?: string;
};

const PlayerPhotoLayer: FC<Props> = ({ playerPhoto }): ReactElement => {
  return (
    <Wrapper>
      <PlayerPhoto
        src={playerPhoto ?? defaultPlayer}
        alt="playerPhoto"
        onError={e => {
          e.currentTarget.src = defaultPlayer;
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const PlayerPhoto = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  bottom: 0px;
`;

export default PlayerPhotoLayer;
