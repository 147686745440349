import React, { useMemo } from "react";
import styled, { FlattenSimpleInterpolation } from "styled-components";

export const Typography = ({
  text,
  style
}: {
  text: string;
  style?: FlattenSimpleInterpolation;
}) => {
  const splitTextArr = useMemo(() => splitTextIntoEnAndJa(text), [text]);

  return (
    <>
      {splitTextArr.map((text, index) => {
        return (
          <React.Fragment key={index}>
            {ja2Bit(text) ? (
              <JPText css={style}>{text}</JPText>
            ) : (
              <ENText css={style}>{text}</ENText>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

const JPText = styled.span<{ css: FlattenSimpleInterpolation | undefined }>`
  font-family: "NotoSansJPWebFont";
  ${props => props.css}
`;

const ENText = styled.span<{ css: FlattenSimpleInterpolation | undefined }>`
  font-family: "RobotoWebFont";
  ${props => props.css}
`;

export const splitTextIntoEnAndJa = (text: string) => {
  const textArr = text.split("");
  const splitText: string[] = [];

  let pushedText = "";
  for (let i = 0; i < textArr.length; i++) {
    if (ja2Bit(pushedText) === ja2Bit(textArr[i])) {
      pushedText += textArr[i];
    } else {
      splitText.push(pushedText);
      pushedText = textArr[i];
    }
  }
  splitText.push(pushedText);

  return splitText;
};

const ja2Bit = (str: string) => {
  return str.match(/^[\u30a0-\u30ff\u3040-\u309f\u3005-\u3006\u30e0-\u9fcf]+$/) ? true : false;
};
