import { firestore } from "firebase";
import { useEffect, useState } from "react";
import { CheerTotalPoint } from "../models/cheerBattle";

export const useCheerTotalPoint = ({
  sportsId,
  leagueId,
  gameId
}: {
  sportsId: string;
  leagueId: string;
  gameId: string;
}) => {
  const [totalPoint, setTotalPoint] = useState<CheerTotalPoint>();
  const [error, setError] = useState();
  useEffect(() => {
    firestore()
      .collection(`sportsData/${sportsId}/league/${leagueId}/games/${gameId}/cheerCount`)
      .doc("totalPoint")
      .get()
      .then(doc => setTotalPoint(doc.data() as CheerTotalPoint))
      .catch(e => setError(e));
  }, [setTotalPoint, leagueId, sportsId, gameId]);
  return [totalPoint, error];
};
