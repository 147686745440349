import dayjs from "dayjs";
import React, { useContext, useMemo } from "react";
import { GameDataContext } from "../hooks/gameData";
import vs from "assets/images/VS.png";
import styled from "styled-components";

export const DateTime = () => {
  const { gameData } = useContext(GameDataContext);

  const { date, day, time } = useMemo(() => {
    const dateObj = dayjs(gameData?.info_datetime?.toDate());
    const date = dateObj.format("M.D");
    const day = dateObj.format("ddd");
    const time = dateObj.format("H:mm");
    return { date, day, time };
  }, [gameData?.info_datetime]);
  return (
    <DateTimeWrapper className="p-score-date-time">
      <div className="p-score-date-time_content">
        <span className="p-score-date-time_date">{date}</span>
        <span className="p-score-date-time_day">{day}</span>
        <span className="p-score-date-time_time">{time}</span>
      </div>
      <div className="p-score-date-time_vs">
        <img src={vs} alt="" />
      </div>
    </DateTimeWrapper>
  );
};

const DateTimeWrapper = styled.div`
  font-family: "OswaldWebFontBold", sans-serif;
  font-size: 80px;
  letter-spacing: 1px;
  position: relative;
  top: -30px;
  text-align: center;
  margin-top: 70px;
  span {
    display: inline-block;
  }
  width: 100%;
  .p-score-date-time {
    &_content {
      margin-bottom: 80px;
      line-height: 1;
      margin-top: 170px;
    }
    &_date {
      font-size: 120px;
      display: inline-block;
    }
    &_day {
      font-size: 60px;
      display: inline-block;
      margin-right: 32px;
    }
    &_time {
      font-size: 120px;
      display: inline-block;
    }
    &_vs {
      color: #a8a8a8;
      font-size: 140px;
      line-height: 1;
      img {
        width: 179px;
      }
    }
  }
`;
