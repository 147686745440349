import { SportsDic } from "models/sportsDic";

export const volleyball: SportsDic = {
  playerHasUniformNumber: true,
  isFixedPlayerPositionNum: false,
  hasPosition: true,
  teamImgIsCircle: true,
  isSetMatch: true,
  isShowGamePeriodAfterEndGame: true,
  isShowLastPeriodGamePoint: true,
  startingMemberNum: 8,
  reserveMemberNum: 6,
  statTypes: [
    {
      key: "point",
      name: "ポイント",
      name_en: "POINT",
      gamePoint: 1,
      color: "white",
      iconText: "P",
      isHiddenStat: true
    },
    {
      key: "set",
      name: "セット",
      name_en: "SET",
      point: 1,
      iconText: "S",
      isNoPlayer: true
    },
    {
      key: "change",
      name: "交代",
      name_en: "Change",
      isHiddenStat: true
    }
  ],
  infringementNames: [],
  initialPeriod: [
    {
      id: "first",
      name: "1セット",
      name_en: "1SET",
      nameScore: "1",
      nameScore_en: "1",
      isHiddenDisplayTime: true,
      order: 1
    },
    {
      id: "second",
      name: "2セット",
      name_en: "2SET",
      nameScore: "2",
      nameScore_en: "2",
      isHiddenDisplayTime: true,
      order: 2
    },
    {
      id: "third",
      name: "3セット",
      name_en: "3SET",
      nameScore: "3",
      nameScore_en: "3",
      isHiddenDisplayTime: true,
      order: 3
    },
    {
      id: "forth",
      name: "4セット",
      name_en: "4SET",
      nameScore: "4",
      nameScore_en: "4",
      isHiddenDisplayTime: true,
      order: 4
    },
    {
      id: "fifth",
      name: "5セット",
      name_en: "5SET",
      nameScore: "5",
      nameScore_en: "5",
      isHiddenDisplayTime: true,
      order: 5
    }
  ],
  periodNames: {
    first: {
      id: "first",
      name: "1セット",
      name_en: "1SET",
      nameScore: "1",
      nameScore_en: "1",
      isHiddenDisplayTime: true,
      displayMaxTimeMin: 120,
      order: 1
    },
    second: {
      id: "second",
      name: "2セット",
      name_en: "2SET",
      nameScore: "2",
      nameScore_en: "2",
      isHiddenDisplayTime: true,
      displayMaxTimeMin: 120,
      order: 2
    },
    third: {
      id: "third",
      name: "3セット",
      name_en: "3SET",
      nameScore: "3",
      nameScore_en: "3",
      isHiddenDisplayTime: true,
      displayMaxTimeMin: 120,
      order: 3
    },
    forth: {
      id: "forth",
      name: "4セット",
      name_en: "4SET",
      nameScore: "4",
      nameScore_en: "4",
      isHiddenDisplayTime: true,
      displayMaxTimeMin: 120,
      order: 4
    },
    fifth: {
      id: "fifth",
      name: "5セット",
      name_en: "5SET",
      nameScore: "5",
      nameScore_en: "5",
      isHiddenDisplayTime: true,
      displayMaxTimeMin: 120,
      order: 5
    }
  },
  positionNames: [{ name: "S" }, { name: "MB" }, { name: "L" }, { name: "OH" }, { name: "OP" }],
  defaultPosition: [
    { positionName: "S" },
    { positionName: "MB" },
    { positionName: "L" },
    { positionName: "OH" },
    { positionName: "OP" },
    { positionName: "L" },
    { positionName: "" },
    { positionName: "" }
  ],
  addCheerIcons: ["🏐"]
};
