import React from "react";
import Lv1 from "assets/images/supporterLv1.png";
import Lv2 from "assets/images/supporterLv2.png";
import Lv3 from "assets/images/supporterLv3.png";
import Lv4 from "assets/images/supporterLv4.png";
import Lv5 from "assets/images/supporterLv5.png";
import Lv6 from "assets/images/supporterLv6.png";
import styled from "styled-components";

type Props = {
  logoUrl: string;
  month: number;
  level: number;
};

export const SupporterBadge = ({ logoUrl, month, level }: Props) => {
  const badgeUrl = getBadgeUrl(level);
  const badgeColor = getBadgeColor(level);
  return (
    <Wrapper className="supporter-badge">
      <img src={badgeUrl} className="supporter-badge__badge" alt="badge" />
      <img src={logoUrl} className="supporter-badge__logo" alt="logo" />
      <span
        className="supporter-badge__month"
        style={{
          textShadow: `1px 1px ${badgeColor}`
        }}
      >
        {month}
      </span>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  .supporter-badge {
    &__badge {
      width: 113px;
      height: 58px;
    }
    &__logo {
      position: absolute;
      width: 33px;
      height: 33px;
      top: 10px;
      left: 8px;
      border-radius: 50%;
      object-fit: contain;
    }
    &__month {
      position: absolute;
      left: 64px;
      top: 16px;
      font-size: 22px;
      color: #ffffff;
      font-family: "OswaldWebFontBold";
    }
  }
`;

// supporterレベルに応じたバッジのURLを取得する関数
const getBadgeUrl = (level: number) => {
  switch (level) {
    case 1:
      return Lv1;
    case 2:
      return Lv2;
    case 3:
      return Lv3;
    case 4:
      return Lv4;
    case 5:
      return Lv5;
    case 6:
      return Lv6;
    default:
      return;
  }
};

// supporterレベルに応じたバッジの色を取得する関数
const getBadgeColor = (level: number) => {
  switch (level) {
    case 1:
      return "#2570A4";
    case 2:
      return "#397656";
    case 3:
      return "#517183";
    case 4:
      return "#8F5B0C";
    case 5:
      return "#9D2381";
    case 6:
      return "#301A9A";
    default:
      return "#2570A4";
  }
};
