import React, { FC, ReactElement, useEffect, useMemo, useState } from "react";
import useReactRouter from "use-react-router";
import styled from "styled-components";
import { CardData, ScoreEvent } from "components/organisms/ScoreCard/types";
import qs from "qs";
import { TeamMaster, TMID_RED_HURRICANES, TMID_URAYASU_DROCKS } from "models/teamMaster";
import ScoreCardForRedHurricanes from "components/organisms/ScoreCard/RedHurricanes";
import ScoreCardForUrayasuDRocks from "components/organisms/ScoreCard/UrayasuDRocks";
import { fetchTeamMaster, fetchTeamMasterPlayer } from "assets/js/firebase";
const makeCardData = async (
  sportsId: string,
  teamMasterId: string,
  teamMasterPlayerId: string,
  eventType: string
): Promise<CardData> => {
  const teamMaster: TeamMaster = await fetchTeamMaster(sportsId, teamMasterId);
  const teamMasterPlayer = await fetchTeamMasterPlayer(sportsId, teamMasterId, teamMasterPlayerId);
  return {
    bgImg: teamMaster.bgImgUrlWithProGraphicsNicePlay ?? "",
    playerName: teamMasterPlayer.name,
    playerNameEn: teamMasterPlayer.name_en ?? "",
    playerPhoto: teamMasterPlayer.photoUrlWithProGraphicsNicePlay,
    playerPosition: teamMasterPlayer.positionList ? teamMasterPlayer.positionList[0].id : "",
    gameName: "Sample Game",
    gameStatus: "前半XX分",
    eventType: getEventType(eventType),
    homeScore: "111",
    awayScore: "11",
    homeTeamIcon: teamMaster.logoUrl,
    awayTeamIcon: undefined,
    teamPosition: "home"
  } as CardData;
};

const getEventType = (eventTypeStr: string): ScoreEvent => {
  switch (eventTypeStr) {
    case "try":
      return "try";
    case "dropgoal":
      return "dropGoal";
    case "penaltygoal":
      return "penaltyGoal";
    case "conversiongoal":
      return "conversionGoal";
    default:
      throw new Error(`invalid event type: ${eventTypeStr}`);
  }
};

type QueryParam = {
  sportsId: string;
  teamMasterId: string;
  teamMasterPlayerId: string;
  eventType: string;
};

/**
 * スコアカードのプレビュー表示用コンポーネント
 * 管理画面上で、選手データ（画像、名前）がどのように表示されるかを確かめる用途に用いる
 */
const PreviewScoreCard: FC = (): ReactElement => {
  const [data, setData] = useState<CardData | null>(null);
  const { location } = useReactRouter();
  const url = decodeURIComponent(location.search);
  const { sportsId, teamMasterId, teamMasterPlayerId, eventType } = qs.parse(
    url.replace("?", "")
  ) as QueryParam;

  const renderScoreCard = (
    teamMasterId: string,
    cardData: CardData | null
  ): ReactElement | null => {
    if (cardData === null) return null;
    switch (teamMasterId) {
      case TMID_RED_HURRICANES:
        return (
          <div className="generatedCard">
            <ScoreCardForRedHurricanes data={cardData} />
          </div>
        );
      case TMID_URAYASU_DROCKS:
        return (
          <div className="generatedCard">
            <ScoreCardForUrayasuDRocks data={cardData} />
          </div>
        );
      default:
        return null;
    }
  };
  const renderScoreCardMemo = useMemo(() => {
    return renderScoreCard(teamMasterId, data);
  }, [teamMasterId, data]);

  useEffect(() => {
    const load = async () => {
      const res = await makeCardData(sportsId, teamMasterId, teamMasterPlayerId, eventType);
      setData(res);
    };
    load();
  }, [sportsId, teamMasterId, teamMasterPlayerId, eventType]);
  return <Wrapper>{renderScoreCardMemo}</Wrapper>;
};

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  .generatedCard {
    width: fit-content;
    height: fit-content;
  }
`;

export default PreviewScoreCard;
