import { __awaiter, __generator } from "tslib";
import { useEffect, useState } from "react";
export var useAsyncMemo = function (factory, deps) {
    var _a = useState(), value = _a[0], setValue = _a[1];
    useEffect(function () {
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            var value;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, factory()];
                    case 1:
                        value = _a.sent();
                        setValue(value);
                        return [2 /*return*/];
                }
            });
        }); })();
    }, deps); // eslint-disable-line
    return value;
};
export var useAsyncMemoWithLoading = function (factory, deps) {
    var _a = useState(), value = _a[0], setValue = _a[1];
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    useEffect(function () {
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            var value;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, factory()];
                    case 1:
                        value = _a.sent();
                        setValue(value);
                        setLoading(false);
                        return [2 /*return*/];
                }
            });
        }); })();
    }, deps); // eslint-disable-line
    return [value, loading];
};
