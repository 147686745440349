import React, { FC, ReactElement, useEffect, useMemo, useState } from "react";
import useReactRouter from "use-react-router";
import styled from "styled-components";
import { CardData } from "components/organisms/ChangePlayerCard/types";
import qs from "qs";
import ChangePlayerCardForRedHurricanes from "components/organisms/ChangePlayerCard/RedHurricanes";
import ChangePlayerCardForUrayasuDRocks from "components/organisms/ChangePlayerCard/UrayasuDRocks";
import { fetchTeamMaster, fetchTeamMasterPlayer } from "assets/js/firebase";
import { TMID_RED_HURRICANES, TMID_URAYASU_DROCKS } from "models/teamMaster";

const makeCardData = async (
  sportsId: string,
  teamMasterId: string,
  teamMasterPlayerId: string
): Promise<CardData> => {
  const teamMaster = await fetchTeamMaster(sportsId, teamMasterId);
  const teamMasterPlayer = await fetchTeamMasterPlayer(sportsId, teamMasterId, teamMasterPlayerId);
  return {
    bgImg: teamMaster.bgImgUrlWithProGraphicsChangePlayer ?? "",
    inPlayerName: teamMasterPlayer.name,
    inPlayerNameEn: teamMasterPlayer.name_en ?? "",
    inPlayerUniformNumber: teamMasterPlayer.uniformNumber ?? "",
    inPlayerPhoto: teamMasterPlayer.photoUrlWithProGraphicsChangePlayer,
    outPlayerName: teamMasterPlayer.name,
    outPlayerNameEn: teamMasterPlayer.name_en ?? "",
    outPlayerUniformNumber: teamMasterPlayer.uniformNumber ?? "",
    outPlayerPhoto: teamMasterPlayer.photoUrlWithProGraphicsChangePlayer,
    gameName: "Sample Game",
    gameStatus: "前半XX分"
  } as CardData;
};

type QueryParam = {
  sportsId: string;
  teamMasterId: string;
  teamMasterPlayerId: string;
};

/**
 * 選手交代カードのプレビュー表示用コンポーネント
 * 管理画面上で、選手データ（画像、名前）がどのように表示されるかを確かめる用途に用いる
 */
const PreviewChangePlayerCard: FC = (): ReactElement => {
  const [data, setData] = useState<CardData | null>(null);
  const { location } = useReactRouter();
  const url = decodeURIComponent(location.search);
  const { sportsId, teamMasterId, teamMasterPlayerId } = qs.parse(
    url.replace("?", "")
  ) as QueryParam;

  const renderScoreCard = (
    teamMasterId: string,
    cardData: CardData | null
  ): ReactElement | null => {
    if (cardData === null) return null;
    switch (teamMasterId) {
      case TMID_RED_HURRICANES:
        return (
          <div className="generatedCard">
            <ChangePlayerCardForRedHurricanes data={cardData} />
          </div>
        );
      case TMID_URAYASU_DROCKS:
        return (
          <div className="generatedCard">
            <ChangePlayerCardForUrayasuDRocks data={cardData} />
          </div>
        );
      default:
        return null;
    }
  };
  const renderScoreCardMemo = useMemo(() => {
    return renderScoreCard(teamMasterId, data);
  }, [teamMasterId, data]);

  useEffect(() => {
    const load = async () => {
      const res = await makeCardData(sportsId, teamMasterId, teamMasterPlayerId);
      setData(res);
    };
    load();
  }, [sportsId, teamMasterId, teamMasterPlayerId]);
  return <Wrapper>{renderScoreCardMemo}</Wrapper>;
};

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  .generatedCard {
    width: fit-content;
    height: fit-content;
  }
`;

export default PreviewChangePlayerCard;
