import React, { FC, ReactElement } from "react";
import styled from "styled-components";
import defaultPlayerPhoto from "assets/images/change_player_card/UrayasuDRocks/default_player.png";

export type Props = {
  playerName: string;
  uniformNumber: string;
  playerPhotoUrl: string;
};

const InPlayerLayer: FC<Props> = (props: Props): ReactElement => {
  return (
    <Wrapper>
      <div className="frame">
        <div className="canvasInFrame">
          <img
            src={props.playerPhotoUrl}
            className="playerPhoto"
            onError={e => {
              e.currentTarget.src = defaultPlayerPhoto;
            }}
            alt={props.playerName}
          />
          <div className="playerDataArea">
            <div className="canvasInPlayerDataArea">
              <div className="uniformNumber">{props.uniformNumber}</div>
              <div className="playerNameWrapper">
                <div className="playerName">{props.playerName}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="inEffect"></div>
        <div className="in">IN</div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  .frame {
    width: 1000px;
    height: 1000px;
    position: absolute;
    left: 0px;
    top: 0px;
    clip-path: polygon(0 0, 43% 0, 57% 100%, 0% 100%);
    box-sizing: border-box;
  }

  .canvasInFrame {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: linear-gradient(360deg, #000000 11.98%, rgba(0, 0, 0, 0) 34.56%);
  }
  .playerPhoto {
    width: 680px;
    height: 680px;
    position: absolute;
    left: -110px;
    top: 82px;
  }
  .playerDataArea {
    width: 100%;
    height: 106px;
    position: absolute;
    left: 0px;
    top: 762px;
    background: linear-gradient(270deg, #f9d431 0.52%, #f39d3e 98.7%);
  }
  .inEffect {
    width: 680px;
    height: 680px;
    position: absolute;
    left: -110px;
    top: 82px;
    background: linear-gradient(0deg, #000000 11.98%, rgba(0, 0, 0, 0) 34.56%);
  }
  .in {
    font-family: "Alverata";
    font-style: italic;
    font-weight: 900;
    font-size: 150px;
    line-height: 175px;
    text-transform: uppercase;
    color: #f49e3f;
    position: absolute;
    bottom: 206px;
    left: 30px;
  }
  .canvasInPlayerDataArea {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .uniformNumber {
    position: absolute;
    left: 10px;
    top: 0px;
    font-family: "RobotoWebFont";
    font-style: italic;
    font-weight: 900;
    font-size: 120px;
    line-height: 141px;
    /* identical to box height */
    letter-spacing: -25px;
    text-transform: uppercase;
    color: #ffffff;
    opacity: 0.2;
  }

  .playerNameWrapper {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 500px;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0px 25px 0px 60px;
    box-sizing: border-box;
    word-wrap: break-word;
    word-break: break-all;
    .playerName {
      font-family: "RobotoWebFont";
      font-style: italic;
      font-weight: 900;
      font-size: 36px;
      line-height: 39px;
      text-transform: uppercase;
      color: #ffffff;
    }
  }
`;

export default InPlayerLayer;
