import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import media, { generateMedia } from "styled-media-query";
import firebase from "firebase";
import { Team } from "models/team";
import { Link, useLocation } from "react-router-dom";
import { Header } from "./internal/Header";
import { Footer } from "./internal/Footer";
import { League } from "models/league";
import { defaultSportsImage } from "assets/js/images";

const customMedia = generateMedia({
  verySmall: "320px"
});

export const TeamList = () => {
  const [teamList, setTeamList] = useState<Team[]>();
  const [league, setLeague] = useState<League>();
  const location = useLocation();
  // location.pathname = /sports/tournaments/${sportsId}/${leagueId}
  const res = location.pathname.split("/");
  const sportsId = res[3];
  const leagueId = res[4];

  const fetchLeagueList = useCallback(async () => {
    const qs = await firebase
      .firestore()
      .collection(`sportsData/${sportsId}/league/${leagueId}/teams`)
      .orderBy("name")
      .get();
    const teamList = qs.docs.map(doc => {
      return doc.data() as Team;
    });
    setTeamList(teamList);
  }, [leagueId, sportsId]);
  const fetchLeague = useCallback(() => {
    firebase
      .firestore()
      .collection(`sportsData/${sportsId}/league/`)
      .doc(leagueId)
      .get()
      .then(doc => setLeague(doc.data() as League));
  }, [leagueId, sportsId]);
  useEffect(() => {
    (() => {
      fetchLeagueList();
      fetchLeague();
    })();
  }, [fetchLeague, fetchLeagueList]);
  return (
    <Wrapper>
      <Header />
      <div className="title_wrapper">
        <div className="title">{league?.name}</div>
      </div>
      <div className="league_items_container">
        {teamList &&
          teamList.map(t => (
            <Link
              to={{
                pathname: `/sports/team/${sportsId}/${t.masterTeamId}`
              }}
              style={{ textDecoration: "none" }}
            >
              <div className="team_item_container">
                {t.logoUrl ? (
                  <img className="team_logo" src={t.logoUrl} alt="teamLogo" />
                ) : (
                  <div className="dummy_team_logo_wrapper">
                    <img
                      src={defaultSportsImage[sportsId]}
                      alt="teamLogo"
                      className="dummy_team_logo"
                    />
                  </div>
                )}
                <div className="teamName_wrapper">
                  <div className="teamName">{t.name}</div>
                </div>
              </div>
            </Link>
          ))}
      </div>
      <div className="footer_wrapper">
        <Footer />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-height: 100vh;
  position: relative;
  padding-bottom: 64px;
  .title_wrapper {
    text-align: center;
    margin-top: 34px;
  }
  .title {
    font-weight: 700;
    font-size: 20px;
    font-family: Noto Sans JP;
  }

  .league_items_container {
    display: grid;
    grid-gap: 40px 40px;
    padding: 10px;
    margin: auto;
    margin-top: 78px;
    margin-bottom: 50px;
    place-items: center;
    align-items: flex-start;
    ${media.greaterThan("medium")`
    width: 760px;
    grid-template-columns: repeat(4, 160px);
    `}
    ${media.between("medium", "large")`
    width: 560px;
    grid-template-columns: repeat(3, 160px);
    `}
    ${media.lessThan("medium")`
    width: 300px;
    grid-template-columns: repeat(2, 130px);
    `}
    ${customMedia.lessThan("verySmall")`
    width: 160px;
    grid-template-columns: repeat(1, 130px);
  `}
  }
  .team_item_container {
    cursor: pointer;
  }
  .teamName_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    text-align: center;
  }
  .teamName {
    color: white;
  }
  .team_logo {
    object-fit: contain;
    width: 160px;
    height: 160px;
    border-radius: 80px;
    ${media.lessThan("medium")`
    width: 130px;
    height: 130px;
  `}
  }
  .dummy_team_logo_wrapper {
    background-color: white;
    width: 160px;
    height: 160px;
    border-radius: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${media.lessThan("medium")`
    width: 130px;
    height: 130px;
  `};
  }
  .dummy_team_logo {
    width: 100px;
    height: 100px;
    ${media.lessThan("medium")`
    width: 70px;
    height: 70px;
  `};
  }
  .footer_wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    ${media.greaterThan("medium")`
    display: none
  `}
  }
`;
