import { Emoji } from "../models/cheer";

const EMOJI_TEXT = {
  "👍": "ThumbsUp",
  "🎉": "PartyPopper",
  "💯": "HundredPoints",
  "🌟": "GlowingStar",
  "😊": "Smile",
  "💪": "Muscle",
  "🏉": "RugbyBall",
  "⛄": "Snow",
  "⚽": "SoccerBall",
  "🎯": "Darts"
};

export const emoji2text = (emoji: Emoji) => {
  return EMOJI_TEXT[emoji] || Object.values(EMOJI_TEXT)[0];
};
