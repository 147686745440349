import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "reset.css";
import "./index.css";
import firebase from "firebase/app";

import FirebaseApp from "./FirebaseApp";
import App from "./App";
import firebaseConfig from "./firebase-config";
import * as serviceWorker from "./serviceWorker";
import colorTheme from "theme";
import { ThemeContext } from "contexts";
import { RecoilRoot } from "recoil";

firebase.initializeApp(firebaseConfig);
ReactDOM.render(
  <BrowserRouter>
    <RecoilRoot>
      <FirebaseApp>
        <ThemeContext.Provider value={colorTheme}>
          <App />
        </ThemeContext.Provider>
      </FirebaseApp>
    </RecoilRoot>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
