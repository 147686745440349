import { useCallback, useContext, useEffect, useRef, useState } from "react";

import { calculateTimelineData } from "assets/js/calculateTimelineData";
import { FirebaseContext } from "contexts";

import { League } from "models/league";
import { Game } from "models/game";
import { CheerComment } from "models/cheerComment";
import { GiftComment } from "models/giftComment";
import { Timeline, PeriodGamePoint } from "models/timeline";
import { useCheerGiftProducts } from "./use-cheerGiftProducts";
import { getTeamDataAndPlayer, getCheerIconNum, fetchPlaceUrl } from "assets/js/firebase";
import dayjs from "dayjs";

const MAX_COMMENT_NUM = 300;
const POLLING_INTERVAL = 500;

export const useGameDataSnapshot = ({
  sportsId,
  leagueId,
  gameId
}: {
  sportsId: string;
  leagueId: string;
  gameId: string;
}) => {
  const [gameData, setGameData] = useState<Game>();
  const gameDataUnsubscribe = useRef<() => void>();

  const [totalCheerIcon, setTotalCheerIcon] = useState<number>(0);
  const [totalHomeCheerIcon, setTotalHomeCheerIcon] = useState<number>(0);
  const [totalAwayCheerIcon, setTotalAwayCheerIcon] = useState<number>(0);
  const [tmpTotalCheerIcon, setTmpTotalCheerIcon] = useState<number>(0);
  const [tmpTotalHomeCheerIcon, setTmpTotalHomeCheerIcon] = useState<number>(0);
  const [tmpTotalAwayCheerIcon, setTmpTotalAwayCheerIcon] = useState<number>(0);

  const [cheerComments, setCheerComments] = useState<CheerComment[]>([]);
  const [cheerCommentsCount, setCheerCommentsCount] = useState<number>(0);
  const cheerCommentUnsubscribe = useRef<() => void>();
  const cheerCommentCountUnsubscribe = useRef<() => void>();

  const [cheerGiftComments, setCheerGiftComments] = useState<GiftComment[]>([]);
  const cheerGiftCommentUnsubscribe = useRef<() => void>();
  const cheerCountIntervalId = useRef<NodeJS.Timeout>();

  const [leagueData, setLeagueData] = useState<League>();
  const [timeline, setTimeline] = useState<{ [id: string]: Timeline }>();
  const [timelineData, setTimelineData] = useState<Timeline[]>();
  const [latestPoints, setLatestPoints] = useState<{ home: number; away: number }>({
    home: 0,
    away: 0
  });
  const [latestGamePoints, setLatestGamePoints] = useState<PeriodGamePoint>();

  const [placeImageUrl, setPlaceImageUrl] = useState<string>("");

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();

  const { productList, loading: giftProductLoading } = useCheerGiftProducts();
  const firebaseRef = useRef(useContext(FirebaseContext));

  const calcCheerCounts = useCallback(() => {
    if (giftProductLoading) {
      return;
    }
    let totalHomeGiftPrice = 0;
    let totalAwayGiftPrice = 0;
    cheerGiftComments
      .filter(comment => {
        if (!gameData?.cheerBattleEndTime) return true;
        return dayjs(comment.createdAt.toDate()).isBefore(gameData.cheerBattleEndTime.toDate());
      })
      .forEach((giftComment: GiftComment) => {
        const product = productList[giftComment?.productId];
        if (product) {
          if (giftComment.isHomeTeamSelect) {
            totalHomeGiftPrice += product.price;
          } else {
            totalAwayGiftPrice += product.price;
          }
        }
      });
    setTotalCheerIcon(tmpTotalCheerIcon + (totalHomeGiftPrice + totalAwayGiftPrice));
    setTotalHomeCheerIcon(tmpTotalHomeCheerIcon + Math.round(totalHomeGiftPrice));
    setTotalAwayCheerIcon(tmpTotalAwayCheerIcon + Math.round(totalAwayGiftPrice));
  }, [
    giftProductLoading,
    cheerGiftComments,
    tmpTotalCheerIcon,
    tmpTotalHomeCheerIcon,
    tmpTotalAwayCheerIcon,
    gameData?.cheerBattleEndTime,
    productList
  ]);

  const pollingCheerCount = useCallback(async ({ firestore, sportsId, leagueId, gameId }) => {
    const counts = await getCheerIconNum({
      firestore,
      sportsId,
      leagueId,
      gameId
    });
    setTmpTotalCheerIcon(counts.count);
    setTmpTotalHomeCheerIcon(counts.homeCount);
    setTmpTotalAwayCheerIcon(counts.awayCount);
  }, []);

  useEffect(() => {
    calcCheerCounts();
  }, [
    calcCheerCounts,
    tmpTotalCheerIcon,
    tmpTotalHomeCheerIcon,
    tmpTotalAwayCheerIcon,
    cheerGiftComments,
    productList
  ]);

  useEffect(() => {
    const { firestore, realtimeDb } = firebaseRef.current;
    if (!firestore) {
      throw new Error("Firestore is not initialized");
    }
    if (!realtimeDb) {
      throw new Error("Realtime DB is not initialized");
    }

    const getGameData = async () => {
      setLoading(true);
      try {
        const leagueDoc = await firestore
          .collection(`sportsData/${sportsId}/league/`)
          .doc(leagueId)
          .get();
        if (!leagueDoc.exists) {
          throw new Error("not exist league");
        }
        const leagueData = leagueDoc.data() as League;
        setLeagueData(leagueData);
        if (gameDataUnsubscribe?.current) {
          gameDataUnsubscribe.current();
        }

        const unsubscribe = firestore
          .collection(`sportsData/${sportsId}/league/${leagueId}/games`)
          .doc(gameId)
          .onSnapshot(async doc => {
            if (!doc.exists) {
              throw new Error("not exist game");
            }
            const gameData = doc.data() as Game;
            const placeUrl = await fetchPlaceUrl({
              sportsId,
              gameData
            });
            setPlaceImageUrl(placeUrl);

            if (gameData.team_awayId) {
              gameData.awayTeam = await getTeamDataAndPlayer({
                firestore,
                sportsId,
                leagueId,
                isMatchIndividual: Boolean(leagueData.isMatchIndividual),
                teamId: gameData.team_awayId
              });
            }
            if (gameData.team_homeId) {
              gameData.homeTeam = await getTeamDataAndPlayer({
                firestore,
                sportsId,
                leagueId,
                isMatchIndividual: Boolean(leagueData.isMatchIndividual),
                teamId: gameData.team_homeId
              });
            }
            setGameData({ ...gameData, id: doc.id });
            setLoading(false);
          });
        gameDataUnsubscribe.current = unsubscribe;

        realtimeDb
          .ref(`timeline/${sportsId}/${leagueId}/${gameId}`)
          .on("value", timelineSnapShot => {
            setTimeline(timelineSnapShot.val());
          });

        if (cheerCountIntervalId?.current) {
          clearInterval(cheerCountIntervalId.current);
        }
        cheerCountIntervalId.current = setInterval(
          () => pollingCheerCount({ firestore, sportsId, leagueId, gameId }),
          POLLING_INTERVAL
        );
        pollingCheerCount({ firestore, sportsId, leagueId, gameId });

        if (cheerCommentUnsubscribe?.current) {
          cheerCommentUnsubscribe.current();
        }
        if (cheerCommentCountUnsubscribe?.current) {
          cheerCommentCountUnsubscribe.current();
        }
        const unsubscribeCheerComment = firestore
          .collection(`sportsData/${sportsId}/league/${leagueId}/games/${gameId}/cheerComments`)
          .orderBy("createdAt", "desc")
          .limit(MAX_COMMENT_NUM)
          .onSnapshot(async querySnapshot => {
            const cheerComments = querySnapshot.docs
              .map(doc => ({ ...doc.data(), id: doc.id } as CheerComment))
              .filter(comment => comment.isPublic);
            setCheerComments(cheerComments);
          });
        const unsubscribeCheerCommentCount = firestore
          .collection(`sportsData/${sportsId}/league/${leagueId}/games/${gameId}/cheerCount`)
          .doc("comment")
          .onSnapshot(doc => {
            const totalCheerComments = (doc.exists && doc.data()?.count) || 0;
            setCheerCommentsCount(totalCheerComments);
          });
        cheerCommentUnsubscribe.current = unsubscribeCheerComment;
        cheerCommentCountUnsubscribe.current = unsubscribeCheerCommentCount;

        const unsubscribeCheerGiftComment = firestore
          .collection(`sportsData/${sportsId}/league/${leagueId}/games/${gameId}/giftComments`)
          .orderBy("createdAt", "desc")
          .onSnapshot(async querySnapshot => {
            const cheerGiftComments = querySnapshot.docs
              .map(doc => ({ id: doc.id, ...doc.data() } as GiftComment))
              .filter(comment => comment.isPublic);
            setCheerGiftComments(cheerGiftComments);
          });
        cheerGiftCommentUnsubscribe.current = unsubscribeCheerGiftComment;

        setError(undefined);
      } catch (err) {
        console.error(err);
        setError(err as Error);
      }
    };

    getGameData();
  }, [sportsId, leagueId, gameId, pollingCheerCount]);

  useEffect(() => {
    if (!timeline || !gameData) {
      return;
    }
    const { timelineData, latestPoints, latestGamePoints } = calculateTimelineData(
      timeline,
      sportsId,
      undefined,
      gameData?.periods?.periods
    );
    if (timelineData) {
      setTimelineData(timelineData);
    }
    setLatestPoints(latestPoints);
    setLatestGamePoints(latestGamePoints);
  }, [timeline, sportsId, gameData]);

  return {
    gameData,
    leagueData,
    timelineData,
    latestPoints,
    latestGamePoints,
    placeImageUrl,
    totalCheerIcon,
    totalHomeCheerIcon,
    totalAwayCheerIcon,
    cheerComments,
    cheerCommentsCount,
    cheerGiftComments,
    productList,
    loading,
    error
  };
};
