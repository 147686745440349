import React, { FC, ReactElement } from "react";
import styled from "styled-components";

export type Props = {
  bgImg: string;
};

/**
 * 背景レイヤー
 * mix-blend-mode を使うエフェクトの実装上、独自コンポーネントとして実装
 */
const BgLayer: FC<Props> = (props: Props): ReactElement => {
  return (
    <Wrapper bgImg={props.bgImg}>
      <div className="frameIn">
        <div className="canvasIn"></div>
      </div>
      <div className="frameOut">
        <div className="canvasOut"></div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ bgImg: string }>`
  position: relative;
  width: 100%;
  height: 100%;
  .frameIn {
    width: 1000px;
    height: 1000px;
    position: absolute;
    left: 0px;
    top: 0px;
    clip-path: polygon(0 0, 43% 0, 57% 100%, 0% 100%);
    background-image: url(${props => props.bgImg});
    .canvasIn {
      width: 100%;
      height: 100%;
      background: linear-gradient(270deg, #f9d431 -6.26%, #f39d3e 103.59%);
      mix-blend-mode: difference;
    }
  }
  .frameOut {
    width: 1000px;
    height: 1000px;
    position: absolute;
    left: 0px;
    top: 0px;
    clip-path: polygon(43% 0, 100% 0, 100% 100%, 57% 100%);
    background-image: url(${props => props.bgImg});
    .canvasOut {
      width: 100%;
      height: 100%;
      background: linear-gradient(42.11deg, #100d30 8.96%, rgba(16, 13, 48, 0) 92.89%);
      mix-blend-mode: darken;
    }
  }
`;

export default BgLayer;
