import React, { FC, ReactElement } from "react";
import { CardCanvas, Layer } from "../LayerSystem";
import { CardData } from "../types";
import GameStatusLayer from "./GameStatusLayer";
import InPlayerLayer from "./InPlayerLayer";
import OutPlayerLayer from "./OutPlayerLayer";
import LogoLayer from "./LogoLayer";
import bgImg from "assets/images/change_player_card/UrayasuDRocks/bg.png";
import SpoliveLogo from "assets/images/logo_with_produced_by.png";
import LeagueOneLogo from "assets/images/sports/rugby/league_logo/japan_rugby_league_one.png";
import EffectCloudLeftImg from "assets/images/change_player_card/UrayasuDRocks/effect_cloud_left.png";
import EffectCloudRightImg from "assets/images/change_player_card/UrayasuDRocks/effect_cloud_right.png";
import BgLayer from "./BgLayer";
import defaultPlayerPhoto from "assets/images/change_player_card/UrayasuDRocks/default_player.png";

const CHANGE_PLAYER_CARD_HEIGHT = 1000;
const CHANGE_PLAYER_CARD_WIDTH = 1000;

type Props = {
  data: CardData;
};

const ChangePlayerCardForUrayasuDRocks: FC<Props> = ({ data }): ReactElement => {
  return (
    <CardCanvas width={CHANGE_PLAYER_CARD_WIDTH} height={CHANGE_PLAYER_CARD_HEIGHT}>
      <Layer width={CHANGE_PLAYER_CARD_WIDTH} height={CHANGE_PLAYER_CARD_HEIGHT} layerProperty={0}>
        <BgLayer bgImg={bgImg} />
      </Layer>
      <Layer width={CHANGE_PLAYER_CARD_WIDTH} height={CHANGE_PLAYER_CARD_HEIGHT} layerProperty={0}>
        <InPlayerLayer
          playerName={data.inPlayerNameEn}
          uniformNumber={data.inPlayerUniformNumber}
          playerPhotoUrl={data.inPlayerPhoto ?? defaultPlayerPhoto}
        />
      </Layer>
      <Layer width={CHANGE_PLAYER_CARD_WIDTH} height={CHANGE_PLAYER_CARD_HEIGHT} layerProperty={0}>
        <OutPlayerLayer
          playerName={data.outPlayerNameEn}
          uniformNumber={data.outPlayerUniformNumber}
          playerPhotoUrl={data.outPlayerPhoto ?? defaultPlayerPhoto}
        />
      </Layer>
      <Layer width={CHANGE_PLAYER_CARD_WIDTH} height={CHANGE_PLAYER_CARD_HEIGHT} layerProperty={0}>
        <GameStatusLayer gameStatus={data.gameStatus} gameName={data.gameName} />
      </Layer>
      <Layer width={CHANGE_PLAYER_CARD_WIDTH} height={CHANGE_PLAYER_CARD_HEIGHT} layerProperty={0}>
        <LogoLayer
          logoImgUrl={SpoliveLogo}
          description="SpoLiveLogo"
          positionType="leftTop"
          leftRightPx="35"
          topBottomPx="20"
          widthPx="181"
          heightPx="64"
        />
      </Layer>
      <Layer width={CHANGE_PLAYER_CARD_WIDTH} height={CHANGE_PLAYER_CARD_HEIGHT} layerProperty={0}>
        <LogoLayer
          logoImgUrl={LeagueOneLogo}
          description="LeagueOneLogo"
          positionType="rightTop"
          leftRightPx="40"
          topBottomPx="35"
          widthPx="180"
          heightPx="90"
        />
      </Layer>
      <Layer width={CHANGE_PLAYER_CARD_WIDTH} height={CHANGE_PLAYER_CARD_HEIGHT} layerProperty={0}>
        <LogoLayer
          logoImgUrl={EffectCloudLeftImg}
          description="EffectCloudLeftImg"
          positionType="leftBottom"
          leftRightPx="0"
          topBottomPx="0"
          widthPx="160"
          heightPx="80"
        />
      </Layer>
      <Layer width={CHANGE_PLAYER_CARD_WIDTH} height={CHANGE_PLAYER_CARD_HEIGHT} layerProperty={0}>
        <LogoLayer
          logoImgUrl={EffectCloudRightImg}
          description="EffectCloudRightImg"
          positionType="rightBottom"
          leftRightPx="0"
          topBottomPx="0"
          widthPx="160"
          heightPx="80"
        />
      </Layer>
    </CardCanvas>
  );
};

export default ChangePlayerCardForUrayasuDRocks;
