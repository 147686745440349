import React, { useEffect, useMemo } from "react";
import useReactRouter from "use-react-router";
import qs from "qs";
import styled from "styled-components";
import img_logo from "assets/images/logo.png";
import img_confetti_all from "assets/images/confetti_all.png";
import img_bgFrame from "assets/images/bg_frame.png";

import img_try from "assets/images/play/try.png";
import img_goal from "assets/images/play/goal.png";
import img_point from "assets/images/play/point.png";
import img_conversiongoal from "assets/images/play/conversiongoal.png";
import img_dropgoal from "assets/images/play/dropgoal.png";
import img_penaltygoal from "assets/images/play/penaltygoal.png";
import img_penaltytry from "assets/images/play/penaltytry.png";
import img_game from "assets/images/play/nicegame.png";
import img_Touchdown from "assets/images/play/touchdown.png";
import img_ExtraPointGood from "assets/images/play/extrapointgood.png";
import img_2PTConversion from "assets/images/play/2ptconversion.png";
import img_FieldGoal from "assets/images/play/fieldgoal.png";

import { Team } from "models/team";
import { Point } from "models/timeline";

import { splashBg, sportsIconGreen } from "assets/js/sportsImages";
import { getPlayerPositionDetails, isShowLastPeriodGamePoint } from "assets/sportsData";
import { getTeamNameShort } from "assets/js/textConvert";
import setHtmlMeta from "assets/js/setHtmlMeta";
import { TeamImage } from "components/atoms/TeamImage";
import { GameContextProvider, useGameContext } from "libs/gameContext";

const playImgs: any = {
  img_try,
  img_goal,
  img_point,
  img_conversiongoal,
  img_dropgoal,
  img_penaltygoal,
  img_penaltytry,
  img_game,
  img_Touchdown,
  img_ExtraPointGood,
  img_2PTConversion,
  img_FieldGoal
};

const viewportSize = 1200;

const NicePlay: React.FC = () => {
  const {
    sportsId,
    gameData,
    loading,
    error,
    latestPoints,
    latestGamePoints,
    placeImageUrl,
    specifiedPlay,
    locale,
    homeTeamMaster,
    awayTeamMaster
  } = useGameContext();
  const isJa = locale === "ja";

  const isHomePlay = specifiedPlay?.player?.hasOwnProperty("home");
  const playTeamData = useMemo(() => {
    if (!gameData) return null;
    return gameData[isHomePlay ? "homeTeam" : "awayTeam"] as Team;
  }, [isHomePlay, gameData]);

  const playerId = useMemo(() => {
    const item = specifiedPlay?.player?.[isHomePlay ? "home" : "away"];
    if (typeof item === "string") {
      return item;
    }
    if (item && "goal" in item) {
      return item.goal as string;
    }
    return item as string;
  }, [isHomePlay, specifiedPlay?.player]);

  const assistPlayerInfo = useMemo(() => {
    const item = specifiedPlay?.player?.[isHomePlay ? "home" : "away"];
    if (typeof item === "string") {
      return null;
    }
    let playerId = "";
    if (item && "assist" in item) {
      playerId = item.assist as string;
    } else {
      return null;
    }
    const assistPlayerData = playTeamData?.players?.[playerId];
    if (!assistPlayerData) return null;
    const name = (!isJa && assistPlayerData.name_en) || assistPlayerData.name;
    const number = assistPlayerData?.uniformNumber ? `#${assistPlayerData.uniformNumber} ` : "";
    const positionList = getPlayerPositionDetails(sportsId, locale, assistPlayerData?.positionList);
    const positionName = positionList.length > 0 ? `${positionList[0].name} ` : "";
    const prefix = isJa ? "アシスト：" : "Assist: ";
    return `${prefix}${positionName}${number}${name}`;
  }, [isHomePlay, isJa, locale, playTeamData?.players, specifiedPlay?.player, sportsId]);

  useEffect(() => {
    setHtmlMeta({ viewport: "width=" + viewportSize });
  }, []);

  const homeTeamNameJa = useMemo(
    () =>
      gameData?.homeTeam?.companyName ||
      gameData?.homeTeam?.name ||
      gameData?.team_homeName_ifEmptyTeamId ||
      "",
    [gameData]
  );

  const homeTeamNameEn = useMemo(
    () =>
      gameData?.homeTeam?.companyName_en ||
      gameData?.homeTeam?.name_en ||
      gameData?.team_homeName_ifEmptyTeamId_en ||
      homeTeamNameJa ||
      "",
    [
      gameData?.homeTeam?.companyName_en,
      gameData?.homeTeam?.name_en,
      gameData?.team_homeName_ifEmptyTeamId_en,
      homeTeamNameJa
    ]
  );

  const awayTeamNameJa = useMemo(
    () =>
      gameData?.awayTeam?.companyName ||
      gameData?.awayTeam?.name ||
      gameData?.team_awayName_ifEmptyTeamId ||
      "",
    [gameData]
  );

  const awayTeamNameEn = useMemo(
    () =>
      gameData?.awayTeam?.companyName_en ||
      gameData?.awayTeam?.name_en ||
      gameData?.team_awayName_ifEmptyTeamId_en ||
      awayTeamNameJa ||
      "",
    [
      gameData?.awayTeam?.companyName_en,
      gameData?.awayTeam?.name_en,
      gameData?.team_awayName_ifEmptyTeamId_en,
      awayTeamNameJa
    ]
  );

  if (loading) {
    return null;
  }

  if (!gameData || loading || !sportsId || !splashBg[sportsId] || error || !specifiedPlay) {
    console.log(checkDataExists());
    return <Wrapper bgimg={"/ogimage.png"}></Wrapper>;
  }

  const playerData = playTeamData?.players?.[playerId];
  const playerImageUrl = playerData?.photoUrl || playTeamData?.logoUrl;

  let uniformNumber = playerData?.uniformNumber || "";
  if (specifiedPlay?.uniformNumber) {
    uniformNumber = "#" + specifiedPlay.uniformNumber;
  }
  if (uniformNumber) {
    uniformNumber += " ";
  }
  let { position = "", position_en = "" } = specifiedPlay;
  if (position || position_en) {
    position = ((!isJa && position_en) || position) + " ";
  }

  const playerText =
    getTeamNameShort(isJa, isHomePlay ? true : false, gameData) +
    (playerData
      ? `  ${position + uniformNumber}${(!isJa && playerData?.name_en) || playerData?.name}`
      : "");

  const playType = "img_" + specifiedPlay?.type;
  const playTextImage = playImgs[playType] ? playImgs[playType] : playImgs.img_goal;

  const leagueText = (!isJa && gameData?.info_leagueText_en) || gameData?.info_leagueText;
  const placeName = (!isJa && gameData?.info_placeName_en) || gameData?.info_placeName;

  //指定のプレー内容が見つからなかったら試合シェアに遷移
  if (!loading && !specifiedPlay) {
    document.location.href = document.location.href.replace("/_splash/play", "/_splash/game");
  }

  //テニスなどの場合にはピリオドごとの点数を表示
  let displayHomePoint = latestPoints.home;
  let displayAwayPoint = latestPoints.away;
  if (
    isShowLastPeriodGamePoint(sportsId) &&
    latestGamePoints &&
    specifiedPlay?.periodId &&
    latestGamePoints?.[specifiedPlay.periodId]
  ) {
    const latestGamePointSet = latestGamePoints[specifiedPlay.periodId] as Point;
    displayHomePoint = latestGamePointSet.home;
    displayAwayPoint = latestGamePointSet.away;
  }

  return (
    <Wrapper bgimg={placeImageUrl || splashBg[sportsId]} className="p-playSplash">
      <div className="p-playSplash_bgFrame" />
      <img className="p-playSplash_confetti" alt="confetti" src={img_confetti_all} />
      <div className="p-playSplash_leagueText">
        <p>
          {leagueText}
          <br />
          {placeName}
        </p>
      </div>
      <div className="p-playSplash_playTeamImgWrapper">
        {playerImageUrl ? (
          <img
            className="p-playSplash_teamImg"
            alt={"point team logo"}
            data-isplayer={Boolean(playerData?.photoUrl)}
            src={playerImageUrl || ""}
          />
        ) : (
          <img
            className="p-playSplash_teamImg--blank"
            alt={"point team logo"}
            src={sportsIconGreen[sportsId]}
          />
        )}
      </div>
      <img className="p-playSplash_playTextImage" src={playTextImage} alt="GOAL!" />
      <div className="p-playSplash_playerTextWrapper">
        <p className="p-playSplash_playerText">{playerText}</p>
      </div>
      {assistPlayerInfo && (
        <div className="p-playSplash_assistPlayerTextWrapper">
          <p className="p-playSplash_assistPlayerText">{assistPlayerInfo}</p>
        </div>
      )}

      <div className="p-playSplash_teamPointArea">
        <div className="p-playSplash_teamArea" data-ishome="true">
          <div className="p-playSplash_teamImgWrapper">
            <TeamImage
              diameter={60}
              sportsId={sportsId}
              name={homeTeamNameJa}
              name_en={homeTeamNameEn}
              logoUrl={gameData?.homeTeam?.logoUrl}
              color={homeTeamMaster?.color}
            />
          </div>
        </div>
        <div className="p-playSplash_pointArea">
          <span className="p-playSplash_pointNumber" data-point={isHomePlay === true}>
            {displayHomePoint}
          </span>
          <span className="p-playSplash_pointHyphen">ー</span>
          <span className="p-playSplash_pointNumber" data-point={isHomePlay === false}>
            {displayAwayPoint}
          </span>
        </div>
        <div className="p-playSplash_teamArea">
          <div className="p-playSplash_teamImgWrapper">
            <TeamImage
              diameter={60}
              sportsId={sportsId}
              name={awayTeamNameJa}
              name_en={awayTeamNameEn}
              logoUrl={gameData?.awayTeam?.logoUrl}
              color={awayTeamMaster?.color}
            />
          </div>
        </div>
      </div>
      <img className="p-playSplash_logo" alt="spolive" src={img_logo} />
    </Wrapper>
  );
  function checkDataExists() {
    if (!gameData) {
      return "no GameData";
    }
    if (loading) {
      return "now loading";
    }
    if (!sportsId) {
      return "no sportsId";
    }
    if (!splashBg[sportsId]) {
      return "no splashBg";
    }
    if (error) {
      return "error";
    }
    if (!specifiedPlay) {
      return "no specifiedPlay";
    }
  }
};
const NicePlayWrap = () => {
  const { location } = useReactRouter();
  const url = decodeURIComponent(location.search);
  const {
    sportsId,
    leagueId,
    gameId,
    locale = "ja",
    timelineId
  } = qs.parse(url.replace("?", "")) as { [key: string]: string };
  return (
    <GameContextProvider
      sportsId={sportsId}
      gameId={gameId}
      leagueId={leagueId}
      locale={locale}
      timelineId={timelineId}
    >
      <NicePlay />
    </GameContextProvider>
  );
};

export default NicePlayWrap;

const Wrapper = styled.div<{ bgimg: string }>`
  position: relative;
  width: ${viewportSize}px;
  height: ${viewportSize * 0.525}px;
  margin: 0 auto;
  padding: 0 14px 51px;
  box-sizing: border-box;
  background-image: url(${({ bgimg }) => bgimg});
  background-size: cover;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: flex-end;
  .p-playSplash {
    &_bgFrame {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url(${img_bgFrame});
      background-color: rgba(0, 0, 0, 0.5);
    }
    &_confetti {
      position: absolute;
      width: 1189px;
      height: 579px;
      top: 26px;
      left: 6px;
    }
    &_leagueText {
      position: absolute;
      top: 45px;
      right: 80px;
      text-align: right;
      font-size: 28px;
      font-weight: bold;
      max-width: 380px;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      p {
        text-align: center;
        word-break: break-word;
        text-shadow: 0 0 4px #000;
      }
    }

    &_playTeamImgWrapper {
      position: relative;
      background-color: #fff;
      width: 244px;
      height: 244px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      border: 4px #fff solid;
      overflow: hidden;
      [data-isplayer="true"] {
        width: 100%;
        height: 100%;
      }
    }
    &_playTextImage {
      position: relative;
      display: block;
      height: 74px;
      margin: 20px auto 20px;
    }

    &_playerTextWrapper {
      position: relative;
      background-color: rgba(0, 0, 0, 0.15);
      height: 55px;
      border-radius: 30px;
      padding: 0 35px;
    }
    &_playerText {
      line-height: 60px;
      font-size: 30px;
      font-weight: bold;
    }
    &_assistPlayerTextWrapper {
      position: relative;
      background-color: rgba(0, 0, 0, 0.15);
      height: 20px;
      border-radius: 30px;
      padding: 0 35px;
    }
    &_assistPlayerText {
      line-height: 30px;
      font-size: 24px;
      font-weight: "400";
    }

    &_teamPointArea {
      position: relative;
      display: flex;
      justify-content: center;
      margin: 25px auto 0px;
      width: 100%;
    }
    &_pointArea {
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-size: 46px;
      font-weight: bold;
      width: 256px;
      height: 60px;
      text-align: center;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &_pointVs {
      font-size: 46px;
      font-weight: bold;
      width: 256px;
      height: 60px;
    }
    &_pointNumber {
      flex: 1;
      font-family: "Oswald", sans-serif;
      &[data-point="true"] {
        color: #fafc9c;
      }
    }
    &_pointHyphen {
      flex: 0;
      font-size: 32px;
    }
    &_teamArea {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      flex: 1;
      height: 60px;
      &[data-ishome="true"] {
        align-items: flex-end;
      }
    }
    &_teamImgWrapper {
      display: block;
      background-color: #fff;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }
    &_teamImg {
      width: 100%;
      height: 100%;
      object-fit: contain;
      &--blank {
        width: 70%;
        height: 70%;
      }
    }
    &_teamName {
      width: 400px;
      overflow: hidden;
      font-size: 34px;
      text-overflow: ellipsis;
      font-weight: bold;
      white-space: nowrap;
    }
    &_logo {
      position: absolute;
      width: 170px;
      height: 48px;
      top: 70px;
      left: 70px;
    }
  }
`;
