import { useWindowSize } from "./use-windowSize";

const CANVAS_WIDTH = 1920; //figmaでの横幅
const CANVAS_ASPECT = 16 / 9;
const CANVAS_HEIGHT = CANVAS_WIDTH / CANVAS_ASPECT;

export const useScale = () => {
  const { width, height } = useWindowSize();
  const windowAspect = width / height;
  const isBaseHeight = windowAspect > CANVAS_ASPECT;
  const scale = isBaseHeight ? height / CANVAS_HEIGHT : width / CANVAS_WIDTH;

  const horizontalMargin = (width - CANVAS_WIDTH * scale) / scale / 2;

  const widthRatio = width / (CANVAS_WIDTH * scale);

  return { scale, horizontalMargin, widthRatio };
};
