import React, { FC, ReactElement } from "react";
import styled from "styled-components";
import defaultPlayerPhoto from "assets/images/change_player_card/UrayasuDRocks/default_player.png";

export type Props = {
  playerName: string;
  uniformNumber: string;
  playerPhotoUrl: string;
};

const OutPlayerLayer: FC<Props> = (props: Props): ReactElement => {
  return (
    <Wrapper>
      <div className="frame">
        <div className="canvasInFrame">
          <img
            src={props.playerPhotoUrl}
            className="playerPhoto"
            onError={e => {
              e.currentTarget.src = defaultPlayerPhoto;
            }}
            alt={props.playerName}
          />
        </div>
        <div className="playerDataArea">
          <div className="canvasInPlayerDataArea">
            <div className="uniformNumber">{props.uniformNumber}</div>
            <div className="playerNameWrapper">
              <div className="playerName">{props.playerName}</div>
            </div>
          </div>
        </div>
        <div className="outEffect"></div>
        <div className="out">OUT</div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  .frame {
    width: 1000px;
    height: 1000px;
    position: absolute;
    left: 0px;
    top: 0px;
    clip-path: polygon(43% 0, 100% 0, 100% 100%, 57% 100%);
  }
  .canvasInFrame {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: linear-gradient(360deg, #000000 11.98%, rgba(0, 0, 0, 0) 34.56%);
  }
  .playerPhoto {
    width: 680px;
    height: 680px;
    position: absolute;
    left: 430px;
    top: 82px;
  }
  .outEffect {
    width: 680px;
    height: 680px;
    position: absolute;
    left: 430px;
    top: 82px;
    background: linear-gradient(0deg, #000000 11.98%, rgba(0, 0, 0, 0) 34.56%);
  }
  .playerDataArea {
    width: 100%;
    height: 106px;
    position: absolute;
    right: 0px;
    top: 762px;
    background-color: white;
  }
  .out {
    font-family: "Alverata";
    font-style: italic;
    font-weight: 900;
    font-size: 150px;
    line-height: 175px;
    text-transform: uppercase;
    position: absolute;
    bottom: 206px;
    right: 30px;
    color: #ffffff;
    box-sizing: border-box;
  }

  .canvasInPlayerDataArea {
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
  }
  .uniformNumber {
    position: absolute;
    right: 35px;
    top: 0px;
    font-family: "RobotoWebFont";
    font-style: italic;
    font-weight: 900;
    font-size: 120px;
    line-height: 141px;
    /* identical to box height */
    box-sizing: border-box;
    letter-spacing: -10px;
    text-transform: uppercase;
    color: #1e1f3c;
    opacity: 0.2;
  }

  .playerNameWrapper {
    position: absolute;
    left: 500px;
    top: 0px;
    width: 500px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 25px 0px 60px;
    box-sizing: border-box;
    word-break: break-all;

    .playerName {
      width: 100%;
      font-family: "RobotoWebFont";
      font-style: italic;
      font-weight: 900;
      font-size: 36px;
      line-height: 39px;
      text-align: end;
      text-transform: uppercase;
      color: #141232;
    }
  }
`;

export default OutPlayerLayer;
