import React, { FC, ReactElement } from "react";
import InPlayerLayer from "./InPlayerLayer";
import OutPlayerLayer from "./OutPlayerLayer";
import GameStatusLayer from "./GameStatusLayer";
import GameNameLayer from "./GameNameLayer";
import BgLayer from "../BgLayer";
import { CardCanvas, Layer } from "../LayerSystem";
import EffectLayer from "./EffectLayer";
import LogoLayer from "./LogoLayer";
import LeagueLogoLayer from "./LeagueLogoLayer";
import { CardData } from "../types";

export const CHANGE_PLAYER_CARD_HEIGHT = 630;
export const CHANGE_PLAYER_CARD_WIDTH = 1200;

type Props = {
  data: CardData;
};

const ChangePlayerCardForRedHurricanes: FC<Props> = ({ data }): ReactElement => {
  return (
    <CardCanvas width={CHANGE_PLAYER_CARD_WIDTH} height={CHANGE_PLAYER_CARD_HEIGHT}>
      <Layer width={CHANGE_PLAYER_CARD_WIDTH} height={CHANGE_PLAYER_CARD_HEIGHT} layerProperty={1}>
        <BgLayer bgImg={data.bgImg} />
      </Layer>
      <Layer width={CHANGE_PLAYER_CARD_WIDTH} height={CHANGE_PLAYER_CARD_HEIGHT} layerProperty={3}>
        <InPlayerLayer
          playerName={data.inPlayerName}
          playerNameEn={data.inPlayerNameEn}
          uniformNumber={data.inPlayerUniformNumber}
          playerPhoto={data.inPlayerPhoto}
        />
      </Layer>
      <Layer width={CHANGE_PLAYER_CARD_WIDTH} height={CHANGE_PLAYER_CARD_HEIGHT} layerProperty={2}>
        <OutPlayerLayer
          playerName={data.outPlayerName}
          playerNameEn={data.outPlayerNameEn}
          uniformNumber={data.outPlayerUniformNumber}
          playerPhoto={data.outPlayerPhoto}
        />
      </Layer>
      <Layer width={CHANGE_PLAYER_CARD_WIDTH} height={CHANGE_PLAYER_CARD_HEIGHT} layerProperty={4}>
        <GameStatusLayer gameStatus={data.gameStatus} />
      </Layer>
      <Layer width={CHANGE_PLAYER_CARD_WIDTH} height={CHANGE_PLAYER_CARD_HEIGHT} layerProperty={4}>
        <GameNameLayer gameName={data.gameName} />
      </Layer>
      <Layer width={CHANGE_PLAYER_CARD_WIDTH} height={CHANGE_PLAYER_CARD_HEIGHT} layerProperty={4}>
        <EffectLayer />
      </Layer>
      <Layer width={CHANGE_PLAYER_CARD_WIDTH} height={CHANGE_PLAYER_CARD_HEIGHT} layerProperty={4}>
        <LogoLayer />
      </Layer>
      <Layer width={CHANGE_PLAYER_CARD_WIDTH} height={CHANGE_PLAYER_CARD_HEIGHT} layerProperty={4}>
        <LeagueLogoLayer />
      </Layer>
    </CardCanvas>
  );
};

export default ChangePlayerCardForRedHurricanes;
