import React, { FC, ReactElement } from "react";
import styled from "styled-components";

type Props = {
  gameStatus: string;
};

const GameStatusLayer: FC<Props> = ({ gameStatus }): ReactElement => {
  return (
    <Wrapper>
      <div className="gameStatusWrapper">
        <div className="gameStatus">{gameStatus}</div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  .gameStatusWrapper {
    position: absolute;
    top: 417px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .gameStatus {
      width: 160px;
      height: 54px;
      display: grid;
      place-items: center;
      background-color: black;
      font-family: Noto Sans JP;
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 100%;
      text-align: center;
      color: #ffffff;
    }
  }
`;

export default GameStatusLayer;
