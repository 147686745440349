import React, { FC, ReactElement } from "react";
import HomeAwaySportsCard from "./HomeAwaySportsCard";
import MultiTeamSportsCard from "./MultiTeamSportsCard";

type Props = {
  sportsId: string;
  leagueId: string;
  gameId: string;
  uid?: string;
  teamId?: string;
  locale: "ja" | "en";
};

const CheerBattleResultCard: FC<Props> = ({
  sportsId,
  leagueId,
  gameId,
  uid,
  teamId,
  locale
}): ReactElement => {
  const cardRoute = (sportsId: string): ReactElement => {
    switch (sportsId) {
      case "motorsports":
        return (
          <MultiTeamSportsCard
            sportsId={sportsId}
            leagueId={leagueId}
            gameId={gameId}
            teamId={teamId}
            uid={uid}
            locale={locale}
          />
        );
      default:
        return (
          <HomeAwaySportsCard
            sportsId={sportsId}
            leagueId={leagueId}
            gameId={gameId}
            locale={locale}
          />
        );
    }
  };
  return cardRoute(sportsId);
};

export default CheerBattleResultCard;
