import { fetchGame, fetchPlaceUrl } from "assets/js/firebase";
import { sortBy } from "lodash";
import setHtmlMeta from "assets/js/setHtmlMeta";
import dayjs from "dayjs";
import { Stage } from "models/game";
import React, { FC, ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import BgLayer from "./BgLayer";
import DataLayer from "./DataLayer";
import { CardCanvas, Layer } from "./LayerSystem";
import { splashBg } from "assets/js/sportsImages";

const CARD_HEIGHT = 675;
const CARD_WIDTH = 1200;

type Props = {
  sportsId: string;
  leagueId: string;
  gameId: string;
  locale: "ja" | "en";
};

type CardData = {
  gameName: string;
  stageName: string;
  gameDate: string;
  gamePlace: string;
  bgImg: string;
};

const makeGameDate = (date: Date | undefined): string => {
  if (!date) return "";
  return dayjs(date).format("YYYY.MM.DD hh:mm〜");
};

/**
 * - ステージ数が3以下まで
 * ${firstStageName}・${secondStageName}・${thirdStageName}
 * - ステージ数が4以上
 * ${firstStageName}〜${lastStageName}
 *
 * 文字省略
 * - 3つ以下：6文字以上超えた部分だけ省略
 * - 4つ以上8文字以上超えた部分だけ省略
 */
const makeStageName = (stages: Stage[] | undefined): string => {
  let displayStageName = "";
  const targetStages = stages?.filter(val => val.level === 1); // top階層の試合を対象にする
  if (!targetStages) return displayStageName;
  // order の小さい順： アプリの表示順に並べ替える
  const sortedTargetStages = sortBy(targetStages, "order");
  if (sortedTargetStages.length <= 3) {
    sortedTargetStages.forEach((stage, idx, copyStages) => {
      displayStageName += formatStageName(stage.name, 6);
      if (copyStages.length > 1 && idx !== copyStages.length - 1) {
        displayStageName += "・";
      }
    });
  } else {
    const firstStageName = formatStageName(sortedTargetStages[0].name, 8);
    const lastStageName = formatStageName(
      sortedTargetStages[sortedTargetStages.length - 1].name,
      8
    );
    displayStageName += firstStageName + "〜" + lastStageName;
  }
  return displayStageName;
};

const formatStageName = (input: string, maxLength: number): string => {
  if (input.length <= maxLength) return input;
  return input.slice(0, maxLength - 1) + "…";
};

const formatGameName = (
  nameJa: string | null,
  nameEn: string | undefined | null,
  locale: "ja" | "en"
): string => {
  if (locale === "ja") return nameJa ?? "";
  return nameEn ? nameEn : nameJa ?? "";
};

const formatGamePlace = (
  nameJa: string | null,
  nameEn: string | undefined | null,
  locale: "ja" | "en"
): string => {
  if (locale === "ja") return nameJa ?? "";
  return nameEn ? nameEn : nameJa ?? "";
};

const makeCardData = async (
  sportsId: string,
  leagueId: string,
  gameId: string,
  locale: "ja" | "en"
): Promise<CardData> => {
  return new Promise<CardData>(async (resolve, reject) => {
    const game = await fetchGame(sportsId, leagueId, gameId);
    if (game === null) {
      return reject(new Error(`not found game, gameId: ${gameId}`));
    }
    const gameName = formatGameName(game.info_leagueText, game.info_leagueText_en, locale);
    const gamePlace = formatGamePlace(game.info_placeName, game.info_placeName_en, locale);
    const bgImg = await fetchPlaceUrl({ sportsId: sportsId, gameData: game });
    resolve({
      gameName: gameName,
      stageName: makeStageName(game.stageList),
      gameDate: makeGameDate(game.info_datetime?.toDate()),
      gamePlace: gamePlace,
      bgImg: bgImg ? bgImg : splashBg[sportsId]
    });
  });
};

export const MultiTeamSportsCard: FC<Props> = ({
  sportsId,
  leagueId,
  gameId,
  locale
}): ReactElement => {
  const [cardData, setCardData] = useState<CardData | null>(null);
  useEffect(() => {
    const load = async (): Promise<void> => {
      makeCardData(sportsId, leagueId, gameId, locale)
        .then(cardData => {
          setCardData(cardData);
        })
        .catch(err => {
          if (err instanceof Error) {
            console.error(err.message);
          } else {
            console.error("unknown error");
          }
        });
    };
    load();
  }, [sportsId, leagueId, gameId, locale]);
  useEffect(() => {
    setHtmlMeta({ viewport: "width=" + CARD_WIDTH });
  }, []);
  return (
    <Wrapper>
      {cardData && (
        <div className="p-gameSplash">
          <div className="card">
            <CardCanvas width={CARD_WIDTH} height={CARD_HEIGHT}>
              <Layer width={CARD_WIDTH} height={CARD_HEIGHT} layerProperty={1}>
                <BgLayer bgImg={cardData.bgImg} />
              </Layer>
              <Layer width={CARD_WIDTH} height={CARD_HEIGHT} layerProperty={1}>
                <DataLayer
                  gameName={cardData.gameName}
                  stageName={cardData.stageName}
                  gameDate={cardData.gameDate}
                  gamePlace={cardData.gamePlace}
                />
              </Layer>
            </CardCanvas>
          </div>
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div``;
