import React, { FC, ReactElement } from "react";
import { CardCanvas, Layer } from "../LayerSystem";
import { CardData } from "../types";
import SpoliveLogo from "assets/images/logo_with_produced_by.png";
import LeagueOneLogo from "assets/images/sports/rugby/league_logo/japan_rugby_league_one.png";
import bgImg from "assets/images/score_card/UrayasuDRocks/bg.png";
import EffectCloudLeftImg from "assets/images/score_card/UrayasuDRocks/effect_cloud_left.png";
import EffectCloudRightImg from "assets/images/score_card/UrayasuDRocks/effect_cloud_right.png";
import BgLayer from "../BgLayer";
import LogoLayer from "./LogoLayer";
import GameStatusLayer from "./GameStatusLayer";
import PlayerDataLayer from "./PlayerDataLayer";
import EventDataLayer from "./EventDataLayer";
import defaultPlayerPhoto from "assets/images/score_card/UrayasuDRocks/default_player.png";

const CARD_HEIGHT = 1000;
const CARD_WIDTH = 1000;

type Props = {
  data: CardData;
};

const ScoreCardForUrayasuDRocks: FC<Props> = ({ data }): ReactElement => {
  return (
    <CardCanvas width={CARD_WIDTH} height={CARD_HEIGHT}>
      <Layer width={CARD_WIDTH} height={CARD_HEIGHT} layerProperty={0}>
        <BgLayer src={bgImg} />
      </Layer>
      <Layer width={CARD_WIDTH} height={CARD_HEIGHT} layerProperty={0}>
        <PlayerDataLayer
          playerNamePrimary={data.playerNameEn}
          playerNameSecondary={data.playerName}
          position={data.playerPosition}
          playerPhotoUrl={data.playerPhoto ?? defaultPlayerPhoto}
        />
      </Layer>
      <Layer width={CARD_WIDTH} height={CARD_HEIGHT} layerProperty={0}>
        <EventDataLayer eventType={data.eventType} />
      </Layer>
      <Layer width={CARD_WIDTH} height={CARD_HEIGHT} layerProperty={0}>
        <GameStatusLayer
          homeTeamScore={data.homeScore}
          awayTeamScore={data.awayScore}
          homeTeamLogoUrl={data.homeTeamIcon}
          awayTeamLogoUrl={data.awayTeamIcon}
          gameStatus={data.gameStatus}
          gameName={data.gameName}
          teamPosition={data.teamPosition}
        />
      </Layer>
      <Layer width={CARD_WIDTH} height={CARD_HEIGHT} layerProperty={0}>
        <LogoLayer
          logoImgUrl={SpoliveLogo}
          description="SpoLiveLogo"
          positionType="leftTop"
          leftRightPx="35"
          topBottomPx="20"
          widthPx="181"
          heightPx="64"
        />
      </Layer>
      <Layer width={CARD_WIDTH} height={CARD_HEIGHT} layerProperty={0}>
        <LogoLayer
          logoImgUrl={LeagueOneLogo}
          description="LeagueOneLogo"
          positionType="rightTop"
          leftRightPx="25"
          topBottomPx="35"
          widthPx="180"
          heightPx="90"
        />
      </Layer>
      <Layer width={CARD_WIDTH} height={CARD_HEIGHT} layerProperty={0}>
        <LogoLayer
          logoImgUrl={EffectCloudLeftImg}
          description="EffectCloudLeftImg"
          positionType="leftBottom"
          leftRightPx="0"
          topBottomPx="0"
          widthPx="160"
          heightPx="80"
        />
      </Layer>
      <Layer width={CARD_WIDTH} height={CARD_HEIGHT} layerProperty={0}>
        <LogoLayer
          logoImgUrl={EffectCloudRightImg}
          description="EffectCloudRightImg"
          positionType="rightBottom"
          leftRightPx="0"
          topBottomPx="0"
          widthPx="160"
          heightPx="80"
        />
      </Layer>
    </CardCanvas>
  );
};
export default ScoreCardForUrayasuDRocks;
