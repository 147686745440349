import React, { FC, ReactElement } from "react";
import styled from "styled-components";

type Props = {
  bgImg: string;
};

const BgLayer: FC<Props> = ({ bgImg }): ReactElement => {
  return (
    <Wrapper>
      <img src={bgImg} className="bgImg" alt="bgImg" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  .bgImg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
  }
`;

export default BgLayer;
