type Prop = {
  url?: string;
  title?: string;
  description?: string;
  imageUrl?: string;
  viewport?: string;
};

type MetaProp = {
  id: string;
  content: string;
  property?: string;
  name?: string;
};

const setMeta = ({ id, content, property, name }: MetaProp) => {
  const element = document.getElementById(id) as HTMLMetaElement;
  if (element) {
    element.setAttribute("content", content);
  } else {
    const head = document.getElementsByTagName("head")[0];
    const meta = document.createElement("meta");
    if (property) {
      meta.setAttribute("property", property);
    }
    if (name) {
      meta.setAttribute("name", name);
    }
    meta.setAttribute("content", content);
    head.appendChild(meta);
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ url, imageUrl, viewport, title, description }: Prop) => {
  if (url) {
    setMeta({ id: "meta-ogurl", content: url });
  }

  if (title) {
    setMeta({ id: "meta-ogtitle", content: title, property: "og:title" });
    setMeta({ id: "meta-twittertitle", content: title, name: "twitter:title" });
    (document.getElementById("meta-title") as HTMLMetaElement).innerHTML = title;
  }
  if (description) {
    setMeta({ id: "meta-description", content: description });
    setMeta({ id: "meta-ogdescription", content: description });
    setMeta({ id: "meta-twitterdescription", content: description });
  }

  if (imageUrl) {
    setMeta({ id: "meta-twitterimage", content: imageUrl });
    setMeta({ id: "meta-ogimage", content: imageUrl });
  }

  if (viewport) {
    setMeta({ id: "meta-viewport", content: viewport });
  }
};
