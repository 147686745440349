import { SportsDic } from "models/sportsDic";

export const basketball: SportsDic = {
  playerHasUniformNumber: true,
  hasUniformNumber: true,
  hasPosition: true,
  isFixedPlayerPositionNum: false,
  teamImgIsCircle: false,
  startingMemberNum: 5,
  reserveMemberNum: 20,
  isCountDownTime: true,
  isPeriodFinish: true,
  isDisplaySeconds: true,
  statTypes: [
    {
      key: "2pt",
      name: "2PT成功",
      name_en: "Made 2PT",
      name_short: "2PT成功",
      name_short_en: "+Made 2PT",
      point: 2,
      gamePoint: 2,
      iconText: "+2"
    },
    {
      key: "2MISSED",
      name: "シュート失敗",
      name_en: "Missed shoot",
      name_short: "シュート失敗",
      name_short_en: "Missed shoot",
      color: "gray",
      iconText: "M2PT"
    },
    {
      key: "3pt",
      name: "3PT成功",
      name_en: "Made 3PT",
      name_short: "3PT成功",
      name_short_en: "Made 3PT",
      point: 3,
      gamePoint: 3,
      iconText: "+3"
    },
    {
      key: "3MISSED",
      name: "3PTシュート失敗",
      name_en: "Missed 3PT shoot ",
      name_short: "3PTシュート失敗",
      name_short_en: "Missed 3PT shoot ",
      color: "gray",
      iconText: "M3PT"
    },
    {
      key: "FTMade",
      name: "フリースロー成功",
      name_en: "Made free throw",
      name_short: "フリースロー成功",
      name_short_en: "Made free throw",
      point: 1,
      gamePoint: 1,
      iconText: "+1"
    },
    {
      key: "FTMissed",
      name: "フリースロー失敗",
      name_en: "Missed free throw",
      name_short: "フリースロー失敗",
      name_short_en: "Missed free throw",
      iconText: "MFT",
      color: "gray"
    },
    {
      key: "Rebound",
      name: "リバウンド",
      name_en: "Rebound",
      name_short: "リバウンド",
      name_short_en: "Rebound",
      color: "black",
      iconText: "R"
    },
    {
      key: "Foul",
      name: "ファウル",
      name_en: "Foul",
      name_short: "ファウル",
      name_short_en: "Foul",
      iconText: "F",
      color: "gray"
    },
    {
      key: "change",
      name: "交代",
      name_en: "Substitution",
      name_short: "交代",
      name_short_en: "Substitution",
      iconText: "change",
      isHiddenStat: true
    },
    {
      key: "Ejectcard",
      name: "退場",
      name_en: "Eject",
      name_short: "退場",
      name_short_en: "Eject",
      isHiddenStat: true
    },
    {
      key: "timeout",
      name: "タイムアウト",
      name_en: "TimeOut",
      iconText: "TO",
      color: "black",
      isNoPlayer: true,
      isHiddenStat: true
    },
    {
      key: "officialTimeout",
      name: "オフィシャルタイムアウト",
      name_en: "Official TimeOut",
      iconText: "OTO",
      color: "black",
      isNoPlayer: true,
      isHiddenStat: true
    }
  ],
  infringementNames: [],
  initialPeriod: [
    {
      id: "firstQuarter",
      name: "1Q",
      name_en: "1Q",
      nameScore: "1Q",
      nameScore_en: "1Q",
      order: 1
    },
    {
      id: "secondQuarter",
      name: "2Q",
      name_en: "2Q",
      nameScore: "2Q",
      nameScore_en: "2Q",
      order: 2
    },
    {
      id: "thirdQuarter",
      name: "3Q",
      name_en: "3Q",
      nameScore: "3Q",
      nameScore_en: "3Q",
      order: 3
    },
    {
      id: "fourthQuarter",
      name: "4Q",
      name_en: "4Q",
      nameScore: "4Q",
      nameScore_en: "4Q",
      order: 4
    },
    {
      id: "OT",
      name: "OT",
      name_en: "OT",
      order: 5,
      isExtra: true,
      isBreak: false
    }
  ],
  periodNames: {
    firstQuarter: {
      id: "firstQuarter",
      name: "1Q",
      name_en: "1Q",
      nameScore: "1Q",
      nameScore_en: "1Q",
      order: 1,
      timeMin: 15,
      isBreak: false,
      displayMaxTimeMin: 15
    },
    secondQuarter: {
      id: "secondQuarter",
      name: "2Q",
      name_en: "2Q",
      nameScore: "2Q",
      nameScore_en: "2Q",
      order: 2,
      timeMin: 15,
      isBreak: false,
      displayMaxTimeMin: 15
    },
    thirdQuarter: {
      id: "thirdQuarter",
      name: "3Q",
      name_en: "3Q",
      nameScore: "3Q",
      nameScore_en: "3Q",
      order: 3,
      timeMin: 15,
      isBreak: false,
      displayMaxTimeMin: 15
    },
    fourthQuarter: {
      id: "fourthQuarter",
      name: "4Q",
      name_en: "4Q",
      nameScore: "4Q",
      nameScore_en: "4Q",
      order: 4,
      isBreak: false,
      timeMin: 15,
      isLastPeriod: true,
      displayMaxTimeMin: 15
    },
    OT: {
      id: "OT",
      name: "OT",
      name_en: "OT",
      nameScore: "OT",
      nameScore_en: "OT",
      order: 5,
      isExtra: true,
      timeMin: 5,
      isBreak: false,
      displayMaxTimeMin: 5
    }
  },
  addCheerIcons: ["🏀"]
};
