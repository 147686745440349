import firebase from "firebase/app";
import { Team } from "models/team";
import { Player } from "@spolive-team/model";
import { TeamMaster } from "models/teamMaster";
import { Game } from "models/game";
import { CheerTotalPoint } from "models/cheerBattle";
import { CheerTeamSelect } from "models/cheerTeamSelect";
import firebaseConfig from "firebase-config";

export const isProd = firebaseConfig.projectId === "spo-live";

export const fetchTeamMaster = async (
  sportsId: string,
  teamMasterId: string
): Promise<TeamMaster> => {
  return firebase
    .firestore()
    .collection(`sportsData/${sportsId}/teamMaster`)
    .doc(teamMasterId)
    .get()
    .then(snap => {
      return { ...snap.data(), id: snap.id } as TeamMaster;
    })
    .catch((e: Error) => {
      throw e;
    });
};

export const fetchTeamMasterPlayer = async (
  sportsId: string,
  teamMasterId: string,
  teamMasterPlayerId: string
): Promise<Player> => {
  return firebase
    .firestore()
    .collection(`sportsData/${sportsId}/teamMaster/${teamMasterId}/players`)
    .doc(teamMasterPlayerId)
    .get()
    .then(snap => {
      return { ...snap.data(), id: snap.id } as Player;
    })
    .catch((e: Error) => {
      throw e;
    });
};

export const fetchTeamMasterPlayerByLeagueData = async (
  sportsId: string,
  teamMasterId: string,
  leagueId: string,
  leagueTeamId: string,
  leaguePlayerId: string
): Promise<Player> => {
  const leaguePlayer = await fetchLeagueTeamPlayer(
    sportsId,
    leagueId,
    leagueTeamId,
    leaguePlayerId
  );
  if (leaguePlayer.masterPlayerId === undefined) {
    throw Error(`Don't find teamMasterPlayer by leaguePlayerId: ${leaguePlayerId}`);
  }
  return fetchTeamMasterPlayer(sportsId, teamMasterId, leaguePlayer.masterPlayerId);
};

export const fetchTeamMasterPlayers = async (
  sportsId: string,
  teamMasterId: string
): Promise<Player[]> => {
  return await firebase
    .firestore()
    .collection(`sportsData/${sportsId}/teamMaster/${teamMasterId}/players`)
    .where("isPublic", "==", true)
    .get()
    .then(qs => qs.docs.map(doc => ({ ...doc.data(), id: doc.id } as Player)));
};

export const fetchLeagueTeam = async (
  sportsId: string,
  leagueId: string,
  leagueTeamId: string
): Promise<Team> => {
  return await firebase
    .firestore()
    .collection(`sportsData/${sportsId}/league/${leagueId}/teams`)
    .doc(leagueTeamId)
    .get()
    .then(data => data.data() as Team);
};

export const fetchLeagueTeamPlayer = async (
  sportsId: string,
  leagueId: string,
  leagueTeamId: string,
  leaguePlayerId: string
): Promise<Player> => {
  return firebase
    .firestore()
    .collection(`sportsData/${sportsId}/league/${leagueId}/teams/${leagueTeamId}/players`)
    .doc(leaguePlayerId)
    .get()
    .then(snap => {
      return { ...snap.data(), id: snap.id } as Player;
    })
    .catch((e: Error) => {
      throw e;
    });
};

/**
 * リーグに紐づくチームの選手一覧を取得する
 * 試合の選手一覧を見るときはリーグ配下のチームの選手一覧がそのリーグの選手情報となる
 * 選手の固定の情報は選手マスタの情報が正しいため、マージした結果を返すようにする
 * playerMasterの値とマージしたかったがデータが壊れているため、一旦保留
 */
export const fetchLeagueTeamPlayers = async (
  sportsId: string,
  leagueId: string,
  leagueTeamId: string
): Promise<Player[]> => {
  const players = await firebase
    .firestore()
    .collection(`sportsData/${sportsId}/league/${leagueId}/teams/${leagueTeamId}/players`)
    .where("isPublic", "==", true)
    .get()
    .then(qs => qs.docs.map(doc => ({ ...doc.data(), id: doc.id } as Player)));
  return players;
};

export const fetchGame = async (
  sportsId: string,
  leagueId: string,
  gameId: string
): Promise<Game> => {
  return await firebase
    .firestore()
    .collection(`sportsData/${sportsId}/league/${leagueId}/games`)
    .doc(gameId)
    .get()
    .then(data => data.data() as Game);
};

export const fetchCheerTotalPoint = async (
  sportsId: string,
  leagueId: string,
  gameId: string
): Promise<CheerTotalPoint> => {
  return await firebase
    .firestore()
    .collection(`sportsData/${sportsId}/league/${leagueId}/games/${gameId}/cheerCount`)
    .doc("totalPoint")
    .get()
    .then(data => data.data() as CheerTotalPoint);
};

export const fetchCheerTeamSelect = async (
  sportsId: string,
  leagueId: string,
  gameId: string,
  uid: string
): Promise<CheerTeamSelect | null> => {
  return await firebase
    .firestore()
    .collection(`sportsData/${sportsId}/league/${leagueId}/games/${gameId}/cheerTeamSelect`)
    .doc(uid)
    .get()
    .then(data => {
      return data.exists ? (data.data() as CheerTeamSelect) : null;
    });
};

export const getTeamDataAndPlayer = async ({
  firestore,
  isMatchIndividual,
  sportsId,
  leagueId,
  teamId
}: {
  firestore: firebase.firestore.Firestore;
  isMatchIndividual: boolean;
  sportsId: string;
  leagueId: string;
  teamId: string;
}) => {
  const collectionId = isMatchIndividual
    ? `sportsData/${sportsId}/league/${leagueId}/players`
    : `sportsData/${sportsId}/league/${leagueId}/teams`;
  const teamData = await firestore
    .collection(collectionId)
    .doc(teamId)
    .get()
    .then(doc => doc.data() as Team);
  if (teamData.masterTeamId) {
    const teamMasterData = await firestore
      .collection(`sportsData/${sportsId}/teamMaster`)
      .doc(teamData.masterTeamId)
      .get()
      .then(doc => doc.data() as TeamMaster);
    teamData.masterTeamData = teamMasterData;
  }
  const players: { [id: string]: Player } = {};
  if (!isMatchIndividual) {
    await firestore
      .collection(`${collectionId}/${teamId}/players`)
      .where("isPublic", "==", true)
      .get()
      .then(qs =>
        qs.docs.map(doc => {
          if (doc.exists) {
            players[doc.id] = doc.data() as Player;
          }
          return null;
        })
      );
    if (players) {
      teamData.players = players;
    }
  }
  return teamData;
};

export const getTeamData = async ({
  firestore,
  sportsId,
  teamMasterId
}: {
  firestore: firebase.firestore.Firestore;
  sportsId: string;
  teamMasterId: string;
}) => {
  const teamMasterData = await firestore
    .collection(`sportsData/${sportsId}/teamMaster`)
    .doc(teamMasterId)
    .get()
    .then(doc => doc.data() as TeamMaster);
  return teamMasterData;
};

export const getCheerIconNum = async ({
  firestore,
  sportsId,
  leagueId,
  gameId
}: {
  firestore: firebase.firestore.Firestore;
  sportsId: string;
  leagueId: string;
  gameId: string;
}) => {
  return firestore
    .collection(`sportsData/${sportsId}/league/${leagueId}/games/${gameId}/cheerCount`)
    .doc("icon")
    .get()
    .then(doc => {
      return {
        count: doc.data()?.count || 0,
        homeCount: doc.data()?.homeCount || 0,
        awayCount: doc.data()?.awayCount || 0
      };
    });
};

export const fetchPlaceUrl = async ({
  sportsId,
  gameData
}: {
  sportsId: string;
  gameData: Game;
}): Promise<string> => {
  if (gameData?.placeUrl) {
    return gameData.placeUrl;
  }
  if (gameData?.defaultPlaceUrl) {
    return gameData.defaultPlaceUrl;
  }
  if (gameData?.placeId) {
    const placeDoc = await firebase
      .firestore()
      .collection("system/common/places")
      .doc(gameData.placeId)
      .get();
    const placeData = placeDoc.data();
    if (placeData?.sportsImgUrl?.[sportsId]) {
      return placeData.sportsImgUrl[sportsId];
    } else if (placeData?.defaultImgUrl) {
      return placeData.defaultImgUrl;
    }
  }
  return "";
};
