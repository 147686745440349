import { SportsDic } from "models/sportsDic";

export const soccer: SportsDic = {
  playerHasUniformNumber: true,
  isFixedPlayerPositionNum: false,
  teamImgIsCircle: true,
  startingMemberNum: 11,
  reserveMemberNum: 7,
  positionList: {
    ja: [
      {
        id: "GK",
        name: "GK"
      },
      {
        id: "DF",
        name: "DF"
      },
      {
        id: "MF",
        name: "MF"
      },
      {
        id: "FW",
        name: "FW"
      },
      {
        id: "HC",
        name: "HC"
      },
      {
        id: "Coach",
        name: "Coach"
      },
      {
        id: "MG",
        name: "MG"
      }
    ],
    en: [
      {
        id: "GK",
        name: "GK"
      },
      {
        id: "DF",
        name: "DF"
      },
      {
        id: "MF",
        name: "MF"
      },
      {
        id: "FW",
        name: "FW"
      },
      {
        id: "HC",
        name: "HC"
      },
      {
        id: "Coach",
        name: "Coach"
      },
      {
        id: "MG",
        name: "MG"
      }
    ]
  },
  statTypes: [
    {
      key: "goal",
      name: "ゴール",
      name_en: "Goal",
      point: 1,
      iconText: "G",
      isHiddenStat: true,
      isAssist: true
    },
    {
      key: "penaltykick",
      name: "ペナルティキック",
      name_en: "Penalty Kick",
      iconText: "PK",
      isHiddenStat: true,
      color: "white"
    },
    {
      key: "owngoal",
      name: "オウンゴール",
      name_en: "Own Goal",
      point: 1,
      iconText: "OG",
      isHiddenStat: true,
      isNoPlayer: true,
      color: "white"
    },
    {
      key: "shoot",
      name: "シュート",
      name_en: "Shot",
      iconText: "S",
      color: "white"
    },
    {
      key: "freeKick",
      name: "フリーキック",
      name_en: "Free kick",
      iconText: "FK",
      color: "white"
    },
    {
      key: "cornerKick",
      name: "コーナーキック",
      name_en: "Corner kick",
      iconText: "CK",
      color: "white"
    },
    {
      key: "yellowcard",
      name: "イエローカード",
      name_en: "Yellow Card"
    },
    {
      key: "redcard",
      name: "レッドカード",
      name_en: "Red Card"
    },
    {
      key: "change",
      name: "交代",
      name_en: "Change",
      isHiddenStat: true
    }
  ],
  infringementNames: [
    {
      key: "penaltyShootout",
      name: "PK戦(延長戦後)",
      name_en: "penalty shootout",
      is_show_gamecontent: true
    },
    {
      key: "fouls",
      name: "ファウル",
      name_en: "Fouls"
    },
    {
      key: "offside",
      name: "オフサイド",
      name_en: "Offside"
    },
    {
      key: "ballControlRate",
      name: "ボール支配率",
      name_en: "Ball control rate",
      defaultValue: 50,
      isTextInput: true,
      unit: "%"
    },
    {
      key: "passSuccessRate",
      name: "パス成功率",
      name_en: "Pass success rate",
      defaultValue: 100,
      isTextInput: true,
      unit: "%"
    },
    {
      key: "shootInFrame",
      name: "枠内シュート",
      name_en: "Shoot in frame",
      isTextInput: true
    },
    {
      key: "passNum",
      name: "パス数",
      name_en: "Pass",
      isTextInput: true
    }
  ],
  positionNames: [
    {
      name: "GK"
    },
    {
      name: "DF"
    },
    {
      name: "MF"
    },
    {
      name: "FW"
    }
  ],
  defaultPosition: [
    { positionName: "GK" },
    { positionName: "DF" },
    { positionName: "DF" },
    { positionName: "DF" },
    { positionName: "DF" },
    { positionName: "MF" },
    { positionName: "MF" },
    { positionName: "MF" },
    { positionName: "FW" },
    { positionName: "FW" },
    { positionName: "FW" }
  ],
  periodNames: {
    firstHalf: {
      id: "firstHalf",
      name: "前半",
      name_en: "1H",
      nameScore: "1st",
      nameScore_en: "1st",
      displayMaxTimeMin: 50,
      order: 1
    },
    halfTime: {
      id: "halfTime",
      name: "ハーフタイム",
      name_en: "half time",
      isBreak: true,
      order: 2
    },
    secondHalf: {
      id: "secondHalf",
      name: "後半",
      name_en: "2H",
      nameScore: "2nd",
      nameScore_en: "2nd",
      displayMaxTimeMin: 50,
      order: 3
    },
    preExtraHalf: {
      id: "preExtraHalf",
      name: "サイドチェンジ",
      name_en: "side change",
      isExtra: true,
      isBreak: true,
      order: 4
    },
    extraFirstHalf: {
      id: "extraFirstHalf",
      name: "延前",
      name_en: "Ex1H",
      nameScore: "extra 1st",
      nameScore_en: "extra 1st",
      isExtra: true,
      displayMaxTimeMin: 20,
      order: 5
    },
    extraHalfTime: {
      id: "extraHalfTime",
      name: "ハーフタイム",
      name_en: "half time",
      isExtra: true,
      isBreak: true,
      order: 6
    },
    extraSecondHalf: {
      id: "extraSecondHalf",
      name: "延後",
      name_en: "Ex2H",
      nameScore: "extra 2nd",
      nameScore_en: "extra 2nd",
      isExtra: true,
      displayMaxTimeMin: 20,
      order: 7
    }
  },
  addCheerIcons: ["⚽"]
};
