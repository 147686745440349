import cheerIcon_PartyPopper from "../assets/images/cheerIcon/PartyPopper.png";
import cheerIcon_HundredPoints from "../assets/images/cheerIcon/HundredPoints.png";
import cheerIcon_GlowingStar from "../assets/images/cheerIcon/GlowingStar.png";
import cheerIcon_Smile from "../assets/images/cheerIcon/Smile.png";
import cheerIcon_Muscle from "../assets/images/cheerIcon/Muscle.png";
import cheerIcon_RugbyBall from "../assets/images/cheerIcon/RugbyBall.png";
import cheerIcon_Snow from "../assets/images/cheerIcon/Snow.png";
import cheerIcon_SoccerBall from "../assets/images/cheerIcon/SoccerBall.png";
import cheerIcon_Darts from "../assets/images/cheerIcon/Darts.png";
import cheerIcon_ThumbsUp from "../assets/images/cheerIcon/ThumbsUp.png";

type Images = {
  [key: string]: string;
};

export const images: Images = {
  cheerIcon_Darts,
  cheerIcon_GlowingStar,
  cheerIcon_HundredPoints,
  cheerIcon_Muscle,
  cheerIcon_PartyPopper,
  cheerIcon_RugbyBall,
  cheerIcon_Smile,
  cheerIcon_Snow,
  cheerIcon_SoccerBall,
  cheerIcon_ThumbsUp
};
