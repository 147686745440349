import { Game } from "models/game";

export const getTeamName = (isHome: boolean, gameData: Game, isJa: boolean): string => {
  if (isHome) {
    const teamMaster = gameData?.homeTeam?.masterTeamData;
    if (!teamMaster) return "　";
    const name_en = teamMaster.name_en || teamMaster.nameFull_en;
    const name = teamMaster.name || teamMaster.nameFull;
    return (!isJa ? name_en : name) || "　";
  } else {
    const teamMaster = gameData?.awayTeam?.masterTeamData;
    if (!teamMaster) return "　";
    const name_en = teamMaster.name_en || teamMaster.nameFull_en;
    const name = teamMaster.name || teamMaster.nameFull;
    return (!isJa ? name_en : name) || "　";
  }
};
