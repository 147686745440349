import React, { useMemo, useEffect, useRef, useState, FC, ReactElement } from "react";
import styled from "styled-components";
import spolive from "assets/images/logo.svg";
import fire from "assets/images/fire.svg";
import crown from "assets/images/crown.png";
import bg from "assets/images/cheerBattle_bg.png";
import confetti from "assets/images/confetti_white_yellow.png";
import setHtmlMeta from "assets/js/setHtmlMeta";
import useOffset from "hooks/use-offset";
import { formatToTimeZone } from "date-fns-timezone";
import { useCheerTotalPoint } from "hooks/use-cheerTotalPoint";
import { TeamImage } from "components/atoms/TeamImage";
import { getTeamNameShort } from "assets/js/textConvert";
import { useGameContext } from "libs/gameContext";

const viewportSize = 1200;
const winTeamNameFZ = 64;
const winTeamNameFZS = 52;
const winTeamNameLH = winTeamNameFZ * 1.1;
const loseTeamNameFZ = 32;
const loseTeamNameFZS = 28;
const loseTeamNameLH = loseTeamNameFZ * 1.1;

type Props = {
  sportsId: string;
  leagueId: string;
  gameId: string;
  locale: "ja" | "en";
};

const HomeAwaySportsCard: FC<Props> = ({
  sportsId,
  leagueId,
  gameId,
  locale
}): ReactElement | null => {
  const { gameData, loading, leagueData, homeTeamMaster, awayTeamMaster } = useGameContext();
  console.log("homeTeamMaster", homeTeamMaster, "awayTeamMaster", awayTeamMaster);
  const [totalPoint] = useCheerTotalPoint({
    sportsId,
    leagueId,
    gameId
  });
  const ratio = useMemo(
    () =>
      totalPoint
        ? (100 * totalPoint.awayTotalPoint!) /
          (totalPoint.homeTotalPoint! + totalPoint.awayTotalPoint!)
        : 50,
    [totalPoint]
  );

  useEffect(() => {
    setHtmlMeta({ viewport: "width=" + viewportSize });
  }, []);

  const homeTeamNameRef = useRef<HTMLSpanElement>(null);
  const awayTeamNameRef = useRef<HTMLSpanElement>(null);
  const [homeTeamOffset, homeTeamOffsetUpdate] = useOffset(homeTeamNameRef);
  const [awayTeamOffset, awayTeamOffsetUpdate] = useOffset(awayTeamNameRef);

  const [homeTeamNameFZ, setHomeTeamNameFZ] = useState(32);
  const [awayTeamNameFZ, setAwayTeamNameFZ] = useState(32);

  // fontsizeの調整
  useEffect(() => {
    if (!totalPoint) return;
    const { homeTotalPoint, awayTotalPoint } = totalPoint;
    const isHomeWin = homeTotalPoint! > awayTotalPoint!;

    if (homeTeamOffset.offsetHight) {
      const isLineBreak = isHomeWin
        ? homeTeamOffset.offsetHight > winTeamNameLH
        : homeTeamOffset.offsetHight > loseTeamNameLH;
      setHomeTeamNameFZ(
        !isLineBreak
          ? isHomeWin
            ? winTeamNameFZ
            : loseTeamNameFZ
          : isHomeWin
          ? winTeamNameFZS
          : loseTeamNameFZS
      );
    } else {
      setHomeTeamNameFZ(isHomeWin ? winTeamNameFZ : loseTeamNameFZ);
    }
    if (awayTeamOffset.offsetHight) {
      const isLineBreak = !isHomeWin
        ? awayTeamOffset.offsetHight > winTeamNameLH
        : awayTeamOffset.offsetHight > loseTeamNameLH;
      setAwayTeamNameFZ(
        !isLineBreak
          ? !isHomeWin
            ? winTeamNameFZ
            : loseTeamNameFZ
          : !isHomeWin
          ? winTeamNameFZS
          : loseTeamNameFZS
      );
    } else {
      setAwayTeamNameFZ(!isHomeWin ? winTeamNameFZ : loseTeamNameFZ);
    }
  }, [homeTeamOffset, awayTeamOffset, totalPoint]);

  useEffect(() => {
    homeTeamOffsetUpdate();
    awayTeamOffsetUpdate();
  }, [loading, homeTeamOffsetUpdate, awayTeamOffsetUpdate]);

  const gameInfoTime = useMemo(() => {
    const d = gameData?.info_datetime?.toDate();
    if (!d) return "";
    const formatedDate = formatToTimeZone(d, "YYYY年MM月DD日 HH:mm～", {
      timeZone: "Asia/Tokyo"
    });
    return formatedDate;
  }, [gameData]);

  const isJa = locale === "ja";
  const infoText = useMemo(() => {
    return (!isJa && gameData?.info_leagueText_en) || gameData?.info_leagueText;
  }, [gameData?.info_leagueText, gameData?.info_leagueText_en, isJa]);

  if (loading) return null;
  if (!gameData) {
    console.error("no gameData");
    return <Wrapper></Wrapper>;
  }
  if (!totalPoint) {
    console.error("no totalPoint");
    return <Wrapper></Wrapper>;
  }
  const { homeTotalPoint, awayTotalPoint } = totalPoint;
  const isHomeWin = homeTotalPoint! > awayTotalPoint!;
  return (
    <Wrapper>
      <Inner className="p-cheerPoints" isHomeWin={isHomeWin}>
        <img className="p-cheerPoints_logo" src={spolive} alt="" />
        <h2 className="p-cheerPoints_title">
          <img src={fire} alt="" />
          <span>CHEERING BATTLE</span>
          <img src={fire} alt="" />
        </h2>
        <div className="p-cheerPoints_main">
          <div className="p-cheerPoints_team-home">
            <div className="p-cheerPoints_team-home_img">
              <TeamImage
                logoUrl={gameData.homeTeam?.logoUrl}
                isFit={leagueData?.isTeamImageFit}
                diameter={isHomeWin ? 256 : 128}
                name={gameData.homeTeam?.name || gameData.team_homeName_ifEmptyTeamId || ""}
                name_en={
                  gameData.homeTeam?.name_en || gameData.team_homeName_ifEmptyTeamId_en || ""
                }
                sportsId={sportsId}
                color={homeTeamMaster?.color}
              />
            </div>
            <div className="p-cheerPoints_team-home_text">
              <TeamName
                className="p-cheerPoints_team-home_name"
                fz={homeTeamNameFZ}
                ref={homeTeamNameRef}
              >
                {getTeamNameShort(isJa, true, gameData)}
              </TeamName>
              <div className="p-cheerPoints_team-home_point">
                {homeTotalPoint!.toLocaleString()}
              </div>
            </div>
          </div>
          <div className="p-cheerPoints_team-away">
            <div className="p-cheerPoints_team-away_img">
              <TeamImage
                logoUrl={gameData.awayTeam?.logoUrl}
                isFit={leagueData?.isTeamImageFit}
                diameter={!isHomeWin ? 256 : 128}
                name={gameData.awayTeam?.name || gameData.team_awayName_ifEmptyTeamId || ""}
                name_en={
                  gameData.awayTeam?.name_en || gameData.team_awayName_ifEmptyTeamId_en || ""
                }
                sportsId={sportsId}
                color={awayTeamMaster?.color}
              />
            </div>
            <div className="p-cheerPoints_team-away_text">
              <TeamName
                className="p-cheerPoints_team-away_name"
                fz={awayTeamNameFZ}
                ref={awayTeamNameRef}
              >
                {getTeamNameShort(isJa, false, gameData)}
              </TeamName>
              <div className="p-cheerPoints_team-away_point">
                {awayTotalPoint!.toLocaleString()}
              </div>
            </div>
          </div>
        </div>
        <StatusBar ratio={ratio} />
        <Footer>
          {gameInfoTime}　{infoText}
        </Footer>
      </Inner>
    </Wrapper>
  );
};

const TeamName = styled.span<{ fz: number }>`
  font-size: ${({ fz }) => fz}px;
`;

const Inner = styled.div<{ isHomeWin: boolean }>`
  background: black;
  height: 100%;
  font-family: "NotoSansJPWebFont";
  width: 100%;
  position: absolute;
  background-image: url(${bg});
  background-size: cover;
  position: relative;
  &:before {
    content: "";
    display: block;
    width: 50%;
    height: 100%;
    position: absolute;
    left: ${({ isHomeWin }) => (isHomeWin ? "0" : "40%")};
    background-image: url(${confetti});
    background-size: cover;
  }
  .p-cheerPoints {
    &_logo {
      top: 30px;
      left: 30px;
      position: absolute;
    }
    &_main {
      position: absolute;
      top: 200px;
      display: flex;
      width: 100%;
      align-items: flex-end;
      justify-content: space-around;
    }
    &_team-${({ isHomeWin }) => (isHomeWin ? "home" : "away")} {
      $this: &;
      display: flex;
      align-items: center;
      position: relative;
      &_text {
        position: relative;
        top: 10px;
      }
      &_img {
        background-color: #ffffff;
        width: 256px;
        height: 256px;
        border-radius: 50%;
        margin-right: 28px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        &_wrapper {
          justify-content: center;
          align-items: center;
          overflow: hidden;
          display: flex;
          height: 100%;
          width: 100%;
          border-radius: 50%;
        }
        &:after {
          content: "";
          display: block;
          position: absolute;
          width: 103px;
          height: 103px;
          background-image: url(${crown});
          left: calc(50% - 50px);
          top: -95px;
          background-size: cover;
        }
      }
      &_name {
        font-family: "NotoSansJPWebFont";
        font-weight: bold;
        line-height: 1.1em;
        max-width: 480px;
        display: block;
      }
      &_point {
        font-size: 104px;
        font-weight: bold;
        font-family: "OswaldWebFont";
        line-height: 1.1em;
      }
    }
    &_team-${({ isHomeWin }) => (isHomeWin ? "away" : "home")} {
      display: flex;
      align-items: center;
      position: relative;
      top: -20px;
      &_img {
        background-color: #ffffff;
        width: 128px;
        height: 128px;
        border-radius: 50%;
        margin-right: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        /* overflow: hidden; */
        &_wrapper {
          justify-content: center;
          align-items: center;
          overflow: hidden;
          display: flex;
          height: 100%;
          width: 100%;
          border-radius: 50%;
        }
      }
      &_name {
        font-family: "NotoSansJPWebFont";
        font-weight: bold;
        line-height: 1.1em;
        max-width: 252px;
        display: block;
      }
      &_point {
        font-size: 56px;
        font-weight: bold;
        font-family: "OswaldWebFont";
        line-height: 1.1em;
      }
    }
    &_title {
      font-family: "OswaldWebFont";
      color: white;
      font-size: 64px;
      font-weight: 600;
      clip-path: polygon(5% 0%, 100% 0%, 100% 100%, 0% 100%);
      width: 692px;
      position: absolute;
      right: 0;
      top: 20px;
      padding-left: 20px;
      height: 120px;
      background: linear-gradient(245.88deg, #f28a3f 0%, #fe77e8 100%);
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        margin: 0 18px;
      }
      img {
        width: 50px;
      }
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: ${viewportSize}px;
  height: ${viewportSize * 0.525}px;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
  background: linear-gradient(245.88deg, #f28a3f 0%, #fe77e8 100%);
`;

const StatusBar = styled.div<{ ratio: number }>`
  width: 1000px;
  height: 40px;
  background: #113a7b;
  position: absolute;
  border-radius: 20px;
  bottom: 100px;
  left: calc(50% - 500px);
  &:after {
    content: "";
    display: block;
    right: 0;
    width: ${({ ratio }) => ratio}%;
    height: 100%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    position: absolute;
    background: #ff3d46;
  }
`;

const Footer = styled.div`
  position: absolute;
  top: 510px;
  left: calc(50% - 500px);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85px;
  font-size: 28px;
  width: 100%;
  text-align: center;
  width: 1000px;
`;

export default HomeAwaySportsCard;
