import React, { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { GiftComment } from "models/giftComment";
import { Typography } from "components/atoms/Typography";
import { GameDataContext } from "../hooks/gameData";
import { SupporterBadge } from "components/atoms/SupporterBadge";
import { getTeamName } from "libs/scoreboard";
import { useSubscribeUser } from "hooks/useSubscribeUser";
import { useGetPlayer } from "../hooks/useGamePlayers";

const useSuperCheer = ({
  homeLogo,
  awayLogo,
  cheerGiftComment
}: {
  homeLogo: string;
  awayLogo: string;
  cheerGiftComment: GiftComment | null;
}) => {
  const { sportsId, leagueId, gameData, isJa } = useContext(GameDataContext);

  const isHome = useMemo(() => {
    return !!cheerGiftComment?.isHomeTeamSelect;
  }, [cheerGiftComment?.isHomeTeamSelect]);
  const cheerTeamLogoUrl = useMemo(() => {
    return isHome ? homeLogo : awayLogo;
  }, [awayLogo, homeLogo, isHome]);
  const teamName = useMemo(() => {
    return gameData ? getTeamName(isHome, gameData, isJa) : "";
  }, [gameData, isHome, isJa]);
  const cheerComment = useMemo(() => {
    return cheerGiftComment?.comment || `${teamName}にスーパー応援`;
  }, [cheerGiftComment?.comment, teamName]);

  const subscribeUser = useSubscribeUser(cheerGiftComment);

  const player = useGetPlayer({
    sportsId,
    leagueId,
    teamId: cheerGiftComment?.selectTeamId ?? "",
    playerId: cheerGiftComment?.playerId ?? ""
  });
  const cheerPlayerOrTeamLogoUrl = useMemo(() => {
    return player ? player.photoUrl : cheerTeamLogoUrl;
  }, [cheerTeamLogoUrl, player]);
  const cheerPlayerOrTeamName = useMemo(() => {
    if (isJa) {
      return player?.name ?? teamName;
    } else {
      return player?.name_en ?? player?.name ?? teamName;
    }
  }, [isJa, player?.name, player?.name_en, teamName]);

  return {
    isHome,
    cheerTeamLogoUrl,
    cheerPlayerOrTeamLogoUrl,
    cheerPlayerOrTeamName,
    cheerComment,
    subscribeUser
  };
};

type Props = {
  homeLogo: string;
  awayLogo: string;
  cheerGiftComment: GiftComment | null;
  iconUrl: string;
  isShow: boolean;
  homeTeamColor: string | undefined;
  awayTeamColor: string | undefined;
  wrapperRef: React.RefObject<HTMLDivElement>;
  commentRef: React.RefObject<HTMLDivElement>;
  imageRef: React.RefObject<HTMLImageElement>;
  superCheerContentRef: React.RefObject<HTMLDivElement>;
};

export const SuperCheer: React.FC<Props> = ({
  homeLogo,
  awayLogo,
  cheerGiftComment,
  iconUrl = "",
  isShow,
  homeTeamColor,
  awayTeamColor,
  wrapperRef,
  commentRef,
  imageRef,
  superCheerContentRef
}) => {
  const { isShownFooter } = useContext(GameDataContext);
  const imagePos = ((commentRef.current?.offsetLeft || 0) - 370) / 2;
  const {
    isHome,
    cheerTeamLogoUrl,
    cheerPlayerOrTeamLogoUrl,
    cheerPlayerOrTeamName,
    cheerComment,
    subscribeUser
  } = useSuperCheer({
    homeLogo,
    awayLogo,
    cheerGiftComment
  });

  const [isImageVisible, setIsImageVisible] = useState(true);

  useEffect(() => {
    setIsImageVisible(false);
  }, [iconUrl]);

  return (
    <Wrapper
      commentColor={isHome ? homeTeamColor : awayTeamColor}
      isHomeTeamSelect={isHome}
      isShownFooter={isShownFooter}
      isShow={isShow}
      ref={wrapperRef}
    >
      <div className="previewSuperCheer-logo">
        <img src={cheerTeamLogoUrl} alt="" className="previewSuperCheer-logo_item" />
      </div>
      <div className="previewSuperCheer" ref={superCheerContentRef}>
        <div className="previewSuperCheer_comment" ref={commentRef}>
          <div className="previewSuperCheer_comment_head">
            <div className="previewSuperCheer_comment_head_img_wrapper">
              <img
                src={cheerGiftComment?.userIcon}
                alt=""
                className="previewSuperCheer_comment_head_img"
              />
              {subscribeUser && (
                <div className="previewSuperCheer_comment_head_badge">
                  <SupporterBadge
                    month={subscribeUser.monthsAgo}
                    level={subscribeUser.supporterLevel}
                    logoUrl={cheerTeamLogoUrl}
                  />
                </div>
              )}
            </div>
            <h4 className="previewSuperCheer_comment_head_name">
              <Typography text={cheerGiftComment?.userNickName || "匿名さん"} />
            </h4>
            <div className="previewSuperCheer_comment_divider">
              <Typography text="▶" />
            </div>
            <div className="previewSuperCheer_comment_head_img_wrapper">
              <img
                src={cheerPlayerOrTeamLogoUrl}
                alt=""
                className="previewSuperCheer_comment_head_img"
              />
            </div>
            <h4 className="previewSuperCheer_comment_head_name">
              <Typography text={cheerPlayerOrTeamName} />
            </h4>
          </div>
          <p className="previewSuperCheer_comment_comment">
            <Typography text={cheerComment} />
          </p>
        </div>
        <img
          src={iconUrl}
          alt=""
          className={
            "previewSuperCheer_icon " + (isImageVisible ? "previewSuperCheer_icon--inVisible" : "")
          }
          ref={imageRef}
          onLoad={() => setIsImageVisible(true)}
          style={isHome ? { right: imagePos } : { left: imagePos }}
        />
      </div>
    </Wrapper>
  );
};

type WrapperProps = {
  commentColor: string | undefined;
  isHomeTeamSelect: boolean;
  isShownFooter: boolean;
  isShow: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  opacity: ${({ isShow }) => (isShow ? 1 : 0)};
  font-family: "RobotoWebFont", "NotoSansJPWebFont";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 431px;
  transition: opacity 0.5s;
  background: linear-gradient(
    ${({ isHomeTeamSelect }) => (isHomeTeamSelect ? "270deg" : "90deg")},
    #000000 67.36%,
    #ffffff 102.04%
  );
  border-top: 7px solid ${({ commentColor }) => commentColor};
  .previewSuperCheer-logo {
    position: absolute;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    overflow: hidden;
    &_item {
      position: absolute;
      overflow: hidden;
      top: -30%;
      overflow: hidden;
      width: 689px;
      ${({ isHomeTeamSelect }) => (isHomeTeamSelect ? "left: -200px;" : "right: -200px")}
      z-index: 0;
      height: 160%;
      object-fit: contain;
    }
  }
  .previewSuperCheer {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: ${({ isHomeTeamSelect }) => (isHomeTeamSelect ? "row" : "row-reverse")};
    align-items: flex-start;
    &_icon {
      width: 370px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      &--invisible: {
        opacity: 0;
      }
    }
    &_hide-overflow {
      overflow: hidden;
    }
    &_comment {
      border-radius: 10px;
      box-sizing: border-box;
      width: 58%;
      height: 345px;
      padding: 4px 18px 9px;
      &_head {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        &_img_wrapper {
          position: relative;
        }
        &_img {
          border-radius: 50%;
          width: 84px;
          height: 84px;
          margin-right: 16px;
          background: white;
          object-fit: contain;
        }
        &_name {
          max-width: 40%;
          font-size: 42px;
          font-weight: normal;
          font-family: "RobotoWebFont", "NotoSansJPWebFont";
          color: #949494;
          display: -webkit-box;
          overflow: hidden;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          line-height: 1.2;
          white-space: normal;
          word-break: break-word;
        }
        &_badge {
          position: absolute;
          top: 60%;
          left: -12%;
        }
      }
      &_divider {
        width: 36px;
        height: 48px;
        font-family: "Noto Sans JP";
        font-style: normal;
        font-weight: 900;
        font-size: 36px;
        line-height: 130%;
        color: #ffffff;
        padding: 40px;
      }
      &_comment {
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        font-size: 56px;
        text-overflow: ellipsis;
        font-family: "RobotoWebFont", "NotoSansJPWebFont";
        font-weight: bold;
        word-break: break-all;
      }
    }
  }
`;
