import React, { FC } from "react";
import { goLP } from "assets/js/goLP";
import { isMobile } from "assets/js/platform";
import { useParams } from "react-router-dom";
import { goAppStore } from "assets/js/goAppStore";

type PathParams = {
  id: string;
};

const ShareCollection: FC = () => {
  const { id } = useParams<PathParams>();
  if (isMobile) {
    //アプリがインストールされている場合deeplink実行
    document.location.href = `spolive://collection?path=${id}`;
    //アプリがインストールされていない（500msたってもdeeplink遷移が行われていない）場合はストアへ飛ばす
    goAppStore(500);
  } else {
    // PC の場合は LP に遷移させる
    goLP();
  }
  return <div />;
};

export default ShareCollection;
