import { Game } from "models/game";
import { PeriodDic, SportsDic } from "models/sportsDic";
import { Timeline } from "models/timeline";

const startingMemberNum = 9;
export const baseball: SportsDic = {
  // playerHasUniformNumber: true,
  playerHasUniformNumber: false, // スタメン表示ようにfalseへ変更
  hasPosition: true,
  hasUniformNumber: true,
  isFixedPlayerPositionNum: false,
  teamImgIsCircle: true,
  reserveMemberNum: 25 - startingMemberNum,
  startingMemberNum: startingMemberNum,
  statTypes: [
    {
      key: "hit",
      name: "ヒット",
      name_en: "Hit",
      isSelectPoint: true,
      iconText: "H"
    },
    {
      key: "2BH",
      name: "ツーベース",
      name_en: "2BH",
      isSelectPoint: true,
      iconText: "2BH"
    },
    {
      key: "3BH",
      name: "スリーベース",
      name_en: "3BH",
      isSelectPoint: true,
      iconText: "3BH"
    },
    {
      key: "homeRun",
      name: "ホームラン",
      name_en: "HomeRun",
      isSelectPoint: true,
      iconText: "HR"
    },
    {
      key: "strikeOut",
      name: "三振",
      name_en: "Strikeout",
      isSelectPoint: true,
      iconText: "K",
      color: "gray"
    },
    {
      key: "walk",
      name: "四球",
      name_en: "Walk",
      isSelectPoint: true,
      iconText: "W",
      color: "gray"
    },
    {
      key: "deadBall",
      name: "死球",
      name_en: "Dead Ball",
      isSelectPoint: true,
      iconText: "DB",
      color: "gray"
    },
    {
      key: "out",
      name: "アウト",
      name_en: "Out",
      isSelectPoint: true,
      iconText: "O",
      color: "gray",
      outCount: 1
    },
    {
      key: "doublePlay",
      name: "ダブルプレー",
      name_en: "Double Play",
      isSelectPoint: true,
      iconText: "DP",
      color: "gray",
      outCount: 2
    },
    {
      key: "triplePlay",
      name: "トリプルプレー",
      name_en: "Triple Play",
      isSelectPoint: true,
      iconText: "TP",
      color: "gray",
      outCount: 3
    },
    {
      key: "error",
      name: "エラー",
      name_en: "Error",
      isSelectPoint: true,
      iconText: "E",
      color: "gray"
    },
    {
      key: "stolenBase",
      name: "盗塁",
      name_en: "Steal",
      isSelectPoint: true,
      iconText: "S",
      color: "gray"
    }
  ],
  initialPeriod: [
    {
      id: "1",
      name: "1回",
      name_en: "1",
      nameScore: "1",
      nameScore_en: "1",
      isHiddenDisplayTime: false,
      order: 1
    },
    {
      id: "2",
      name: "2回",
      name_en: "2",
      nameScore: "2",
      nameScore_en: "2",
      isHiddenDisplayTime: false,
      order: 2
    },
    {
      id: "3",
      name: "3回",
      name_en: "3",
      nameScore: "3",
      nameScore_en: "3",
      isHiddenDisplayTime: false,
      order: 3
    },
    {
      id: "4",
      name: "4回",
      name_en: "4",
      nameScore: "4",
      nameScore_en: "4",
      isHiddenDisplayTime: false,
      order: 4
    },
    {
      id: "5",
      name: "5回",
      name_en: "5",
      nameScore: "5",
      nameScore_en: "5",
      isHiddenDisplayTime: false,
      order: 5
    },
    {
      id: "6",
      name: "6回",
      name_en: "6",
      nameScore: "6",
      nameScore_en: "6",
      isHiddenDisplayTime: false,
      order: 6
    },
    {
      id: "7",
      name: "7回",
      name_en: "7",
      nameScore: "7",
      nameScore_en: "7",
      isHiddenDisplayTime: false,
      order: 7
    },
    {
      id: "8",
      name: "8回",
      name_en: "8",
      nameScore: "8",
      nameScore_en: "8",
      isHiddenDisplayTime: false,
      order: 8
    },
    {
      id: "9",
      name: "9回",
      name_en: "9",
      nameScore: "9",
      nameScore_en: "9",
      isHiddenDisplayTime: false,
      order: 9
    }
  ],
  periodNames: {
    1: {
      id: "1",
      name: "1回",
      name_en: "1st",
      nameScore: "1",
      nameScore_en: "1",
      isHiddenDisplayTime: false,
      order: 1
    },
    2: {
      id: "2",
      name: "2回",
      name_en: "2nd",
      nameScore: "2",
      nameScore_en: "2",
      isHiddenDisplayTime: false,
      order: 2
    },
    3: {
      id: "3",
      name: "3回",
      name_en: "3rd",
      nameScore: "3",
      nameScore_en: "3",
      isHiddenDisplayTime: false,
      order: 3
    },
    4: {
      id: "4",
      name: "4回",
      name_en: "4th",
      nameScore: "4",
      nameScore_en: "4",
      isHiddenDisplayTime: false,
      order: 4
    },
    5: {
      id: "5",
      name: "5回",
      name_en: "5th",
      nameScore: "5",
      nameScore_en: "5",
      isHiddenDisplayTime: false,
      order: 5
    },
    6: {
      id: "6",
      name: "6回",
      name_en: "6th",
      nameScore: "6",
      nameScore_en: "6",
      isHiddenDisplayTime: false,
      order: 6
    },
    7: {
      id: "7",
      name: "7回",
      name_en: "7th",
      nameScore: "7",
      nameScore_en: "7",
      isHiddenDisplayTime: false,
      order: 7
    },
    8: {
      id: "8",
      name: "8回",
      name_en: "8th",
      nameScore: "8",
      nameScore_en: "8",
      isHiddenDisplayTime: false,
      order: 8
    },
    9: {
      id: "9",
      name: "9回",
      name_en: "9th",
      nameScore: "9",
      nameScore_en: "9",
      isHiddenDisplayTime: false,
      order: 9
    },
    10: {
      id: "10",
      name: "10回",
      name_en: "10th",
      nameScore: "10",
      nameScore_en: "10",
      isHiddenDisplayTime: false,
      order: 10
    },
    11: {
      id: "11",
      name: "11回",
      name_en: "11th",
      nameScore: "11",
      nameScore_en: "11",
      isHiddenDisplayTime: false,
      order: 11
    },
    12: {
      id: "12",
      name: "12回",
      name_en: "12th",
      nameScore: "12",
      nameScore_en: "12",
      isHiddenDisplayTime: false,
      order: 12
    },
    13: {
      id: "13",
      name: "13回",
      name_en: "13th",
      nameScore: "13",
      nameScore_en: "13",
      isHiddenDisplayTime: false,
      order: 13
    },
    14: {
      id: "14",
      name: "14回",
      name_en: "14th",
      nameScore: "14",
      nameScore_en: "14",
      isHiddenDisplayTime: false,
      order: 14
    },
    15: {
      id: "15",
      name: "15回",
      name_en: "15th",
      nameScore: "15",
      nameScore_en: "15",
      isHiddenDisplayTime: false,
      order: 15
    }
  },
  infringementNames: [],
  addCheerIcons: ["⚾"]
};

export const getInning = (game: Game): false | { period: PeriodDic; isTop: boolean } => {
  if (!game?.period_currentStatusId || !baseball.periodNames) return false;
  const isTop = !!game.isInningTop;
  const period = baseball.periodNames && baseball.periodNames[game.period_currentStatusId];
  return { period, isTop };
};

export const getBaseballScore = (timelineArr: Timeline[]) => {
  let home = 0;
  let away = 0;
  timelineArr.forEach(_ => {
    if (_.player.home) {
      home += _.point || 0;
    } else if (_.player.away) {
      away += _.point || 0;
    }
  });
  return { home, away };
};
