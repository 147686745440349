import { getPeriodNames } from "assets/sportsData";
import React, { useContext, useMemo } from "react";
import { GameDataContext } from "../hooks/gameData";
import styled from "styled-components";
import { getCurrentPeriodId } from "libs/gameTimeTextGenerator";
import { isExtraPeriod, Points, transformContractToPeriodSetModel } from "models/game";
import { findPeriodScoreNameCreator } from "libs/periods";

export const ScoreBoard = () => {
  const {
    latestGamePoints,
    sportsId,
    gameData,
    firstHalf,
    isJa,
    lastHalf: secondHalf
  } = useContext(GameDataContext);

  const hasPeriodList = useMemo(
    () => gameData?.periodList && gameData.periodList.length > 0,
    [gameData?.periodList]
  );
  const hasPeriodModel = useMemo(
    () => gameData?.periods && gameData.periods.periods.length > 0,
    [gameData?.periods]
  );
  const isPeriodGame = useMemo(
    () => hasPeriodList || hasPeriodModel,
    [hasPeriodList, hasPeriodModel]
  );
  const currentPeriodId = useMemo(
    () => gameData && getCurrentPeriodId(sportsId, gameData),
    [gameData, sportsId]
  );
  // 対戦形式はfont80に。ピリオド形式はアメフト等でコメントがかぶるので60にする
  const fontSize = isPeriodGame ? "60px" : "80px";
  //対戦形式は余白があるので下に余白を追加する
  // アメフトで追加するとOTがコメント欄にかぶるのでmargin-bottom は低めにする
  const mb = isPeriodGame ? "8px" : "24px";
  const mt = isPeriodGame ? "0px" : "36px";

  const periodModel = useMemo(() => {
    const periodSet = gameData?.periods
      ? transformContractToPeriodSetModel([gameData.periods], "Prohibited")
      : [];
    return periodSet.length > 0 ? periodSet[0].periods : null;
  }, [gameData?.periods]);

  const { periodScoreList, allPeriods } = useMemo(() => {
    const findPeriodName = findPeriodScoreNameCreator(gameData?.periods?.periods, sportsId);
    // ピリオド共通化対応
    if (hasPeriodModel && periodModel) {
      const periodIndex = periodModel.findIndex(period => period.id === currentPeriodId);
      const filteredPeriods = periodModel
        .filter((period, i) => !(isExtraPeriod(period) && i > periodIndex))
        .filter(period => period.showScoreDisplay)
        .map(period => ({
          id: period.id,
          showPeriod: period.showScoreDisplay,
          nameScore: findPeriodName(period.id, isJa),
          gamePoint: latestGamePoints?.[period.id]
        }));
      const allPeriods = periodModel
        .filter((period, i) => !(isExtraPeriod(period) && i > periodIndex))
        .map(period => ({
          id: period.id,
          showPeriod: period.showScoreDisplay,
          nameScore: findPeriodName(period.id, isJa),
          gamePoint: latestGamePoints?.[period.id]
        }));

      return { periodScoreList: filteredPeriods, allPeriods: allPeriods };
    }
    const periodNames = sportsId && getPeriodNames(sportsId);
    if (periodNames) {
      const filteredPeriods = Object.keys(periodNames)
        .filter(periodId => findPeriodName(periodId, isJa))
        .sort((a, b) => periodNames[a].order - periodNames[b].order)
        .map(periodId => ({
          id: periodId,
          showPeriod: !periodNames[periodId].isBreak,
          nameScore: findPeriodName(periodId, isJa),
          gamePoint: latestGamePoints?.[periodId]
        }));
      const allPeriods = Object.keys(periodNames)
        .sort((a, b) => periodNames[a].order - periodNames[b].order)
        .map(periodId => ({
          id: periodId,
          showPeriod: !periodNames[periodId].isBreak,
          nameScore: findPeriodName(periodId, isJa),
          gamePoint: latestGamePoints?.[periodId]
        }));
      return { periodScoreList: filteredPeriods, allPeriods: allPeriods };
    }
    return { periodScoreList: [], allPeriods: [] };
  }, [
    periodModel,
    isJa,
    hasPeriodModel,
    latestGamePoints,
    sportsId,
    gameData?.periods,
    currentPeriodId
  ]);

  const currentPeriodIndex = useMemo(
    () => periodScoreList.findIndex(item => item.id === currentPeriodId),
    [currentPeriodId, periodScoreList]
  );
  const startIsFirst = useMemo(
    () => (gameData?.time_firstHalfStartTime ? true : false),
    [gameData?.time_firstHalfStartTime]
  );
  const startIsSecond = useMemo(
    () => (gameData?.time_secondHalfStartTime ? true : false),
    [gameData?.time_secondHalfStartTime]
  );
  const isGameEnd = useMemo(
    () => (gameData?.time_isGameEnd ? true : false),
    [gameData?.time_isGameEnd]
  );

  const renderVsGameScore = () => {
    const getGameScore = (id: string, isHome: boolean, half: Points, startIsHalf: boolean) => {
      const score = isHome ? half.home : half.away;
      return startIsHalf && score === "-" ? 0 : score;
    };
    const makeGameScore = (id: string, isHome: boolean) => {
      if (id === "firstHalf") return getGameScore(id, isHome, firstHalf, startIsFirst);
      if (id === "secondHalf") return getGameScore(id, isHome, secondHalf, startIsSecond);
      return "-";
    };
    return (
      <>
        {periodScoreList.map((item, i) => (
          <div key={item.id} className="p-score-scoreboard_item">
            <div className="p-score-scoreboard_vs_point">{makeGameScore(item.id, true)}</div>
            <div className="p-score-scoreboard_period">{item.nameScore}</div>
            <div className="p-score-scoreboard_vs_point">{makeGameScore(item.id, false)}</div>
          </div>
        ))}
      </>
    );
  };
  const renderPeriodGameScore = () => {
    return (
      <>
        {allPeriods.map((item, i) => {
          if (!item.showPeriod) {
            return null;
          }
          return (
            <div className="p-score-scoreboard_item" key={item.id}>
              <div className="p-score-scoreboard_point">
                {typeof item.gamePoint?.home === "number"
                  ? item.gamePoint?.home
                  : i <= currentPeriodIndex || isGameEnd
                  ? 0
                  : "-"}
              </div>
              <div className="p-score-scoreboard_period">{item.nameScore}</div>
              <div className="p-score-scoreboard_point">
                {typeof item.gamePoint?.away === "number"
                  ? item.gamePoint?.away
                  : i <= currentPeriodIndex || isGameEnd
                  ? 0
                  : "-"}
              </div>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <ScoreBoardWrapper className="p-score-scoreboard" fontSize={fontSize} mb={mb} mt={mt}>
      {hasPeriodList || hasPeriodModel ? renderPeriodGameScore() : renderVsGameScore()}
    </ScoreBoardWrapper>
  );
};

const ScoreBoardWrapper = styled.div<{
  fontSize: string;
  mb: string;
  mt: string;
}>`
  margin-top: ${({ mt }) => mt};
  .p-score-scoreboard {
    font-size: ${({ fontSize }) => fontSize};
  }
  .p-score-scoreboard_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 360px;
    margin-bottom: ${({ mb }) => mb};
  }
  .p-score-scoreboard_point {
    font-size: ${({ fontSize }) => fontSize};
    width: 100px;
    text-align: center;
    line-height: 1;
  }

  .p-score-scoreboard_vs_point {
    font-size: ${({ fontSize }) => fontSize};
    width: 100px;
    text-align: center;
    line-height: 1;
  }

  .p-score-scoreboard_period {
    line-height: 1;
    font-size: ${({ fontSize }) => fontSize};
    color: #bebebe;
    width: 480px;
    text-align: center;
  }
`;
