import { isProd } from "assets/js/firebase";

export type TeamMaster = {
  id?: string;
  name: string;
  name_en: string;
  nameFull?: string;
  nameFull_en?: string;
  logoUrl?: string;
  color?: string;
  color2?: string;
  color3?: string;
  teamIds: [
    {
      leagueId: string;
      teamId: string;
    }
  ];
  sportsId?: string;
  sportsName?: string;
  sportsName_en?: string;
  bgImgUrlWithProGraphicsChangePlayer?: string;
  bgImgUrlWithProGraphicsNicePlay?: string;
  backgroundImgUrl?: string;
};

/**
 * チームマスターIDの定数
 * ProGraphics などで teamMasterId で処理を変える必要が何箇所かであるため、
 * 定数として定義しておく
 *
 * 命名規則: TMID_チーム名
 */
export const TMID_RED_HURRICANES = "reddoharikeenzu";
export const TMID_URAYASU_DROCKS = isProd ? "6LZH1pBIuckn65DDWUaR" : "zxYOtDkXfjyIlUGkOFvy";
