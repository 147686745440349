import React, { FC, ReactElement } from "react";
import styled from "styled-components";
import spoliveLogo from "assets/images/logo.svg";
import leagueLogo from "assets/images/sports/rugby/league_logo/japan_rugby_league_one.png";

const LogoLayer: FC = (): ReactElement => {
  return (
    <Wrapper>
      <div className="gradation" />
      <img className="spoliveLogo" src={spoliveLogo} alt="spoliveLogo" />
      <img className="leagueLogo" src={leagueLogo} alt="leagueLogo" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  .gradation {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 382px;
    width: 100%;
    transform: rotate(-180deg);
    background: linear-gradient(0deg, #000000 30.72%, rgba(0, 0, 0, 0) 94.44%);
  }
  .spoliveLogo {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 260px;
    height: 95px;
    margin: 20px 20px 20px 25px;
    box-sizing: border-box;
  }
  .leagueLogo {
    position: absolute;
    right: 30px;
    top: 20px;
    width: 272px;
    height: 132px;
  }
`;

export default LogoLayer;
